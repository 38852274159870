import React, {Component, Fragment} from "react";
import {connect} from "react-redux";
import {setDialog} from "../../lib/Store/actions/components";
import {scrollToTop} from "../../lib/functions";


class Index extends Component {


    constructor(props) {
        super(props);
        this.state = {}
    }

    componentDidMount() {
        document.title = `DHRU  Privacy Policy`;
        scrollToTop();
    }

    render() {

        return (
            <Fragment>

                <div className="container mt-5">

                    <div className={'my-3'}>

                    <h2>What this policy covers</h2>

                    Sanvi Software Pvt Ltd ("DHRU") respects and values your privacy. That's why Sanvi Software Pvt Ltd
                    has established this Privacy Policy (referred to as the "Privacy Policy" herein) - so that Sanvi
                    Software Pvt Ltd can disclose what information Sanvi Software Pvt Ltd collects about you and how
                    Sanvi Software Pvt Ltd use, store and disclose such information. Sanvi Software Pvt Ltd want to help
                    you make an informed decision about whether or not to share your information with Sanvi Software Pvt
                    Ltd.

                    <br/><br/>
                    Sanvi Software Pvt Ltd reserves the right to revise, modify, add or remove provisions to this
                    Privacy Policy at any time. In the event that Sanvi Software Pvt Ltd do so, Sanvi Software Pvt
                    Ltd will notify you in accordance with the notice provisions of Sanvi Software Pvt Ltd' Terms of
                    Service. Take note of the 'Last Updated' date listed at the foot of this Privacy Policy. If
                    Sanvi Software Pvt Ltd make changes to this Privacy Policy, Sanvi Software Pvt Ltd will update
                    that date to notify you of the date of such changes. By continuing to use www.Sanvi Software Pvt
                    Ltd.com ("the Website") after receiving notice of the updated Privacy Policy, you acknowledge
                    that you have read and understand the revised Privacy Policy.
                    <br/><br/>
                    Capitalised terms in this Privacy Policy have the meaning given to such terms in Sanvi
                    Software Pvt Ltd' Terms of Service.

                    <br/><br/>
                    <h2>How does we collect information from you?</h2>

                    We collects information from you when you send it to we directly by filling out forms on
                    our' Website (including any application you may make for Services or the Software),
                    corresponding with us by e-mail, writing to us by post, or speaking to us by telephone.
                    We also collect information from you when you browse the Website and use the Software,
                    as set out in detail below. Some of the methods we use to collect data are automated and
                    will take effect automatically as you navigate the Website and/or use the Software.

                    <br/><br/>
                    <h2>What information does we collect?</h2>
                    <b> Personal Information.</b><br/>

                    Personal information means any information relating to an identified or identifiable
                    natural person; an identifiable natural person is one who can be identified,
                    directly or indirectly, by reference to an identifier such as a name, an
                    identification number, location data, an online identifier or to one or more factors
                    specific to the physical, physiological, genetic, mental, economic, cultural or
                    social identity of that natural person ("Personal Information").
                    <br/><br/>
                    We may collect the following Personal Information from you: name, address,
                    telephone number, postal address, e-mail address, IP address, information about
                    the type of browser you use, your operating system, the screen resolution of
                    your browser, your ISP, which pages you view on the Website and the time and
                    duration of your visits to the Website, credit card number (in the case of
                    direct customers) and, if applicable, company name, postal address, telephone
                    number, e-mail address, credit card number or tax ID number as well as similar
                    information concerning technical contacts, marketing contacts, and executive
                    contacts within your company or organisation.
                    <br/><br/>
                    <b>User Communications.</b><br/>
                    If you communicate with us, We may collect information relating to that
                    communication whether it takes the form of e-mail, letter, forum posting,
                    testimonials or any other form of communication between you and us or
                    submitted by you to the Website (collectively, "User Communications").

                    <b>Non-Personal Information.</b><br/>
                    We also collect data in a form that does not, on its own, permit direct
                    association with any specific individual. We may collect, use, transfer, and
                    disclose non-personal information for any purpose.

                    <br/><br/>
                    <h2>What information does we collect if you're under the age of 13?</h2>

                    We does not knowingly collect Personal Information about children under
                    the age of 13. If we becomes aware that it has inadvertently collected
                    Personal Information about children under the age of 13, it will take
                    steps to delete the information as soon as possible, except where us is
                    required by applicable law to keep it.
                    <br/><br/>
                    Where we knows a child is above the age of 13, but considered a
                    minor under applicable law, We will obtain parental/guardian consent
                    prior to using that child's Personal Information.

                    <br/><br/>
                    <h2>How does we use and store the information it collects?</h2>
                    As a general matter, DHRU may use the Personal Information you
                    disclose to DHRU to improve and provide the Website, the
                    Services and DHRU' other products and services, and for the
                    specific purpose that you gave DHRU your Personal Information.
                    More specific details on how DHRU use your information follows
                    below.
                    <br/><br/>
                    DHRU may use Personal Information to create and authenticate
                    your account, to process transactions you request such as
                    software license purchases in order to perform any contract
                    you have entered into with DHRU, to provide you with
                    customer and technical support and to communicate with you
                    about your account and DHRU' products and services.
                    <br/><br/>
                    The Personal Information you provide to DHRU allows DHRU
                    to provide you with the newsletters and mailing lists
                    that you have signed up for with DHRU. If you do not
                    want to be on DHRU' mailing list, you can opt out at any
                    time by changing your settings in your 'My Details' page
                    within the client area.
                    <br/><br/>
                    DHRU may also use Personal Information to offer,
                    market or advertise to you DHRU products and
                    services. While such DHRU products and services may
                    reference, incorporate or include third-party
                    products and services, DHRU will not use your
                    Personal Information to independently offer, market
                    or advertise third-party products and services
                    unless DHRU first obtain your consent to do so.
                    <br/><br/>
                    From time to time, DHRU may use your Personal
                    Information to send important notices, such as
                    important product updates and communications
                    about purchases as well as changes to DHRU'
                    terms, conditions, and policies. Because this
                    information is important for your interaction
                    with DHRU you may not opt out of receiving these
                    communications.
                    <br/><br/>
                    DHRU may also use your Personal Information
                    for any other legal purpose stated in this
                    Privacy Policy, and any other purpose for
                    which you have given DHRU express consent.
                    <br/><br/>
                    Where you consent to DHRU doing so, DHRU
                    may use your Personal Information to
                    send you direct marketing
                    communications. Should you ever choose
                    to 'opt out' of receiving such
                    communications DHRU may retain your
                    Personal Information on file to ensure
                    that DHRU do not continue to target you
                    with communications of the type that you
                    have asked DHRU to cease sending to you.
                    <br/><br/>
                    DHRU limits the use of Personal
                    Information to the purposes
                    identified in this Privacy Policy
                    and for which you have provided
                    explicit consent. In accordance with
                    established policies and procedures,
                    DHRU retains Personal Information
                    only for as long as necessary to
                    fulfil the stated purposes, unless
                    it is required by law for a
                    different period or DHRU has the
                    consent of the individual.
                    <br/><br/>
                    In accordance with established
                    policies and procedures, DHRU
                    will periodically destroy or
                    erase any Personal Information
                    that is no longer needed.
                    <br/><br/>
                    DHRU may use User
                    Communications in the same
                    way as DHRU uses Personal
                    Information. If you
                    communicate with DHRU for a
                    particular purpose, DHRU may
                    use your User Communications
                    for that purpose. For
                    example, if you contact DHRU
                    for technical support, DHRU
                    may use your communications
                    to provide technical support
                    to you.
                    <br/><br/>
                    DHRU may use Server
                    Information to provide
                    you with technical
                    support services and to
                    maintain, evaluate,
                    improve and provide DHRU
                    products and services.
                    DHRU may also use such
                    information to
                    investigate unlicensed
                    (and therefore
                    unauthorised) uses of
                    DHRU' software.
                    <br/><br/>
                    <br/><br/>
                    <h2>Where is
                        your
                        Personal
                        Information
                        stored?</h2>

                    DHRU IS A GLOBAL
                    COMPANY AND YOUR
                    PERSONAL
                    INFORMATION WILL
                    NOT NECESSARILY
                    BE HELD IN THE
                    EUROPEAN
                    ECONOMIC AREA
                    ("EEA"). YOUR
                    PERONAL DATA MAY
                    BE TRANSFERRED
                    TO AND STORED AT
                    OUR US OFFICE
                    AND ASSOCIATED
                    DATACENTERS AND
                    BY ACCEPTING OUR
                    TERMS OF SERVICE
                    AND/OR A
                    SOFTWARE EULA,
                    YOU ARE
                    EXPLICITLY
                    CONSENTING TO
                    THIS TRANSFER
                    AND STORAGE
                    TAKING PLACE. WE
                    WILL TAKE ALL
                    STEPS REASONABLY
                    NECESSARY TO
                    ENSURE THAT YOUR
                    PERSONAL
                    INFORMATION IS
                    TREATED SECURELY
                    AND IN
                    ACCORDANCE WITH
                    THIS PRIVACY
                    POLICY.
                    <br/><br/>

                    <br/><br/>
                    <h2>How
                        does
                        we
                        disclose
                        the
                        Personal
                        Information
                        it
                        collects?</h2>

                    Except
                    as
                    disclosed
                    in this
                    Privacy
                    Policy,
                    DHRU
                    does not
                    share
                    your
                    Personal
                    Information
                    with
                    unaffiliated
                    third
                    parties
                    other
                    than as
                    set out
                    in this
                    Privacy
                    Policy.
                    DHRU
                    will
                    never
                    disclose
                    your
                    Personal
                    Information
                    to an
                    unaffiliated
                    third
                    party
                    for the
                    purposes
                    of
                    e-mail
                    or other
                    marketing
                    unless
                    DHRU
                    first
                    obtain
                    your
                    consent
                    to do
                    so.
                    <br/><br/>
                    DHRU
                    cannot
                    be
                    responsible
                    for
                    protecting
                    your
                    Personal
                    Information
                    if
                    you
                    share
                    such
                    information
                    in
                    publicly
                    available
                    sections
                    of
                    the
                    Website
                    such
                    as
                    the
                    user
                    forums
                    or
                    testimonials
                    section.
                    You
                    should
                    use
                    your
                    own
                    judgment
                    in
                    disclosing
                    this
                    information
                    on
                    the
                    Website.
                    <br/><br/>
                    In
                    the
                    event
                    that
                    DHRU
                    is
                    purchased
                    by
                    another
                    company,
                    merges
                    with
                    another
                    company
                    or
                    has
                    a
                    substantial
                    portion
                    of
                    its
                    assets
                    purchased
                    by
                    another
                    company,
                    DHRU
                    may
                    share
                    your
                    Personal
                    Information
                    with
                    that
                    company.
                    In
                    such
                    an
                    event,
                    DHRU
                    will
                    notify
                    you
                    in
                    accordance
                    with
                    the
                    notice
                    provisions
                    of
                    the
                    Terms
                    of
                    Use.
                    <br/><br/>
                    DHRU
                    may
                    share
                    your
                    Personal
                    Information
                    with
                    third
                    parties
                    as
                    permitted
                    or
                    required
                    by
                    applicable
                    law,
                    by
                    a
                    court
                    order
                    or
                    by
                    another
                    judicial
                    or
                    governmental
                    request.
                    DHRU
                    may
                    also
                    disclose
                    your
                    Personal
                    Information
                    if
                    DHRU
                    determine
                    that
                    such
                    disclosure
                    is
                    necessary
                    to
                    protect
                    DHRU's
                    rights
                    and
                    remedies
                    or
                    is
                    necessary
                    for
                    national
                    security,
                    law
                    enforcement,
                    or
                    other
                    issues
                    of
                    public
                    importance.
                    Such
                    disclosures
                    could
                    take
                    place
                    in
                    the
                    context
                    of
                    an
                    investigation
                    or
                    to
                    prevent
                    or
                    take
                    action
                    against
                    illegal
                    activity,
                    suspected
                    fraud,
                    situations
                    involving
                    potential
                    threats
                    to
                    the
                    physical
                    safety
                    of
                    any
                    person,
                    violations
                    of
                    DHRU's
                    Terms
                    of
                    Service,
                    applicable
                    EULAs
                    or
                    other
                    DHRU
                    agreements,
                    or
                    as
                    otherwise
                    required
                    by
                    applicable
                    law.
                    Where
                    this
                    is
                    the
                    case
                    DHRU
                    will
                    endeavour
                    to
                    give
                    you
                    notice
                    of
                    such
                    disclosures,
                    to
                    the
                    extent
                    that
                    DHRU
                    are
                    lawfully
                    permitted
                    to
                    do
                    so.
                    <br/><br/>
                    DHRU
                    may
                    disclose
                    your
                    Personal
                    Information
                    to
                    affiliated
                    third
                    parties
                    that
                    assist
                    DHRU
                    in
                    providing
                    maintaining,
                    evaluating,
                    improving
                    and
                    providing
                    the
                    Website,
                    the
                    Services
                    and
                    any
                    other
                    DHRU
                    products
                    and
                    services.
                    DHRU
                    may
                    disclose
                    your
                    Personal
                    Information
                    to
                    affiliated
                    third
                    parties
                    for
                    the
                    purposes
                    of
                    offering,
                    marketing
                    or
                    advertising
                    DHRU
                    products
                    and
                    services
                    to
                    you
                    (such
                    DHRU
                    products
                    and
                    services
                    may
                    reference,
                    incorporate
                    or
                    include
                    third-party
                    products
                    and
                    services).
                    In
                    such
                    an
                    event,
                    DHRU
                    will
                    take
                    reasonable
                    measures
                    to
                    prevent
                    such
                    affiliated
                    third
                    parties
                    from
                    using
                    your
                    Personal
                    Information
                    for
                    other
                    purposes.

                    <br/><br/>
                    <br/><br/>
                    <h2>Does
                        we
                        use
                        cookies
                        and
                        web
                        beacons?</h2>

                    <b>Cookies</b><br/>

                    Yes,
                    DHRU
                    uses
                    cookies
                    to
                    distinguish
                    you
                    from
                    other
                    users
                    of
                    the
                    Website
                    and
                    to
                    improve
                    your
                    overall
                    experience.
                    When
                    you
                    use
                    the
                    Website,
                    certain
                    information
                    may
                    be
                    stored
                    locally
                    on
                    your
                    device
                    using
                    'cookies'
                    or
                    similar
                    technologies
                    such
                    as
                    objects
                    stored
                    in
                    the
                    device's
                    local
                    storage.
                    A
                    cookie
                    is
                    a
                    small
                    text
                    file
                    used
                    to
                    store
                    limited
                    information
                    about
                    the
                    user
                    of
                    the
                    device.
                    They
                    help
                    DHRU
                    to
                    identify
                    how
                    DHRU'
                    users
                    use
                    the
                    Website,
                    and
                    so
                    that
                    DHRU
                    can
                    continue
                    to
                    develop
                    and
                    improve
                    it.
                    <br/><br/>
                    Cookies
                    do
                    this
                    by
                    storing
                    information
                    about
                    your
                    preferences
                    on
                    your
                    device,
                    including
                    the
                    device
                    model,
                    manufacturer,
                    screen
                    resolution,
                    device
                    capabilities,
                    service
                    provider,
                    country
                    and
                    city
                    location
                    data.
                    DHRU
                    uses
                    the
                    following
                    types
                    of
                    cookies:
                    <br/><br/>
                    Strictly
                    necessary:
                    These
                    cookies
                    enable
                    DHRU'
                    Website
                    to
                    function
                    correctly
                    and
                    deliver
                    the
                    services
                    and
                    products
                    you
                    have
                    requested.
                    Performance &amp; Functionality:
                    These
                    cookies
                    are
                    used
                    to
                    enhance
                    the
                    performance
                    and
                    functionality
                    of
                    DHRU'
                    Website
                    but
                    are
                    non-essential
                    to
                    their
                    use
                    (for
                    example,
                    DHRU
                    uses
                    cookies
                    to
                    remember
                    your
                    preferences
                    on
                    the
                    Website).
                    However,
                    without
                    these
                    cookies,
                    certain
                    functionality
                    may
                    become
                    unavailable.
                    Analytical:
                    These
                    cookies
                    collect
                    information
                    that
                    is
                    used
                    either
                    in
                    aggregate
                    form
                    to
                    help
                    DHRU
                    understand
                    how
                    the
                    Website
                    is
                    being
                    used
                    or
                    to
                    help
                    DHRU
                    customise
                    the
                    Website
                    for
                    you.
                    In
                    particular
                    DHRU
                    use
                    the
                    Google
                    analytics
                    cookies.
                    For
                    more
                    information
                    about
                    Google's
                    privacy
                    policy,
                    please
                    visit
                    http://www.google.com/intl/en/policies/.
                    If
                    you
                    do
                    not
                    wish
                    to
                    allow
                    the
                    use
                    of
                    Google
                    Analytics
                    cookies
                    at
                    all,
                    Google
                    provides
                    an
                    opt-out
                    plug-in
                    for
                    most
                    common
                    website
                    browsers
                    http://tools.google.com/dlpage/gaoptout.
                    By
                    using
                    the
                    Service,
                    you
                    accept
                    the
                    use
                    of
                    cookies
                    in
                    accordance
                    with
                    this
                    Privacy
                    Policy.

                    <br/><br/>
                    <b>Your
                        cookie
                        choices</b><br/>
                    You
                    have
                    the
                    right
                    to
                    choose
                    whether
                    to
                    accept
                    or
                    refuse
                    cookies
                    and
                    similar
                    technologies.
                    You
                    can
                    exercise
                    this
                    choice
                    by
                    not
                    accepting
                    the
                    Terms
                    of
                    Service
                    and
                    this
                    Privacy
                    Policy,
                    however,
                    please
                    note
                    that
                    if
                    you
                    choose
                    to
                    do
                    so,
                    you
                    will
                    not
                    be
                    able
                    to
                    use
                    the
                    Service.
                    <br/><br/>
                    For
                    more
                    information
                    about
                    what
                    cookies
                    are
                    and
                    your
                    rights
                    to
                    control
                    cookies,
                    please
                    visit
                    the
                    third
                    party
                    educational
                    resource
                    www.allaboutcookies.org.
                    <br/><br/>
                    <b>Web
                        beacons</b><br/>
                    DHRU
                    does
                    not
                    use
                    web
                    beacons.
                    <br/><br/>
                    <br/><br/>

                    <h2>How
                        does
                        we
                        protect
                        Personal
                        Information
                        and
                        other
                        information
                        about
                        you?</h2>
                    DHRU
                    takes
                    reasonable
                    steps
                    to
                    protect
                    any
                    Personal
                    Information
                    DHRU
                    collects
                    about
                    you.
                    Additionally,
                    DHRU
                    may
                    supply
                    you
                    with
                    tools
                    to
                    evaluate
                    the
                    strength
                    of
                    the
                    password
                    you
                    select
                    for
                    the
                    Website.
                    No
                    security
                    measures
                    are
                    foolproof,
                    and
                    DHRU
                    cannot
                    guarantee
                    the
                    security
                    of
                    your
                    information
                    other
                    than
                    to
                    take
                    reasonable
                    steps
                    to
                    protect
                    it.
                    In
                    the
                    event
                    that
                    DHRU
                    becomes
                    aware
                    of
                    a
                    security
                    breach,
                    unauthorised
                    disclosure
                    or
                    inadvertent
                    disclosure
                    concerning
                    your
                    information,
                    you
                    agree
                    that
                    DHRU
                    may
                    notify
                    you
                    of
                    such
                    an
                    event
                    using
                    the
                    notice
                    provisions
                    of
                    the
                    Terms
                    of
                    Use.
                    <br/><br/>
                    You
                    are
                    responsible
                    for
                    maintaining
                    your
                    account's
                    security
                    (as
                    more
                    specifically
                    explained
                    in
                    the
                    Terms
                    of
                    Service).
                    <br/><br/>
                    <br/><br/>


                    <h2>Information
                        and
                        access
                        to
                        your
                        Personal
                        Information</h2>

                    Based
                    on
                    what
                    you
                    have
                    told
                    DHRU,
                    DHRU
                    try
                    to
                    ensure
                    that
                    your
                    contact
                    information
                    and
                    preferences
                    are
                    accurate,
                    complete,
                    and
                    up
                    to
                    date.
                    You
                    have
                    the
                    right
                    to
                    request
                    access
                    to
                    and
                    rectification
                    or
                    erasure
                    of
                    Personal
                    Information
                    or
                    restriction
                    of
                    processing
                    concerning
                    your
                    Personal
                    Information
                    or
                    to
                    object
                    to
                    processing
                    as
                    well
                    as
                    the
                    right
                    to
                    data
                    portability.
                    DHRU
                    will
                    comply
                    with
                    erasure
                    requests
                    as
                    long
                    as
                    DHRU
                    is
                    not
                    required
                    to
                    retain
                    the
                    Personal
                    Information
                    by
                    law
                    and
                    does
                    not
                    need
                    to
                    retain
                    it
                    for
                    its
                    legitimate
                    business
                    purposes.
                    DHRU
                    may
                    decline
                    to
                    process
                    requests
                    that
                    are
                    frivolous/vexatious,
                    jeopardise
                    the
                    privacy
                    of
                    others,
                    are
                    extremely
                    impractical,
                    or
                    for
                    which
                    access
                    is
                    not
                    otherwise
                    required
                    by
                    local
                    law.
                    Access,
                    correction,
                    or
                    deletion
                    requests
                    can
                    be
                    made
                    by
                    emailing
                    privacy@DHRU.com.
                    <br/><br/>
                    <b>Your
                        Rights</b><br/>
                    As
                    a
                    Data
                    Subject
                    you
                    have
                    a
                    number
                    of
                    rights
                    in
                    relation
                    to
                    your
                    Personal
                    Information.
                    These
                    include
                    your
                    rights:
                    <br/><br/>
                    -To
                    ask
                    DHRU
                    to
                    confirm
                    what
                    (if
                    any)
                    Personal
                    Information
                    DHRU
                    hold
                    relating
                    to
                    you
                    and
                    how
                    that
                    data
                    is
                    processed
                    and
                    used
                    by
                    DHRU
                    (often
                    referred
                    to
                    as
                    a
                    'Subject
                    Access
                    Request');<br/><br/>
                    -To
                    have
                    DHRU
                    rectify
                    Personal
                    Information
                    which
                    DHRU
                    hold
                    about
                    you
                    that
                    is
                    inaccurate;<br/><br/>
                    -To
                    have
                    DHRU
                    erase
                    Personal
                    Information
                    which
                    relates
                    to
                    you
                    if
                    it
                    is
                    no
                    longer
                    necessary
                    for
                    DHRU
                    to
                    continue
                    to
                    process
                    it,
                    or
                    if
                    DHRU
                    have
                    no
                    lawful
                    basis
                    to
                    store,
                    process
                    or
                    retain
                    it
                    (though
                    DHRU
                    do
                    have
                    rights
                    to
                    refuse
                    these
                    requests
                    in
                    certain
                    situations);<br/><br/>
                    -To
                    object
                    to
                    DHRU
                    processing
                    your
                    Personal
                    Information;<br/><br/>
                    -To
                    be
                    informed
                    about
                    the
                    existence
                    of
                    any
                    automated
                    decision
                    making
                    and
                    profiling
                    of
                    your
                    Personal
                    Information,
                    and
                    where
                    appropriate,
                    be
                    provided
                    with
                    meaningful
                    information
                    about
                    the
                    logic
                    involved,
                    as
                    well
                    as
                    the
                    significance
                    and
                    the
                    envisaged
                    consequences
                    of
                    such
                    processing
                    that
                    affects
                    you;<br/><br/>
                    -To
                    have
                    DHRU
                    provide
                    you
                    with
                    a
                    copy
                    of
                    your
                    Personal
                    Information
                    for
                    transfer
                    to
                    an
                    alternative
                    provider
                    of
                    similar
                    services.<br/><br/>
                    -Should
                    you
                    ever
                    wish
                    to
                    exercise
                    one
                    or
                    more
                    of
                    these
                    rights
                    you
                    should
                    contact
                    DHRU
                    at
                    privacy
                    @
                    dhru.com. <br/><br/>

                    <br/><br/>
                    <b>Third
                        party
                        websites</b><br/>
                    DHRU'
                    Website
                    may,
                    from
                    time
                    to
                    time,
                    contain
                    links
                    to
                    and
                    from
                    the
                    websites
                    of
                    DHRU'
                    partner
                    networks,
                    advertisers
                    and
                    affiliates.
                    If
                    you
                    follow
                    a
                    link
                    to
                    any
                    of
                    these
                    websites,
                    please
                    note
                    that
                    these
                    websites
                    have
                    their
                    own
                    privacy
                    policies
                    and
                    that
                    DHRU
                    do
                    not
                    accept
                    any
                    responsibility
                    or
                    liability
                    for
                    these
                    policies.
                    Please
                    check
                    these
                    policies
                    before
                    you
                    submit
                    any
                    Personal
                    Information
                    to
                    these
                    websites.

                    <br/><br/>
                    <br/><br/>
                    <h2>What
                        if
                        you
                        have
                        questions,
                        comments
                        or
                        complaints
                        about
                        this
                        Privacy
                        Policy?</h2>

                    Please
                    feel
                    free
                    to
                    direct
                    any
                    questions,
                    comments
                    or
                    complaints
                    regarding
                    this
                    Privacy
                    Policy
                    using
                    the
                    contact
                    information
                    listed
                    in
                    the
                    Notices
                    section
                    of
                    the
                    DHRU
                    Terms
                    of
                    Use.
                    It
                    is
                    DHRU'
                    policy
                    to
                    investigate
                    and
                    attempt
                    to
                    resolve
                    all
                    formal
                    written
                    complaints
                    DHRU
                    receive
                    concerning
                    this
                    Privacy
                    Policy.
                    DHRU
                    will
                    cooperate
                    with
                    appropriate
                    regulatory
                    authorities,
                    including
                    local
                    data
                    protection
                    authorities,
                    to
                    resolve
                    any
                    complaints
                    regarding
                    the
                    transfer
                    of
                    personal
                    data
                    that
                    cannot
                    be
                    resolved
                    between
                    DHRU
                    and
                    an
                    individual.
                    <br/><br/>
                    With
                    effect
                    from
                    25
                    May
                    2018,
                    the
                    General
                    Data
                    Protection
                    Regulation
                    (GDPR)
                    enters
                    force.
                    Your
                    rights
                    under
                    that
                    new
                    regulation
                    are
                    explained
                    at
                    https://ico.org.uk/for-organisations/data-protection-reform/overview-of-the-gdpr/.
                    Details
                    of
                    how
                    you
                    can
                    exercise
                    those
                    rights
                    are
                    set
                    out
                    in
                    'Your
                    Rights'.
                    DHRU
                    have
                    largely
                    factored
                    GDPR
                    into
                    this
                    Privacy
                    Policy
                    but
                    if
                    you
                    think
                    DHRU
                    has
                    failed
                    to
                    do
                    so
                    in
                    any
                    way,
                    please
                    notify
                    DHRU
                    at
                    privacy@dhru.com.
                    <br/><br/>
                    <br/><br/>
                </div>
                </div>
            </Fragment>
        );
    }

}

const mapStateToProps = (state) => {
    return {}
};

const mapDispatchToProps = (dispatch) => ({
    setDialog: (dialog) => dispatch(setDialog(dialog)),
});


export default connect(mapStateToProps, mapDispatchToProps)(Index);
