import React, {Component, Fragment} from "react";
import {connect} from "react-redux";
import requestApi, {SUCCESS} from "../../lib/ServerRequest";
import {methods} from "../../lib/ServerRequest/methods";
import { withGoogleReCaptcha} from 'react-google-recaptcha-v3';
import {scrollToTop, transformRequest} from "../../lib/functions";
import {Spinner} from "reactstrap";


class Index extends Component {


    constructor(props) {
        super(props);
        this.state = {
            name: null,
            email: null,
            subject: null,
            message: null,
            'g-recaptcha-response':"",
            errors: {
                name: 'Name is required',
                email: 'Email is required',
                subject: 'Subject is required',
                message: 'Message is required',
            },
            ticketmsg:'',
            displayError:false,
            spinner:false,
            errormsg:false
        };
    }

    componentDidMount() {
        document.title = "DHRU Contact Us";
        scrollToTop();
    }


    validEmailRegex = RegExp(
        /^(([^<>()[\].,;:\s@]+(.[^<>()[\].,;:\s@]+)*)|(.+))@(([^<>()[\].,;:\s@]+.)+[^<>()[\].,;:\s@]{2,})$/i
    );
    validateForm = errors => {
        let valid = true;
        Object.values(errors).forEach(val => val.length > 0 && (valid = false));
        return valid;
    };

    handleChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        let errors = this.state.errors;

        switch (name) {
            case 'name':
                errors.name =
                    value.length === 0
                        ? 'Name is required'
                        : '';
                break;
            case 'email':
                errors.email =
                    this.validEmailRegex.test(value)
                        ? ''
                        : value.length === 0 ? 'Email is required' : 'Email is not valid!';
                break;

            case 'subject':
                errors.subject =
                    value.length === 0
                        ? 'Subject is required'
                        : '';
                break;
            case 'message':
                errors.message =
                    value.length === 0
                        ? 'Message is required'
                        : '';
                break;
            default:
                break;
        }
        this.setState({errors, [name]: value});
    }



    handleSubmit = async (event) => {
        event.preventDefault();
        const {name,email,subject,message} = this.state;
        if(this.validateForm(this.state.errors)) {
            const {googleReCaptchaProps} = this.props;
            this.setState({spinner:true});
            const captcha = await  googleReCaptchaProps.executeRecaptcha();
            let array = {
                name: name,
                email: email,
                subject: btoa(subject),
                text: btoa(message),
                'g-recaptcha-response':captcha
            }
            this.setState({'g-recaptcha-response':captcha},()=>{
                requestApi({
                    method: methods.post,
                    body: {...array},
                    otherurl: 'https://api.dhru.com/client/api/v1/contactus',
                }, (response) => {
                    if (response.status === SUCCESS) {
                        this.setState({ticketmsg:response.message,spinner:false})
                    }
                    else{
                        this.setState({errormsg:response.message,spinner:false})
                    }
                });
            });
        }else{
            this.setState({displayError:true});
        }
    }


    render() {
        const {errors,ticketmsg,displayError,spinner,errormsg} = this.state;
        return(
            <Fragment>
                <section className="section pb-5">
                    <div className="container">
                        <div className="row justify-content-xl-between">
                            <div className="col-12 col-lg-6 m-auto">
                                <h2 className={'my-4'}>Get in touch <span>with us</span></h2>




                                {!ticketmsg &&  <form  id={'contactus'}  onSubmit={this.handleSubmit} noValidate >

                                    {errormsg && <div className={'alert alert-danger'}>
                                        {errormsg}
                                    </div>}

                                    <div className="input-wrp">
                                        <input className="textfield textfield--grey" placeholder="Full name"
                                               onChange={this.handleChange} noValidate required name="name" type="text"/>
                                        {Boolean(errors.name.length) && displayError && <small className="text-danger">{errors.name}</small>}
                                    </div>

                                    <div className="row">

                                        <div className="col-12 col-md-12">
                                            <div className="input-wrp">
                                                <input className="textfield textfield--grey" placeholder="Email"
                                                       onChange={this.handleChange} noValidate   name="email" required  type="email"
                                                       inputMode="email" x-inputmode="email"/>
                                                {Boolean(errors.email.length) && displayError && <small className="text-danger">{errors.email}</small>}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="input-wrp">
                                        <input className="textfield textfield--grey" placeholder="Subject"
                                               onChange={this.handleChange} noValidate  required name="subject" type="text"/>
                                        {Boolean(errors.subject.length) && displayError && <small className="text-danger">{errors.subject}</small>}
                                    </div>

                                    <label className="input-wrp">
                                        <textarea className="textfield textfield--grey" ng-model="contact.message"
                                                  onChange={this.handleChange} noValidate  required placeholder="Message" name="message"></textarea>
                                        {Boolean(errors.message.length) && displayError && <small className="text-danger">{errors.message}</small>}
                                    </label>


                                    <div className="d-flex justify-content-between align-items-center w-100">
                                        <div>
                                            <button className="custom-btn custom-btn--medium custom-btn--style-4"  type="submit" role="button">
                                                {spinner  ? <Fragment> <Spinner  /> Please wait </Fragment>: 'Send' }
                                            </button>
                                        </div>
                                        <small className="mt-3">
                                             <a target="_blank" rel="noopener noreferrer" href="https://account.dhru.com">Already registered? Please login here</a>
                                        </small>
                                    </div>


                                    <div className="form__note"></div>

                                    <br/><br/>
                                    <div className={'text-center mt-5'}>

                                        <small  className={'d-block'}  style={{fontSize:'12px',color:'#ccc'}}>
                                            Protected by reCAPTCHA and subject to the Google <a target={'_blank'} href={'https://policies.google.com/privacy?hl=en'}>Privacy Policy</a>  and  <a  target={'_blank'} href={'https://policies.google.com/terms?hl=en'}> Terms of Service </a>.
                                        </small>
                                    </div>

                                </form>}

                                {ticketmsg && <div  className="text-success p-5 text-center">

                                    <div className="sa-icon animate alert-success">
                                        <span className="sa-line sa-tip animateSuccessTip"></span>
                                        <span className="sa-line sa-long animateSuccessLong"></span>
                                        <div className="sa-placeholder"></div>
                                        <div className="sa-fix"></div>
                                    </div>

                                    {ticketmsg}

                                </div>}

                            </div>

                        </div>
                    </div>

                </section>
            </Fragment>
        );
    }

}

const mapStateToProps = (state) => {
    return {

    }
};

const mapDispatchToProps = (dispatch) => ({

});


export default withGoogleReCaptcha(connect(mapStateToProps, mapDispatchToProps)(Index));
