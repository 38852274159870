import React, {Component,Fragment} from "react";
import {NavLink} from "reactstrap";
import {Link} from "react-router-dom";
import Logo from "../../theme/images/logos/app_icon_2020.png";
import {setPage} from "../../lib/Store/actions/appApiData";
import {connect} from "react-redux";

export class Footer extends Component {
    render() {

        const year = new Date().getFullYear();
        const {setPage} = this.props;

        return (
            <Fragment>
                <section className="section section--no-pt section--no-pb">
                    <footer className="footer footer--s3 footer--color-dark">
                        <div className="footer__line footer__line--first">
                            <div className="container">
                                <div className="row border-bottom" style={{marginBottom:'50px',paddingBottom:'50px'}}>
                                    <div className="col-12 col-md-6 col-lg-4">
                                        <div className="footer__item text-center">
                                            <img className="img-fluid rounded" src={Logo} alt="Logo" style={{width:'50px'}} />
                                        </div>
                                    </div>
                                    <div className="col col-sm-5 col-md-3 col-lg-2">
                                        <div className="footer__item"><h4 className="footer__item__title">Resources</h4>
                                            <nav className="footer__navigation">
                                                <ul>
                                                    <li><NavLink tag={Link} onClick={()=>setPage({page:'index'})}   to={"/products/dhru-fusion"}>Dhru Fusion</NavLink></li>
                                                    <li><NavLink tag={Link}  onClick={()=>setPage({page:'index'})}   to={"/products/dhru-ecommerce"}>Ecommerce</NavLink></li>
                                                    <li><NavLink  tag={Link}  onClick={()=>setPage({page:'index'})}  to={"/products/dhru-erp/restaurant"}>Restaurant</NavLink></li>
                                                    <li><NavLink tag={Link}  onClick={()=>setPage({page:'index'})}   to={"/products/dhru-erp/retail"}>Retail</NavLink></li>
                                                    <li><NavLink tag={Link}   onClick={()=>setPage({page:'index'})}  to={"/products/dhru-erp/servicecenter"}>Service Center</NavLink></li>
                                                </ul>
                                            </nav>
                                        </div>
                                    </div>
                                    <div className="col col-sm-5 col-md-2 col-lg-2">
                                        <div className="footer__item"><h4 className="footer__item__title">Useful
                                            links</h4>
                                            <nav className="footer__navigation">
                                                <ul>
                                                    <li><a target="_blank"
                                                           href="https://support.dhru.com/Dhru-Fusion/releasenotes/" rel="noopener noreferrer">Announcements</a>
                                                    </li>
                                                    <li><a target="_blank" href="https://support.dhru.com" rel="noopener noreferrer">Support</a></li>
                                                    <li><a target="_blank"
                                                           href="https://support.dhru.com/Dhru-Fusion/article/Troubleshooting/?aid=9&cid=1&pid=1" rel="noopener noreferrer">FAQ</a>
                                                    </li>
                                                    <li><a target="_blank"
                                                           href="https://support.dhru.com/Dhru-Fusion/" rel="noopener noreferrer">Developer</a>
                                                    </li>
                                                </ul>
                                            </nav>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-4">
                                        <div className="footer__item"><h4 className="footer__item__title">Contact
                                            info</h4>
                                            <nav className="footer__navigation">
                                                <ul>
                                                    <li><NavLink tag={Link}  onClick={()=>setPage({page:'contactus'})}   to={"/contactus"}>Email Us</NavLink></li>
                                                    <li><NavLink tag={Link}  onClick={()=>setPage({page:'contactus'})}  to={"/contactus"}>Work with us</NavLink>
                                                    </li>
                                                </ul>
                                            </nav>
                                        </div>
                                        <div className="footer__item">
                                            <div className="s-btns s-btns--md s-btns--transparent s-btns--rounded">
                                                <ul className="d-flex flex-row flex-wrap align-items-center">
                                                    <li><a className="t" target="_blank" href="https://t.me/s/dhrufusion" rel="noopener noreferrer"><i
                                                        className="fab fa-telegram-plane"></i></a></li>
                                                    <li><a className="y" target="_blank" href="https://www.youtube.com/channel/UC7fIFIY3zUwayrCwy7JVpsw/featured" rel="noopener noreferrer"><i
                                                        className="fab fa-youtube"></i></a></li>
                                                    <li><a className="t" target="_blank" href="https://apps.apple.com/us/app/dhru/id1535749812" rel="noopener noreferrer"><i
                                                        className="fab fa-apple"></i></a></li>
                                                    <li><a className="y" target="_blank" href="https://play.google.com/store/apps/details?id=com.dhru.account" rel="noopener noreferrer"><i
                                                        className="fab fa-android"></i></a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 text-center">
                                        <div>
                                            <span className="__bullet">&copy; Dhru Cloud Pvt Ltd {year}</span>
                                            <span className="__bullet">Dhru™ </span>
                                            <span className="__bullet">Smart Lifestyle™ </span>
                                            <span className="__bullet">Dhru Cloud™ </span>
                                            <span className="__bullet">POS.WORK™</span>
                                            <br/><br/>
                                            <div>
                                                {/*<NavLink   onClick={()=>setPage({page:'index'})}  tag={Link}   to={"/"}> <span className="__bullet"> About </span> </NavLink>*/}
                                                <NavLink  onClick={()=>setPage({page:'privacypolicy'})}   tag={Link}  to={"/privacypolicy"}><span className="__bullet m-0"> Privacy Policy </span> </NavLink>
                                                <NavLink  onClick={()=>setPage({page:'toc'})}  tag={Link}  to={"/toc"}> <span className="__bullet m-0">TOC</span></NavLink>
                                            </div>
                                        </div>
                                        <br/><br/></div>
                                </div>
                            </div>
                        </div>
                    </footer>
                </section>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        page:state.appApiData.page
    }
};

const mapDispatchToProps = (dispatch) => ({
    setPage: (page) => dispatch(setPage(page)),
});


export default connect(mapStateToProps, mapDispatchToProps)(Footer);
