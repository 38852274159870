const products = [
    {value: "b152adfc-5829-4c3c-a3b7-6df8e4a3cf52", label: "Dhru Fusion",seo:'Dhru-Fusion'},
    {value: "2", label: "Dhru Ecommerce",seo:'Dhru-Ecommerce'},
];




const integration = [
    {img:'paypal',title:'PayPal : Masspay, Express'},
    {img:'2checkout',title:'2 Checkout'},
    {img:'allipay',title:'Alipay'},
    {img:'baokim',title:'Baokim'},
    {img:'coinbase',title:'Coinbase'},
    {img:'dwolla',title:'Dwolla'},
    {img:'ccavenue',title:'CCavenue'},
    {img:'dhl',title:'DHL'},
    {img:'clickatell',title:'Clickatell'},
    {img:'fedex',title:'FedEx'},
    {img:'paytm',title:'PayTm'},
    {img:'quickpay',title:'QuickPay'},
    {img:'googlepay',title:'GooglePay'},
    {img:'telegram',title:'Telegram'},
    {img:'twillo',title:'Twillo'},
    {img:'zoho',title:'Zoho'},
    {img:'worldpay',title:'WorldPay'},
    {img:'mailgun',title:'Mailgun'},
    {img:'sendgrid',title:'Sendgrid'},
    {img:'skrill',title:'Skrill'},
    {img:'slack',title:'Slack'},
    {img:'mailchimp',title:'Mail Chimp'},
    {img:'ups',title:'UPS'},
    {img:'blockchain',title:'Block Chain'},
    {img:'icontact',title:'iContact'},
    {img:'nochex',title:'Nochex'},
]

export {
    products,
    integration,
}
