import React, { Fragment, useEffect, useState} from "react";
import {connect} from "react-redux";
import {setDialog} from "../../lib/Store/actions/components";
import {_accordion, map, numberFormat, scrollToTop} from "../../lib/functions";
import requestApi, {actions, methods, SUCCESS} from "../../lib/ServerRequest";
import {Link, useParams} from "react-router-dom";
import {NavLink} from "reactstrap";
import {setPage} from "../../lib/Store/actions/appApiData";
import Erp from "./erp";

const fusioninclude = [
    {name:'IMEI Service'},
    {name:'File Service'},
    {name:'Server Service'},
    {name:'Remote Client APi'},
    {name:'Suppliers APi'},
    {name:'Client Management'},
    {name:'CMS (Content management system)'},
    {name:'Support Ticket System'},
    {name:'Blogger'},
    {name:'Knowledgebase'},
    {name:'Affiliates System'},
    {name:'Pro Utilities'},
    {name:'Installation Service'}
];

const ecommerceinclude = [
    {name:'Client Management'},
    {name:'CMS (Content management system)'},
    {name:'Support Ticket System'},
    {name:'Blogger'},
    {name:'Knowledgebase'},
    {name:'Affiliates System'},
    {name:'Pro Utilities'},
    {name:'Installation Service'}
];


const Index = (props) => {

    const params = useParams()

    const {product} = params;
    const productname = product === 'dhru-fusion'?'DHRU Fusion':product === 'dhru-erp'?'DHRU ERP':'DHRU Ecommerce';

    const [loader,setLoader] = useState(false)

    const [state,setState] = useState({
        product:product,
        addons:false,
        addontotal:0,
        currency:'USD',
        includes: product === 'dhru-fusion'?fusioninclude:ecommerceinclude,
        productkey : product === 'dhru-erp'?'3d44bcb5-afbc-4153-af6a-aa3457ebe119':'b152adfc-5829-4c3c-a3b7-6df8e4a3cf52'
    })



    scrollToTop();

     useEffect(()=>{
         _accordion();
         const {product,currency,productkey} = state;
         document.title = ` ${productname} Pricing | Application for Businesses`;
         if(product === 'dhru-fusion' || product === 'dhru-ecommerce' || product === 'dhru-erp') {
             requestApi({
                 method: methods.get,
                 action: actions.init,
                 otherurl: 'https://api.dhru.com/client/api/v1/'
             }, (response) => {
                 setLoader(true)
                 if (response.status === SUCCESS) {
                     const {addons, price} = response.data.static.items[productkey];
                     setState({...state,addons: addons, price: price[currency].price,_price: price[currency]._price});
                 }
             });
         }
     },[])

    const addtocart = (addon,key) => {
        const {currency} = state;
        let array = {...state.addons,[key]:{...state.addons[key],selected:!state.addons[key].selected}};
        let addontotal =  0;

        map(array,function (key,value) {
            if(value.selected){
                addontotal += +value.price[currency].price;
            }
        })
        setState({...state,addons:array,addontotal:addontotal});
    }

    const checkout = ()=>{
        let aids = '';
        const {productkey} = state;
        state.addons && map(state.addons,function (key,value) {
            if (Boolean(value.selected)){
                if (Boolean(aids)){
                    aids+=","+key
                }else {
                    aids = key;
                }
            }
        });
        window.location.href = 'https://account.dhru.com/checkout/?pid='+productkey+'&aids='+aids;
        return null;
    }


    if(!loader){
        return <></>
    }

    const {price,_price,addons,includes,currency,addontotal} = state;

    const removeAddons = ['c9e19240-1533-430b-9607-9e55300e7cdf','ebe1d5e8-ed58-42e7-b0b8-1a39ad065ff5','5b612c9f-cf4d-4c3c-a2f1-36278712e3e4']

    return(
        <Fragment>


            {addons && product !== 'dhru-erp' && <section className="section section--light-blue-bg">
                <div className="container container-full">
                    <div className="section-heading section-heading--center">

                        <h2 className="__title"> {productname} <span>Pricing </span></h2>

                    </div>

                    <div className="spacer py-6"></div>

                    <div className="row">
                        <div className="col-12">

                            <div className="content-container">

                                <div className="pricing-table pricing-table--s1">
                                    <div className="__inner">
                                        <div className="row justify-content-sm-center row-reverse justify-content-md-start">

                                            <div className="col-12 col-sm-9 col-md-6 col-lg-4 d-sm-flex">
                                                <div className="__item __item--rounded">
                                                    <div className="__header">
                                                        <h3 className="__title h3">Base Package Includes</h3>
                                                        <p>Perfect to get started</p>
                                                    </div>

                                                    {includes &&  <div className="__body  pt-8 pb-9 mt-5 mb-5">
                                                        <ul className="__desc-list check-list">
                                                            {
                                                                map(includes,function (key,value) {
                                                                    return(
                                                                        <li key={key}><i className="ico-checked fontello-ok"></i>{value.name}</li>
                                                                    )
                                                                })
                                                            }
                                                        </ul>
                                                    </div>}


                                                </div>
                                            </div>

                                            <div className="col-12 col-sm-9 col-md-6 col-lg-4 d-sm-flex">
                                                <div className="__item __item--rounded">
                                                    <div className="__header">
                                                        <h3 className="__title h3">Select Add-ons</h3>
                                                        <p>Just add some magic</p>
                                                    </div>

                                                    <div className="__body  pt-8 pb-9 mt-5 mb-5">
                                                        <ul className="__desc-list">

                                                            {
                                                                map(addons,function (key,addon) {

                                                                    if(!removeAddons.includes(key)) {

                                                                        return (
                                                                            <li key={key}
                                                                                className="text-left cursor-pointer d-flex addon"
                                                                                onClick={() => addtocart(addon, key)}>
                                                                                <div style={{width: '35px'}}
                                                                                     className="text-center"
                                                                                     title="Click add to cart"
                                                                                     data-toggle="tooltip">
                                                                                    {addon.selected && <span> <i
                                                                                        className="ico-checked fontello-ok"></i></span>}
                                                                                    {!addon.selected && <span><i
                                                                                        className="fal fa-plus"></i></span>}
                                                                                </div>
                                                                                <div>
                                                                                    {addon.name}
                                                                                    <span
                                                                                        className="badge addon-price badge-secondary ml-4">
                                                                                       {addon?.price[currency]?.billingcycle} : {numberFormat(addon?.price[currency]?.price)}
                                                                                    </span>
                                                                                    <div>
                                                                                        <small
                                                                                            dangerouslySetInnerHTML={{__html: addon.description}}/>
                                                                                    </div>
                                                                                </div>
                                                                            </li>
                                                                        )
                                                                    }
                                                                })
                                                            }
                                                        </ul>
                                                    </div>

                                                </div>
                                            </div>

                                            <div className="col-12 col-sm-9 col-md-6 col-lg-4">

                                                <div className="__item __item--rounded mb-3">
                                                    <div className="__header">
                                                        <h3 className="__title h3"><i
                                                            className="fal fa-shopping-cart"></i></h3>
                                                    </div>

                                                    <div className="__body w-100  pt-8 pb-9  mt-5 mb-5">


                                                        <ul className="__desc-list p-5 bg-light">
                                                            <li className="text-left cursor-pointer d-flex w-100  justify-content-between">
                                                                <div>
                                                                    <h5>{product}</h5>
                                                                </div>
                                                                <div className="text-right">
                                                                    <h5> {numberFormat(price)} </h5>
                                                                    {(_price !== price) && <div style={{textDecoration:'line-through'}}><small className={'text-danger'}>{numberFormat(_price)}</small></div>}
                                                                </div>
                                                            </li>

                                                            {
                                                                map(addons,function (key,addon) {

                                                                    return(<Fragment key={key} >
                                                                            {addon.selected && <li className="text-left  mt-3 d-flex   w-100 justify-content-between">
                                                                                <div className="pl-2">
                                                                                        <span>
                                                                                             {addon.name}
                                                                                        </span>
                                                                                    <span className="cursor-pointer p-2   text-danger" onClick={()=> addtocart(addon,key)} style={{fontSize: '12px'}} >
                                                                                            <i className="fal fa-times"></i>
                                                                                        </span>
                                                                                </div>
                                                                                <div className="text-right">
                                                                                    <div>{ numberFormat(addon.price[currency].price)}</div>
                                                                                    {(addon.price[currency].price !== addon.price[currency]._price) && <div  style={{textDecoration:'line-through'}}><small className={'text-danger'}>{ numberFormat(addon.price[currency]._price)}</small></div>}
                                                                                </div>
                                                                            </li>}
                                                                        </Fragment>
                                                                    )
                                                                })
                                                            }

                                                        </ul>


                                                        <div className="text-right p-5">
                                                            <h3> {numberFormat(+price + addontotal)} </h3>
                                                        </div>

                                                    </div>


                                                    <p className="text-muted p-4" style={{fontSize: '12px'}}>
                                                        * This Price does not Include Additional Taxes,
                                                        GST Will calculate on final Invoice page depends on country and
                                                        state if applicable
                                                    </p>



                                                    <div className="__footer w-100">
                                                        <div className="pl-5 pr-5">
                                                            <button
                                                                className="custom-btn custom-btn--medium custom-btn--style-4 wide"
                                                                type="button" onClick={()=>checkout()}>Checkout
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>



                                            </div>


                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </section>}

            {product === 'dhru-erp' &&
                <section className="section section--light-blue-bg">
                    <div className="container">
                        <div className="section-heading section-heading--center">
                            <h2 className="__title"> DHRU ERP <span>Pricing </span></h2>
                        </div>

                        <div className="spacer py-6"></div>

                        <div className="row">
                            <div className="col-12">
                                <div className="content-container">
                                    <Erp checkout={checkout}/>
                                </div>
                            </div>
                        </div>

                    </div>
                </section>
            }


            {(product === 'dhru-fusion' || product === 'dhru-ecommerce') &&   <section className="section section--light-blue-bg">
                <div className="container">
                    <div className="section-heading section-heading--center">

                        <h2 className="__title">Frequently <span>Asked Questions</span></h2>
                    </div>

                    <div className="spacer py-6"></div>

                    <div className="row">
                        <div className="col-12">

                            <div className="content-container">

                                <div className="faq">
                                    <div className="accordion-container">

                                        <div className="accordion-item active">
                                            <div className="accordion-toggler">
                                                <h4 className="accordion-title">What's included?</h4>

                                                <i className="circled"></i>
                                            </div>

                                            <article className="accordion-content">
                                                <div className="accordion-content__inner">
                                                    <p>
                                                        Your Download license includes a perpetual Dhru Fusion
                                                        license, six months of access to product updates and
                                                        support, which is optionally renewable.
                                                    </p>
                                                </div>
                                            </article>
                                        </div>

                                        <div className="accordion-item">
                                            <div className="accordion-toggler">
                                                <h4 className="accordion-title">Total customization and control</h4>

                                                <i className="circled"></i>
                                            </div>

                                            <article className="accordion-content">
                                                <div className="accordion-content__inner">
                                                    <p>
                                                        Because the eCommerce is hosted in your own environment and
                                                        you have access to the full templates source code, you have
                                                        unlimited customization and control over your site .You can
                                                        modify your design as your organization's requirements
                                                    </p>
                                                </div>
                                            </article>
                                        </div>

                                        <div className="accordion-item">
                                            <div className="accordion-toggler">
                                                <h4 className="accordion-title">Six months of product updates and
                                                    support included</h4>

                                                <i className="circled"></i>
                                            </div>

                                            <article className="accordion-content">
                                                <div className="accordion-content__inner">
                                                    <p>
                                                        Because your license is perpetual, you can continue using
                                                        your Fusion license without an active product updates and
                                                        support subscription. Your subscription is optionally
                                                        renewable at 300$ for 12 months.
                                                    </p>
                                                </div>
                                            </article>
                                        </div>

                                        <div className="accordion-item">
                                            <div className="accordion-toggler">
                                                <h4 className="accordion-title">Upgrading licenses and add-ons</h4>

                                                <i className="circled"></i>
                                            </div>

                                            <article className="accordion-content">
                                                <div className="accordion-content__inner">
                                                    <p>
                                                        At any point, it is possible to upgrade your license or even
                                                        your product (from Fusion lite to Fusion) and you will only
                                                        need to pay the difference. You can also buy add-on later as
                                                        you have Demand.
                                                    </p>
                                                </div>
                                            </article>
                                        </div>

                                        <div className="accordion-item">
                                            <div style={{padding: '30px 0'}}>
                                                <h4 className="accordion-title">
                                                    <NavLink
                                                        className="custom-btn custom-btn--medium custom-btn--style-2"
                                                        type="button" onClick={()=>setPage({page:'contactus'})}   tag={Link}  to={"/contactus"}> Want to know more?
                                                    </NavLink>
                                                </h4>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </section> }
        </Fragment>
    );

}

const mapStateToProps = (state) => {
    return {

    }
};

const mapDispatchToProps = (dispatch) => ({
    setDialog: (dialog) => dispatch(setDialog(dialog)),
});


export default connect(mapStateToProps, mapDispatchToProps)(Index);
