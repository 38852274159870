import React from 'react';
import ReactDOM from "react-dom/client";
import {Provider} from 'react-redux';
import {BrowserRouter, Router} from "react-router-dom";
import * as serviceWorker from './serviceWorker';
import configureStore from './lib/Store/configureStore'
import Loader from "./components/Loader/loader"
import RoutingComponent from "./lib/RoutingComponent";
import Dialog from "./components/Modal";
import {createBrowserHistory} from "history";
import Header from "./pages/Navigation/Header";
import Footer from "./pages/Navigation/Footer";
import './scss/index.scss';

import AOS from 'aos';
import {GoogleReCaptchaProvider} from "react-google-recaptcha-v3";
AOS.init();

export const store = configureStore();

export const history = createBrowserHistory({
    basename: process.env.PUBLIC_URL
});


const JSX = () => {


    return (
        <Provider store={store}>
            <GoogleReCaptchaProvider reCaptchaKey="6LebDscZAAAAAEfuz_E-rB6r12xLHekW_XlR-Y5V" useRecaptchaNet={true}>
            <BrowserRouter history={history}>
                <Header location={history.location} />
                <RoutingComponent />
                <Footer/>
                <Dialog/>
            </BrowserRouter>
            <Loader/>
            </GoogleReCaptchaProvider>
        </Provider>
    )
};

//localStorage.clear();
console.clear();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <JSX />
);


serviceWorker.unregister();


