import {LOGIN, LOGOUT, SYSTEM_SETUP} from "../definitions/authentication";

const login = (authDetails) => ({
    type: LOGIN,
    authDetails,
});

const logout = () => ({
    type: LOGOUT
});

const setSystemSetup = (system_setup) => ({
    type: SYSTEM_SETUP,
    system_setup
});

export {login, logout, setSystemSetup};