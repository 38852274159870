import {LOGIN, LOGOUT, SYSTEM_SETUP} from "../definitions/authentication";

const initState = {isAuthenticated: false,authDetails:{user:false}};

export default (state = initState, action) => {
    switch (action.type) {
        case LOGIN:
            const {authDetails} = action;
            if (authDetails) {
                return {
                    isAuthenticated: true,
                    token: authDetails.token,
                    authDetails
                };
            }
            return state;
        case LOGOUT:
            return initState;
        case SYSTEM_SETUP:

            const {system_setup}=action;
            return {
                ...state,
                system_setup
            };
        default:
            return state;
    }
};
