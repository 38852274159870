import React, {Component, Fragment} from "react";
import {connect} from "react-redux";
import {setDialog} from "../../lib/Store/actions/components";
import {scrollToTop} from "../../lib/functions";


class Index extends Component {


    constructor(props) {
        super(props);
        this.state={

        }
    }

    componentDidMount() {
        document.title = "DHRU Customer Development Agreement";
        scrollToTop();
    }

    render() {

        return(
            <Fragment>


                <div className="container mt-5">
                    <div className={'my-5'}>
                        <div className="blog">
                            <div className="entry ">

                                THIS AGREEMENT is made this [DAY] day of [MONTH] , [YEAR] by and between Qualiteam Corp
                                ("Developer") and [COMPANY_NAME] ("Customer").
                                <br/><br/>

                                    <h3>Recitals</h3>
                                    <ul style={{listStyle:'upper-alpha',paddingLeft: '30px'}}>
                                        <li>Customer has obtained a license of certain Software Programs ("Software
                                            Programs") from the Developer whose Software Programs are described in
                                            Ordered products list.
                                        </li>
                                        <li>Customer requires certain custom modifications to be made to the Software
                                            Programs in accordance with the functional and technical specifications set
                                            forth in Order description #[SPECIFICATION_NUMBER] ("Specification for
                                            Software Modifications") also accessible via the following URL
                                            [SPECIFICATION_URL] and wishes to retain the services of the Developer to
                                            make such custom modifications.
                                        </li>
                                        <li>Developer wishes to retain all copyrights in and to the Software
                                            Modifications and Customer wishes to receive a perpetual license to use the
                                            Software Modifications.
                                        </li>
                                        <li>The Developer has expertise in the areas desired by the Customer and
                                            Developer wishes to accept the assignment to provide services to the
                                            Customer pursuant to the terms and conditions set forth in this Agreement.
                                        </li>
                                    </ul>

                                    <br/>

                                        <h3>1. Provision of service</h3>
                                        <ul style={{listStyle:'upper-alpha',paddingLeft: '30px'}}>
                                            <li>Definition of Modification Specifications. The technical and functional
                                                aspects of the Software Program modifications are as set forth in Order
                                                description. Developer shall perform all necessary programming in order
                                                to create the modifications to the Software Programs in substantial
                                                compliance with the Specifications.
                                            </li>
                                            <li>Commencement of Development. Developer shall commence development work
                                                on the Software Modifications within the term specified in Project's
                                                timeframe after payment of the development fee specified in the invoice
                                                provided with these Conditions. Such Software Modifications shall be
                                                created substantially in conformance with the Order description.
                                            </li>
                                            <li>Changes To Specifications. The parties expect that there will be some
                                                changes to the Specifications after acceptance by both parties. Requests
                                                for changes shall be proposed by the Development Coordinators or the
                                                Technical Coordinators, but shall not be effective without the written
                                                consent of both parties. Prior to acceptance of any change requests, the
                                                parties shall cooperate to equitably determine the impact on pricing,
                                                time commitments, scheduling and deadlines and other project factors and
                                                reflect these changes in the change order. All change orders must be
                                                approved and executed by the Development Coordinators of each party.
                                            </li>
                                            <li>Plan of Development. The development of the Software Modifications shall
                                                be conducted substantially in compliance with the Plan of Development
                                                attached hereto as Project timeframe (hereinafter referred to as "Plan
                                                of Development"). The Plan of Development includes a description of
                                                various steps involved in the development process, various development
                                                phases with a description of the milestones to be achieved in each
                                                stage, estimated dates of completion for each phase of development,
                                                allocation of tasks for each phase and a listing of items and input to
                                                be provided by the Customer for each phase, and definition of the
                                                Deliverables to be provided at the end of each phase and upon completion
                                                of the development process. The Development Coordinators shall
                                                periodically review the Plan of Development during the project and
                                                discuss any necessary revisions as the project moves forward. Developer
                                                shall devote sufficient time and effort and shall allocate sufficient
                                                personnel resources to the project as may be required for the
                                                development and testing thereof. Developer shall conduct and conclude
                                                such development and tests in a professional manner, incorporate into
                                                the final version such modifications as the tests indicate are
                                                necessary, and conduct such further tests as may be required under the
                                                circumstances. Developer shall not be in default for failure to meet
                                                these timetable goals provided that the overall project is proceeding in
                                                a reasonable fashion and Developer is substantially achieving project
                                                objectives. Developer shall inform the Customer of factors that will
                                                lead to delays such as labor shortages, technical difficulties,
                                                competing projects, mechanical problems and other factors.
                                            </li>
                                            <li>Delivery. Upon completion of the Software Modifications, Developer shall
                                                notify the Development Coordinator for the Customer. The Development
                                                Coordinators for each party shall then arrange the logistics of making
                                                delivery of the Deliverables (as defined herein) and for performing
                                                acceptance testing. Upon delivery, the Development Coordinators shall
                                                prepare and execute a mutually acceptable "Delivery Certificate."<br/>
                                            </li>
                                            <li>Performance of Acceptance Testing. Upon delivery, the parties shall
                                                reasonably cooperate in good faith to perform the agreed acceptance
                                                testing procedures. If according to the Original specification a project
                                                has more than one phase, after finishing each phase Developer presents
                                                the results of his work to the Customer for acceptance testing. In no
                                                event shall the acceptance testing process last more than 7 (seven) days
                                                following delivery, unless specifically provided in the agreed
                                                acceptance testing procedures for the project. Following the performance
                                                of acceptance testing, Customer shall, acting in good faith, give
                                                written notice of acceptance or rejection of the Deliverables. In the
                                                event the Deliverables are rejected, Customer shall detail the reasons
                                                for such rejection in the written notice. Additionally, the Customer
                                                shall identify with specificity the portions of the acceptance tests
                                                that form the basis for the rejection. In the event that such written
                                                notice is not provided within 7 (seven) days following delivery, the
                                                Customer shall be deemed to have accepted the Deliverables. Following
                                                receipt of notice of rejection, Developer shall use reasonable diligence
                                                to correct any deficiencies cited in good faith by the Customer and to
                                                resolve any Customer concerns over the Deliverables.
                                            </li>
                                        </ul>

                                        <br/>
                                            <h3>2. Compensation</h3>
                                            <ul style={{listStyle:'upper-alpha',paddingLeft: '30px'}}>
                                                <li> Project Pricing. Customer shall pay to the Developer a Development
                                                    Fee equal to $ [SUM_FOR_ITEM] for the development of the Software
                                                    Modifications. Such Development fee shall be paid in the
                                                    installments set forth in the Plan of Development based upon project
                                                    milestones indicated therein.
                                                </li>
                                                <li> Timing of Payments. All payments shall be due upon achievement of
                                                    the milestones set forth in the Plan of Development and performing
                                                    necessary acceptance testing by the Customer. In case if project has
                                                    more than one phase, the fact of payment for the next phase
                                                    indicates the acceptance of the previous phase. Customer shall be in
                                                    default under these Conditions if payment is not received within 5
                                                    days following such dates unless the Customer disputes the invoice
                                                    in good faith. Upon default in payment, Developer is authorized to
                                                    suspend work hereunder until the default is cured by payment in
                                                    full. In the event that such default is not cured within 15 days
                                                    following the invoice, the Developer may terminate supply of
                                                    customization services by written notice to the Customer. Upon such
                                                    termination, Developer shall have no further obligations under these
                                                    Conditions but all payments due through the date of such termination
                                                    shall remain due and payable and Developer may take any and all
                                                    actions necessary to collect the same, including but not limited to
                                                    withholding delivery of any work product produced.
                                                </li>
                                                <li> Project Expense Reimbursement. The fee to be paid to the Developer
                                                    shall be exclusive of expense reimbursement which shall be treated
                                                    separately. All such expenses shall be due and payable upon invoice
                                                    to Customer. Alternatively, Developer may request that such expenses
                                                    be paid directly by the Customer or paid in advance to the
                                                    Developer. The parties shall use their reasonable efforts to
                                                    estimate expenses when creating the Plan of Development. Expenses
                                                    may include such items as (i) postal charges, federal express,
                                                    facsimile charges, long distance telephone charges, and other costs
                                                    of project specific communications, (ii) costs of purchasing or
                                                    licensing graphics, sound, or other content form third parties,
                                                    (iii) costs of any special software or hardware that is necessary to
                                                    complete the specific development task for the project, (iv) travel
                                                    expenses, (v) costs of acquiring or leasing any special development
                                                    tools made necessary by the nature of the project, and (vi) all
                                                    other expenses identified by the parties in the Plan of Development.
                                                    Expense items shall not include items within the normal overhead and
                                                    operating expenses of the Developer.
                                                </li>
                                                <li> Verification Records. Developer shall maintain accounting, time,
                                                    and other records as are necessary to verify any amount to be paid
                                                    by the Customer hereunder. Upon any dispute of any invoice,
                                                    Developer shall provide backup records to support the invoice that
                                                    is questioned if applicable.
                                                </li>
                                            </ul>

                                            <br/>
                                                <h3>3. Proprietary rights</h3>
                                                <ul style={{listStyle:'upper-alpha',paddingLeft: '30px'}}>
                                                    <li> License of Software. Upon payment in full of all amounts due to
                                                        the Developer, the Developer shall grant to the Customer a
                                                        perpetual, world-wide license to use the Software Modifications.
                                                        Such license shall be limited to the right to:
                                                        <ul style={{paddingLeft: '20px'}}>
                                                            <li>install the computer software portion of the Software
                                                                Program containing the Software Modifications on
                                                                computer systems owned, leased, or controlled by
                                                                Customer,
                                                            </li>
                                                            <li>utilize the Software Programs containing the Software
                                                                Modifications for its own internal purposes,
                                                            </li>
                                                            <li>make sufficient copies of the Software Program
                                                                containing the Software Modifications for backup
                                                                purposes and as reasonably necessary to exercise the
                                                                right to use the Software Programs as permitted
                                                                hereunder
                                                            </li>
                                                            <li>transfer the Software Modifications and license to
                                                                another party if the other party agrees to accept the
                                                                terms and conditions of this Agreement.
                                                                <br/><br/>
                                                                    This Agreement shall not provide Customer with the
                                                                    right of ownership or title in and to the Software
                                                                    Programs containing the Software Modifications but
                                                                    rather, Customer's rights shall be limited to the
                                                                    scope of license provided herein. Customer shall not
                                                                    take any action or permit any occurrence that would
                                                                    create a lien or encumbrance on the Software Program
                                                                    containing the Software Modifications or the
                                                                    copyright thereto, or create any cloud on
                                                                    Developer's title thereto. Customer shall not use
                                                                    the Software Program containing the Software
                                                                    Modifications in such a manner that may infringe
                                                                    upon the rights of any other party.</li>
                                                        </ul>
                                                    </li>
                                                    <li> Exclusivity. The license granted to the Customer hereunder
                                                        shall not be exclusive to the Customer.
                                                    </li>
                                                    <li> Pre-Existing Works. In the event that the Deliverables include
                                                        any pre-existing works created by the Developer or any other
                                                        party, the Developer hereby grants, and shall arrange for
                                                        applicable third parties to grant, a perpetual, non-exclusive,
                                                        royalty free license to use such pre-existing works in
                                                        connection with the Deliverables.
                                                    </li>
                                                    <li> Infringement On Third Party Rights. The Developer agrees to use
                                                        reasonable diligence to avoid infringement on the proprietary
                                                        rights of any third party in performance of the creation of
                                                        Deliverables. The Developer agrees that all aspects of the
                                                        Deliverables shall be original works of creation and shall not
                                                        use, in whole or in part, any work created by any other party,
                                                        except, where specifically disclosed by the Developer to the
                                                        Customer and where a License to use such items is obtained for
                                                        the benefit of the Customer. All such licenses shall be royalty
                                                        free, perpetual, world-wide licenses, sufficient in scope to
                                                        permit Customer's full use and enjoyment of the Deliverables,
                                                        except where specifically agreed in writing by the Customer.
                                                    </li>
                                                    <li> Inventions. The parties intend that all inventions and
                                                        creations developed by Developer in the course of the
                                                        development project, conditioned on full and complete payment of
                                                        all amounts due to Developer hereunder, shall be the property of
                                                        the Developer, subject only to the license granted hereunder.
                                                        For purposes of this Agreement, Inventions shall mean and
                                                        include any and all ideas, concepts, discoveries, designs,
                                                        improvements, and creations, regardless of whether the same are
                                                        patentable or protected under any Federal or State law, rule or
                                                        regulation or under the common law of any state, or under
                                                        International law.
                                                    </li>
                                                    <li> Reservation of Proprietary Rights In Developer. Customer
                                                        acknowledges and agrees that the Development Fee applicable to
                                                        this project was negotiated based upon the Developer retaining
                                                        the copyright and all other proprietary rights in and to the
                                                        Software and other Inventions created hereunder and that the
                                                        Development Fee would have been substantially greater had the
                                                        Developer not retained the copyright in and to such items.
                                                        Therefore, Customer represents, acknowledges and agrees that the
                                                        Software Modifications are valuable trade secrets and
                                                        proprietary property of the Developer. Customer further
                                                        acknowledges and agrees that Developer retains all copyrights
                                                        and proprietary rights in and to the Software Modifications.
                                                        Customer agrees to refrain from any copying, distribution, or
                                                        other infringements on the exclusive rights of the Developer as
                                                        the copyright owner of the Software Modifications, except as
                                                        specifically permitted in the license granted hereunder.
                                                        Customer agrees to maintain the confidentiality of the Software
                                                        Modifications and to protect the same as trade secrets of the
                                                        Developer. Customer further agrees to immediately notify the
                                                        Developer of any third party infringement on the Developer's
                                                        rights of which the Customer may become aware.
                                                    </li>
                                                    <li> Confidential Information. Customer shall not, at any time,
                                                        disclose or disseminate the trade secrets embodied in the
                                                        Software Modifications or any supporting program documentation
                                                        to any other person, firm, organization, or employee who does
                                                        not need to obtain access thereto consistent with Customer's
                                                        rights under this Agreement. Under no circumstances may Customer
                                                        disclose or disseminate such trade secrets to any competitor of
                                                        Developer. Customer shall devote Customer' s best efforts to
                                                        ensure that all persons afforded access to the Software
                                                        Modifications and all supporting program documentation protect
                                                        Developer's trade secrets against unauthorized use,
                                                        dissemination, or disclosure.
                                                    </li>
                                                    <li> Customer's Proprietary Material. Notwithstanding the above,
                                                        Customer shall retain and, Developer shall have no proprietary
                                                        rights whatsoever in all of Customer's intellectual property
                                                        rights in any and all text, images or other components and/or
                                                        materials owned by Customer, or which Customer has the legal
                                                        right to use, that are delivered to Developer, including but not
                                                        limited to software, related documentation, Customer marketing
                                                        material, logos, and tag lines ("Customer's Proprietary
                                                        Material"). Developer agree that shall not use Customer's
                                                        Proprietary Material for any other purpose than those expressly
                                                        set forth in this Agreement.
                                                    </li>
                                                </ul>

                                                <br/>
                                                    <h3>4. Support</h3>
                                                    <ul style={{listStyle:'upper-alpha',paddingLeft: '30px'}}>
                                                        <li>Basic Support Services. For a period of 6 (six) months
                                                            following delivery of the Deliverables to the Customer,
                                                            Developer shall provide certain support services to the
                                                            Customer related to the Software Modifications including the
                                                            following: (i) web-based support regarding operation and use
                                                            of the Software Modifications during the Developer's normal
                                                            business hours and (ii) such further programming services to
                                                            correct demonstrated errors in the operation of the Software
                                                            Modifications which are the result of incorrect
                                                            implementation of the requested Software modifications and
                                                            necessary to enable the Software Modifications to operate
                                                            substantially in accordance with the Specifications.
                                                            DEVELOPER SHALL NOT HAVE ANY OBLIGATION TO CORRECT ERRORS IN
                                                            THE OPERATION OF THE SOFTWARE MODIFICATIONS IF THE
                                                            PROGRAMMING CODE HAS BEEN MODIFIED BY CUSTOMER OR BY ANY
                                                            OTHER PARTY.
                                                        </li>
                                                        <li>Compensation For Support Services. Customer shall compensate
                                                            Developer at Developer's published rates for providing such
                                                            support services that are necessary after acceptance of the
                                                            Software Modifications. Additionally, Customer shall be
                                                            responsible for paying or reimbursing Developer for all
                                                            costs and expenses reasonably incurred by the Developer
                                                            resulting from the provision of the support services. All
                                                            compensation for support services shall be paid within 10
                                                            days following invoice therefore. Developer may require the
                                                            Customer to pay the estimated amount for such services in
                                                            advance of performing any support services hereunder. In the
                                                            event that the Customer fails to make any payment within 10
                                                            days of invoice, or if the Customer fails to pay any amount
                                                            required by the Developer in advance, Developer shall not
                                                            have any obligation to perform support services.
                                                        </li>
                                                        <li>Customer Responsibilities. Customer shall not attempt to
                                                            correct any errors in the Software Modifications or do
                                                            anything to alter or modify any programming code. Customer
                                                            shall promptly report any errors in the operation of the
                                                            Software Modifications to the Developer and shall not take
                                                            any actions that would increase the severity of the error.
                                                            Customer shall use the Software Modifications only for its
                                                            intended purpose and only in the manner intended. IN THE
                                                            EVENT THAT THE CUSTOMER VIOLATES ANY OF THE REQUIREMENTS OF
                                                            THIS SECTION, THE DEVELOPER SHALL HAVE NO RESPONSIBILITY TO
                                                            PROVIDE SUPPORT SERVICES, THESE REQUIREMENTS EXPRESS
                                                            PRECONDITIONS TO THE AVAILABILITY OF DEVELOPER SUPPORT
                                                            SERVICES HEREUNDER.
                                                        </li>
                                                        <li>Place of Support Services. All support services shall be
                                                            provided by the Developer from the Developer's facility, or
                                                            through electronic access to the Customer's computers,
                                                            unless the Developer determines, in its sole and absolute
                                                            discretion, that it is necessary to perform such services at
                                                            the Customer's facilities.
                                                        </li>
                                                    </ul>

                                                    <br/>
                                                        <h3>5. Representations and warranties of the developer</h3>
                                                        <ul style={{listStyle:'upper-alpha',paddingLeft: '30px'}}>
                                                            <li> Software Version &amp; Components. Software
                                                                Customizations are based on a certain version of the
                                                                Software as well as on certain set of add-on modules and
                                                                skin installed. Developer DOES NOT GUARANTEE that
                                                                Software Modifications will be suitable for:
                                                                <ul style={{paddingLeft: '20px'}}>
                                                                    <li> the other versions of the Software</li>
                                                                    <li> the Software with different set of add-on
                                                                        modules
                                                                    </li>
                                                                    <li> the Software with different skin</li>
                                                                </ul>
                                                            </li>
                                                            <li> Performance. Developer represents and warrants that
                                                                service hereunder shall be performed in a workmanlike
                                                                manner consistent with industry standards, that all
                                                                Deliverables shall function substantially in compliance
                                                                with agreed specifications, and that it shall perform
                                                                all development tasks in compliance with all applicable
                                                                state, federal and local laws, rules and regulations..
                                                            </li>
                                                            <li> Pre-Existing Works. Developer represents and warrants
                                                                that Customer, upon payment in full of all amounts due
                                                                hereunder, shall have a perpetual, royalty free,
                                                                worldwide license to use and pre-existing works, whether
                                                                developed by the Developer or by and other party, which
                                                                are used in connection with the Deliverables.
                                                            </li>
                                                            <li> Warranty Disclaimers. Other than as specifically set
                                                                forth in this Agreement, THE DELIVERABLES ARE DELIVERED
                                                                TO CUSTOMER ON "AS IS" BASIS, WITHOUT ANY WARRANTIES OR
                                                                REPRESENTATIONS EXPRESS, IMPLIED, INCLUDING, WITHOUT
                                                                LIMITATION, WARRANTIES OF MERCHANTABILITY OR FITNESS FOR
                                                                A PARTICULAR PURPOSE, QUALITY, PERFORMANCE OR
                                                                NONINFRINGEMENT UPON THE RIGHTS OF ANY OTHER PARTY.
                                                                DEVELOPER MAKES NO WARRANTY THAT THE DELIVERABLES WILL
                                                                MEET CUSTOMER'S SPECIFIC OBJECTIVES OR NEEDS OR THAT THE
                                                                DELIVERABLES WILL BE FREE FROM ERRORS OR BUGS. DEVELOPER
                                                                MAKES NO WARRANTY THAT THERE WILL BE UNINTERUPTED
                                                                OPERATION OF THE DELIVERABLES. CUSTOMER ACKNOWLEDGES AND
                                                                AGREES THAT THE FOREGOING EXCLUSIONS AND DISCLAIMERS OF
                                                                WARRANTIES ARE AN ESSENTIAL PART OF THIS AGREEMENT AND
                                                                FORMED THE BASIS FOR DETERMINING THE PRICE CHARGED FOR
                                                                THE PRODUCTS.
                                                            </li>
                                                            <li> Limitation On Damages. In no event will Developer be
                                                                liable for any incidental, special or consequential
                                                                damages in spite of the fact that the Customer
                                                                acknowledges that Customer has been fully advised that
                                                                such damages are possible. Under no circumstances will
                                                                damages assessed against Developer exceed the
                                                                development fee (exclusive of costs and expenses)
                                                                actually paid to the Developer.
                                                            </li>
                                                            <li> Third Party Claims. Developer shall not liable for
                                                                claims made against the Customer or the Developer
                                                                arising out of Customer's use or ownership of the
                                                                Deliverables and Customer hereby indemnifies and holds
                                                                the Developer harmless from and against any and all
                                                                claims, of every nature or type that may be brought or
                                                                asserted by any other party.
                                                            </li>
                                                        </ul>

                                                        <br/>
                                                            <h3>6. Terms and terminations of provision of service</h3>
                                                            <ul style={{listStyle:'upper-alpha',paddingLeft: '30px'}}>
                                                                <li>Term. The license to use the Software Modifications
                                                                    contained herein shall be perpetual unless sooner
                                                                    terminated pursuant to the terms hereof. The
                                                                    obligation to provide support services following
                                                                    acceptance shall terminate following the expiration
                                                                    of the period described in the Article applicable to
                                                                    support services.
                                                                </li>
                                                                <li> Termination Without Cause. Either party may
                                                                    terminate the provision of Customization Services
                                                                    prior to completion of the development project, with
                                                                    or without cause, by giving 10 (ten) days prior
                                                                    written notice of termination to the other party.
                                                                </li>
                                                                <li> Termination For Cause. Either party may terminate
                                                                    the provision of Customization Services for cause in
                                                                    the event that the other party substantially
                                                                    defaults under any term or condition contained
                                                                    herein; provided that if the termination is against
                                                                    the Developer based upon the performance or lack of
                                                                    performance of the Developer's development
                                                                    responsibilities, the Developer shall first be given
                                                                    thirty (30) days advanced written notice specifying
                                                                    the alleged deficiency in detail and Developer shall
                                                                    have such thirty (30) day period to reasonably cure
                                                                    the alleged default.
                                                                </li>
                                                                <li>Termination Prior To Completion. Upon any early
                                                                    termination prior to completion of development, the
                                                                    Developer shall immediately cease work on the
                                                                    relevant project and shall issue an invoice to the
                                                                    Customer for all work performed through the date of
                                                                    termination. The final invoice shall be due and
                                                                    payable by the Customer upon receipt thereof.
                                                                </li>
                                                                <li>Survival of Certain Provisions. In the event of any
                                                                    termination, the following provisions shall continue
                                                                    in full force and affect: (i) the obligation of the
                                                                    Customer to make payments due hereunder to the
                                                                    Developer, (ii) confidentiality provisions, and
                                                                    (iii) representations and warranties as to
                                                                    proprietary rights of the Deliverables.
                                                                </li>
                                                            </ul>

                                                            <br/>
                                                                <h3>7. Miscellaneous provisions</h3>
                                                                <ul style={{listStyle:'upper-alpha',paddingLeft: '30px'}}>
                                                                    <li>Excusable Delays. Any delay or nonperformance of
                                                                        any provision of these Conditions caused by
                                                                        conditions beyond the reasonable control of
                                                                        Developer shall not constitute a breach of these
                                                                        Conditions, provided that Developer has taken
                                                                        reasonable measures to notify Customer of the
                                                                        delay in writing and uses reasonable efforts to
                                                                        perform in accordance with these Conditions
                                                                        notwithstanding such conditions. The delayed
                                                                        party's time for performance shall be deemed to
                                                                        be extended for a period equal to the duration
                                                                        of the conditions beyond its control. Conditions
                                                                        beyond a party's reasonable control include, but
                                                                        are not limited to disease of the responsible
                                                                        person, natural disasters, acts of government
                                                                        after the date of acceptance of these
                                                                        Conditions, power failure, fire, flood, acts of
                                                                        God, labor disputes, riots, acts of war and
                                                                        epidemics.
                                                                    </li>
                                                                    <li>Non-solicitation. Both the Customer and the
                                                                        Developer agree that while this Agreement is in
                                                                        force and for a period of twelve (12) months
                                                                        thereafter, they shall not directly or
                                                                        indirectly solicit or offer employment to any of
                                                                        the other's officers, employees, third party
                                                                        contractors and associates who have been
                                                                        involved in or associated with this Agreement
                                                                        without the other's prior written consent.
                                                                    </li>
                                                                    <li>Notices. Any notification or written
                                                                        communication required by or contemplated under
                                                                        the terms of this Agreement shall be in writing
                                                                        and shall be deemed to be delivered via Support
                                                                        Helpdesk (Ticket) .
                                                                    </li>
                                                                    <li>Governing law. This Agreement is governed by
                                                                        English law. Any dispute arising out or in
                                                                        connection with this agreement including any
                                                                        question regarding its existence, validity or
                                                                        termination, shall be referred to and finally
                                                                        resolved by arbitration under the Rules of the
                                                                        London Court of International Arbitration
                                                                        ("LCIA") which Rules are deemed to be
                                                                        incorporated by reference into this clause. The
                                                                        place of arbitration shall be London, England.
                                                                    </li>
                                                                </ul>

                                                                <br/>I have read and understood the Conditions of Supply
                                                                    of Services and "ordered products", "order
                                                                    description" and "project timeframe" attached
                                                                    hereto. I accept these Conditions of Supply of
                                                                    Services in aggregate with the information
                                                                    containing in and "ordered products", "order
                                                                    description" and "project timeframe" and hereby
                                                                    agree to abide by them.<br/>
                                                                        <br/>Signed by [COMPANY_NAME].<br/>
                                                                            <br/><br/><br/>


                            </div>
                        </div>
                    </div>
                </div>

            </Fragment>
        );
    }

}

const mapStateToProps = (state) => {
    return {

    }
};

const mapDispatchToProps = (dispatch) => ({
    setDialog: (dialog) => dispatch(setDialog(dialog)),
});


export default connect(mapStateToProps, mapDispatchToProps)(Index);
