import React, {Component} from "react";
import {connect} from "react-redux";
import {setDialog} from "../../lib/Store/actions/components";
import {ModalBody, ModalHeader,Modal} from "reactstrap";

class Index extends Component {


    handleClose = () => {
        const {setDialog} = this.props;
        setDialog({
            show: false,
        });
    };


    render() {
        const { component: Component,title,show,maxWidth,className,backdrop,keyboard} = this.props.dialogs;
        return (
            <Modal isOpen={show} toggle={this.handleClose} size={maxWidth?maxWidth:'xs'} className={className} backdrop={backdrop?backdrop:true} keyboard={keyboard?keyboard:true} >
                <ModalHeader toggle={this.handleClose}>{title?title:''}</ModalHeader>
                <ModalBody>
                    <Component/>
                </ModalBody>
            </Modal>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        dialogs: state.components.dialog,
    }
};

const mapDispatchToProps = (dispatch) => ({
    setDialog: (dialog) => dispatch(setDialog(dialog)),
});


export default connect(mapStateToProps, mapDispatchToProps)(Index);
