import {methods} from "./methods";
import {actions} from "./actions";
import {DEFAULT_MESSAGE, ERROR, SUCCESS} from "./status";
import {isDevelopment} from "../setting";
import { store} from "../../index";
import {enqueueSnackbar,  setLoader} from "../Store/actions/components";
import {apiUrl} from "../setting";
import {logout} from "../Store/actions/authentication";


const requestApi = ({method, action, queryString, body,alert=true,loader=true,otherurl,otherheaders,xhrRequest=false}, response) => {


    const {authentication} = store.getState();
    const token = authentication.token;
    const responseJson = {};


    let url = apiUrl;

    if(otherurl){
        url = otherurl;
    }

    if (action) {
        url += `${action}/`;
    }

    if (queryString) {
        url += jsonToQueryString(queryString);
    }


    let headers = {
        'Accept': '*/*',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': '*',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': '*',
        ...otherheaders
    };

    if (token) {
        headers = {
            ...headers,
            'Authorization': 'Bearer ' + token,
        };
    }

    const init = {
        method,
        headers: new Headers(headers)
    };
    if (body) {
        init.body = JSON.stringify(body);
    }


    if(loader) {
        store.dispatch(setLoader({
            show: true
        }));
    }

    if(xhrRequest){
        let xhr = new XMLHttpRequest();
        xhr.withCredentials = false;
        xhr.addEventListener("readystatechange", function() {
            if(this.readyState === 4) {
                if(this.status === 200) {
                    store.dispatch(setLoader({
                        show: false
                    }));
                    if (this.responseText) {
                        let data = JSON.parse(this.responseText);
                        if (response && data.status === SUCCESS) {
                            response(data);
                        }
                    }
                }
            }
        });
        xhr.open(method, url);
        xhr.setRequestHeader("Authorization", "Bearer "+token);
        xhr.send(body);
    }
    else{
        fetch(url, init)
            .then((result) => result.json())
            .then((data) => {
                store.dispatch(setLoader({
                    show: false
                }));
                if (isDevelopment) {
                    console.log(
                        "==========",
                        "\napi url : ", url,
                        "\nmethod : ", method,
                        "\naction : ", action,
                        "\nqueryString : ", queryString,
                        //"\nheaders : ",JSON.stringify(headers),
                        "\nbody : ", JSON.stringify(body),
                        "\nresponse : ", data,
                        "\n=========="
                    );
                }
                //let callback = '';
                //let message = data.message;
                const {code, status, message} = data;

                if (code === 401) {
                    store.dispatch(logout());
                    //action !== actions.login ;
                }
                 else {
                    if (response && status === SUCCESS) {
                        response(data);
                    }
                }
                if (message && alert) {
                    store.dispatch(enqueueSnackbar({
                        message: message,
                        options: {
                            key: new Date().getTime() + Math.random(),
                            variant: data.status
                        },
                    }));
                }


            })
            .catch((error) => {
                console.log(`${action} ERROR..`);
                if (isDevelopment) {
                    console.log('catch : ', action, error.message, error);
                }
                responseJson.status = ERROR;
                responseJson.msg = error.message;
                store.dispatch(enqueueSnackbar({
                    message: responseJson.msg,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: ERROR
                    },
                }));
                store.dispatch(setLoader({
                    show: false
                }));
                response(responseJson);
            })
    }
};




const jsonToQueryString = (json) => {
    if (!json) {
        return undefined;
    }
    return '?' + Object.keys(json).map((key) => {
        return `${key}=${json[key]}`
    }).join('&');
};

const queryStringToJson = (string) => {
    var pairs = string.slice(1).split('&');

    var result = {};
    pairs.forEach(function (pair) {
        pair = pair.split('=');
        result[pair[0]] = decodeURIComponent(pair[1] || '');
    });

    return JSON.parse(JSON.stringify(result));
};

export {
    methods,
    actions,
    ERROR,
    SUCCESS,
    DEFAULT_MESSAGE,
    jsonToQueryString,
    queryStringToJson,
};

export default requestApi;
