import React, {Component, Fragment} from "react";
import {connect} from "react-redux";
import {setDialog} from "../../lib/Store/actions/components";
import {scrollToTop} from "../../lib/functions";


class Index extends Component {


    constructor(props) {
        super(props);
        this.state={

        }
    }

    componentDidMount() {
        document.title = "DHRU Graphic Design Agreement";
        scrollToTop();
    }

    render() {

        return(
            <Fragment>


                <div className="container mt-5">
                    <div className={'my-5'}>
                        <div className="blog">
                            <div className="entry"> THIS AGREEMENT is made this [DAY] day of [MONTH] , [YEAR] by and
                                between Qualiteam Corp ("Designers") and [COMPANY_NAME] ("Customer").
                                <h3>Terms &amp; Agreements</h3>
                                <strong>In consideration of the mutual covenants set forth in this Agreement, Customer
                                    and Designers hereby agree as follows:</strong>
                                <h3>1. Description of the Project ("Specifications").</h3>
                                Designers agree to develop the Project according to the Specifications described in the
                                specification file #[SPECIFICATION_NUMBER] also accessible via the following URL
                                [SPECIFICATION_URL] and incorporated by reference in this document. <br/>
                                    <br/>
                                        <h3>2. Additional Editing &amp; Changes.</h3>
                                        Any requested changes to the Specifications shall constitute additional editing
                                        and incur additional charges or fees. All additional changes must be submitted
                                        and approved by both parties in writing by approved Contract Change form. <br/>
                                        <br/>
                                            <h3>3. Delivery of Project.</h3>
                                            Designers will use all reasonable efforts in the development of the Project
                                            and endeavor to complete and deliver to Customer all files, media and
                                            materials related to the Project no later than in [PROJECT_FINISH_DAYS] days
                                            after the effective date of this agreement by an approved party provided
                                            that payment and all requested instructions and material have been received
                                            by Designers from Customer. Any delay in the completion of the Project due
                                            to actions or negligence of Customer, transportation delays, illness, or
                                            circumstances outside the control of Designers may alter the delivery date.
                                            Designers will make reasonable effort to notify Customer of any delays to
                                            the estimated delivery date as soon as possible. <br/>
                                            <br/>
                                                <strong>Reproduction of Project.</strong>
                                                <ul>
                                                    <li>Upon successful completion of all compensation terms and
                                                        outstanding balances owed to Designers.
                                                    </li>
                                                    <li>Customer is granted full and unlimited reproduction rights to
                                                        the Project.
                                                    </li>
                                                    <li>Designers retain the right to reproduce the Project in any form
                                                        for marketing, future publications, competitions or other
                                                        promotional uses. Designers shall at no time reproduce the
                                                        Project for use in commercial means or for-profit use.
                                                    </li>
                                                    <li>Customer may not reproduce or otherwise use design mock-ups,
                                                        drafts, sketches etc. created by designer during work on the
                                                        Project but not included into the final version of the Project.
                                                        Such artwork belongs solely to Designer who may use it at his
                                                        own discretion.
                                                    </li>
                                                </ul>
                                                <br/>
                                                    <br/>
                                                        <h3>4. Ownership of Artwork and Source Files.</h3>
                                                        Except for Customer's Proprietary Material (defined below)
                                                        contained in the Project, Designers shall hold all right, title,
                                                        and interest in all original artwork, whether in draft, mock-up,
                                                        concept or final development for the Project. Specifically, but
                                                        without limitation, Designers shall hold all right, title, and
                                                        interest in and to (1) all text, graphics or digital components
                                                        of the Project (the "Content"), (2) all layouts, logos,
                                                        structures or arrangements or other components of any materials
                                                        presented to Customer that comprises the Project, (3) all
                                                        literal and nonliteral expressions of ideas that operate, cause,
                                                        create, direct, manipulate, access, or otherwise affect the
                                                        Content, and (4) all copyrights, patents, trade secrets, and
                                                        other intellectual or industrial property rights in the Project
                                                        or any component or characteristic thereof. Customer shall not
                                                        do anything that may infringe upon or in any way undermine
                                                        Designers' right, title, and interest in the Project, as
                                                        described in this Paragraph 4. Notwithstanding the above,
                                                        Customer shall retain and, Designers shall have no proprietary
                                                        rights whatsoever in all of Customer's intellectual property
                                                        rights in any and all text, images or other components and/or
                                                        materials owned by Customer, or which Customer has the legal
                                                        right to use, that are delivered to Designers, including but not
                                                        limited to software, related documentation, Customer marketing
                                                        material, logos, and tag lines ("Customer's Proprietary
                                                        Material"). Designers agree that they shall not use Customer's
                                                        Proprietary Material for any other purpose than those expressly
                                                        set forth in this Agreement. <br/>
                                                        <br/>
                                                            <h3>5. Compensation.</h3>
                                                            In return for the Projects that are completed and delivered
                                                            under this Agreement, Customer shall compensate Designers,
                                                            pursuant to the terms of Exhibit A attached hereto. In the
                                                            event Customer fails to make any of the payments referenced
                                                            in Exhibit A by the deadline set forth in Exhibit A,
                                                            Designers have the right, but are not obligated, to pursue
                                                            any or all of the following remedies: (1) terminate the
                                                            Agreement, (2) withhold all files, artwork, source,
                                                            commitments or any other service to be performed by
                                                            Designers for Customer, (3) bring legal action. Customer is
                                                            fully responsible for all material costs as outlined in
                                                            Exhibit A, and accepts responsibility for all additional
                                                            material costs that Designers may incur in the development
                                                            of this Project. <br/>
                                                            <br/>
                                                                <h3>6. Confidentiality.</h3>
                                                                Customer and Designers acknowledge and agree that the
                                                                Specifications and all other documents and information
                                                                related to the development of the Project, excluding
                                                                however, Customer's Proprietary Material, (the
                                                                "Confidential Information") will constitute valuable
                                                                trade secrets of Designers. Customer shall keep the
                                                                Confidential Information in confidence and shall not, at
                                                                any time during or after the term of this Agreement,
                                                                without Designer's prior written consent, disclose or
                                                                otherwise make available to anyone, either directly or
                                                                indirectly, all or any part of the Confidential
                                                                Information. <br/>
                                                                <br/>
                                                                    <h3>7. Limited Warranty and Limitation on
                                                                        Damages.</h3>
                                                                    Designers warrant the Project will conform to the
                                                                    Specifications. If the Project does not conform to
                                                                    the Specifications, Designers shall be responsible
                                                                    for the timely correction of the Project, at
                                                                    Designers' sole expense and without charge to
                                                                    Customer, to bring the Project into conformance with
                                                                    the Specifications. This warranty shall be the
                                                                    exclusive warranty available to Customer. Customer
                                                                    waives any other warranty, express or implied.
                                                                    Customer acknowledges that Designers are not
                                                                    responsible for the results obtained by Customer's
                                                                    use of any part of the Project. Customer
                                                                    acknowledges that Designers are not responsible for
                                                                    fixing any problems, errors or omissions on the
                                                                    Project, once mass produced or after Customer has
                                                                    tested, proofed and approved the Project. Except as
                                                                    otherwise expressly stated herein, Customer waives
                                                                    any claim for damages, direct or indirect, and
                                                                    agrees that its sole and exclusive remedy for
                                                                    damages (either in contract or tort) is the return
                                                                    of the consideration paid to Designers as set forth
                                                                    in Exhibit A attached hereto. This limited warranty
                                                                    shall become void and expire 60 days after after the
                                                                    delivery of the Project by Designers. <br/>
                                                                    <br/>
                                                                        <h3>8. Independent Contractor.</h3>
                                                                        Designers are retained as independent
                                                                        contractors. Designers will be fully responsible
                                                                        for payment of their own income taxes on all
                                                                        compensation earned under this Agreement.
                                                                        Customer will not withhold or pay any income
                                                                        tax, social security tax, or any other payroll
                                                                        taxes on Designers' behalf. Designers understand
                                                                        that they will not be entitled to any fringe
                                                                        benefits that Customer provides for its
                                                                        employees generally or to any statutory
                                                                        employment benefits, including without
                                                                        limitation worker's compensation or unemployment
                                                                        insurance. <br/>
                                                                        <br/>
                                                                            <h3>9. Availability of Materials, Logos,
                                                                                Graphics and other Collateral.</h3>
                                                                            Customer agrees to make available to
                                                                            Designers, for Designers' use in performing
                                                                            the services required by this Agreement,
                                                                            such graphical elements and materials as
                                                                            Customer and Designers may agree in writing
                                                                            for such purpose. Failure to provide
                                                                            Designers with Materials in the requested
                                                                            formats may result in additional delays or
                                                                            fees in addition to those stated in Exhibit
                                                                            A. <br/>
                                                                            <br/>
                                                                                <h3>10. General Provisions.</h3>
                                                                                <ul>
                                                                                    <li>Entire Agreement.</li>
                                                                                    This Agreement contains the entire
                                                                                    Agreement between the parties
                                                                                    relating to the subject matter
                                                                                    hereof and supersedes any and all
                                                                                    prior agreements or understandings,
                                                                                    written or oral, between the parties
                                                                                    related to the subject matter
                                                                                    hereof. No modification of this
                                                                                    Agreement shall be valid unless made
                                                                                    in writing and signed by all of the
                                                                                    parties hereto.
                                                                                    <li>Governing Law.</li>
                                                                                    This Agreement is governed by
                                                                                    English law. Any dispute arising out
                                                                                    or in connection with this agreement
                                                                                    including any question regarding its
                                                                                    existence, validity or termination,
                                                                                    shall be referred to and finally
                                                                                    resolved by arbitration under the
                                                                                    Rules of the London Court of
                                                                                    International Arbitration ("LCIA")
                                                                                    which Rules are deemed to be
                                                                                    incorporated by reference into this
                                                                                    clause. The place of arbitration
                                                                                    shall be London, England.
                                                                                    <li>Binding Effect.</li>
                                                                                    This Agreement shall be binding upon
                                                                                    and endure to the benefit of
                                                                                    Customer and Designers and their
                                                                                    respective successors and assigns,
                                                                                    provided that Designers shall not
                                                                                    assign any of their obligations
                                                                                    under this Agreement without
                                                                                    Customer's prior written consent.
                                                                                    <li>Waiver.</li>
                                                                                    The waiver by either party of any
                                                                                    breach or failure to enforce any of
                                                                                    the terms and conditions of this
                                                                                    Agreement at any time shall not in
                                                                                    any way affect, limit, or waive such
                                                                                    party's right thereafter to enforce
                                                                                    and compel strict compliance with
                                                                                    every term and condition of this
                                                                                    Agreement.
                                                                                    <li>Good Faith.</li>
                                                                                    Each party represents and warrants
                                                                                    to the other that such party has
                                                                                    acted in good faith, and agrees to
                                                                                    continue to so act, in the
                                                                                    negotiation, execution, delivery,
                                                                                    performance, and any termination of
                                                                                    this Agreement.
                                                                                    <li>No Right to Assign.</li>
                                                                                    Customer has no right to assign,
                                                                                    sell, modify or otherwise alter the
                                                                                    Project, except upon the express
                                                                                    written advance approval of
                                                                                    Designers, which consent can be
                                                                                    withheld for any reason.
                                                                                    <li>Right to Remove Project.</li>
                                                                                    In the event Customer fails to make
                                                                                    any of the payments set forth on
                                                                                    Exhibit A within the time prescribed
                                                                                    in Exhibit A, Designers have the
                                                                                    right to immediately cease all work
                                                                                    on the Project until payment in full
                                                                                    is paid.
                                                                                    <li>Indemnification.</li>
                                                                                    Customer warrants that everything it
                                                                                    gives Designers to include in the
                                                                                    Project is legally owned or licensed
                                                                                    to Customer. Customer agrees to
                                                                                    indemnify and hold Designers
                                                                                    harmless from any and all claims
                                                                                    brought by any third-party relating
                                                                                    to Customer's Proprietary Material
                                                                                    provided by Customer to Designers
                                                                                    including any and all demands,
                                                                                    liabilities, losses, reasonable
                                                                                    associated costs and claims
                                                                                    including reasonable attorney's fees
                                                                                    arising out of injury caused by
                                                                                    Customer's Proprietary Material
                                                                                    supplied by Customer to Designers,
                                                                                    copyright infringement, and
                                                                                    defective products sold as a result
                                                                                    of Customer's distribution of the
                                                                                    Project.
                                                                                    <li>Use of Project for Promotional
                                                                                        Purposes.</li>
                                                                                    Customer grants Designers the right
                                                                                    to use the Project for promotional
                                                                                    purposes and/or to cross-link it
                                                                                    with other marketing venues
                                                                                    developed by Designers.
                                                                                    <li> Right to Style or to Make
                                                                                        Derivative Works. </li>
                                                                                    Subject to Section 4 above,
                                                                                    Designers have the exclusive rights
                                                                                    in making any derivative similar
                                                                                    works of the Project and any
                                                                                    similarities between Customer's
                                                                                    Project and future projects
                                                                                    constitutes Designer's methods and
                                                                                    style and shall remain the right of
                                                                                    Designers.
                                                                                    <li>Attorney's Fees.</li>
                                                                                    In the event any party to this
                                                                                    Agreement employs an attorney to
                                                                                    enforce any of the terms of the
                                                                                    Agreement, the prevailing party
                                                                                    shall be entitled to recover its
                                                                                    actual reasonable attorney's fees
                                                                                    and reasonable associated costs,
                                                                                    including expert witness fees.
                                                                                    <li>Trademarks, Logos and other
                                                                                        Intellectual Property
                                                                                        Issues.</li>
                                                                                    Customer is responsible for any
                                                                                    Copyright or Trademark issues
                                                                                    related to the creation and use of
                                                                                    Project files by Customer. Customer
                                                                                    shall be solely responsible for any
                                                                                    Trademark or Copyright searches
                                                                                    pertaining to the Project unless
                                                                                    otherwise contracted for in the
                                                                                    Specifications. Designers will not
                                                                                    knowingly copy other rightfully
                                                                                    trademarked or copyrighted material.
                                                                                    <li>Non-solicitation.</li>
                                                                                </ul>
                                                                                <br/>
                                                                                    <br/>
                                                                                        <br/>
                                                                                            <br/>
                                                                                                Both the Customer and
                                                                                                the Designers agree that
                                                                                                while this Agreement is
                                                                                                in force and for a
                                                                                                period of twelve (12)
                                                                                                months thereafter, they
                                                                                                shall not directly or
                                                                                                indirectly solicit or
                                                                                                offer employment to any
                                                                                                of the other's officers,
                                                                                                employees, third party
                                                                                                contractors and
                                                                                                associates who have been
                                                                                                involved in or
                                                                                                associated with this
                                                                                                Agreement without the
                                                                                                other's prior written
                                                                                                consent. <br/>
                                                                                                <br/>
                                                                                                    <h3>Exhibit A</h3>
                                                                                                    <strong>Payment and
                                                                                                        Working
                                                                                                        Schedule</strong>
                                                                                                    <ul>
                                                                                                        <li> Customer
                                                                                                            agrees to
                                                                                                            pay a Fee of
                                                                                                            [SUM_FOR_ITEM]
                                                                                                            for the
                                                                                                            implementation
                                                                                                            of the
                                                                                                            Project by
                                                                                                            Designer.
                                                                                                        </li>
                                                                                                        <li> Designer
                                                                                                            will start
                                                                                                            the work on
                                                                                                            the Project
                                                                                                            only after
                                                                                                            the full
                                                                                                            payment of
                                                                                                            the due Fee.
                                                                                                            The exact
                                                                                                            date when
                                                                                                            Designer
                                                                                                            will
                                                                                                            actually
                                                                                                            start to
                                                                                                            work on the
                                                                                                            Project
                                                                                                            (Start Date)
                                                                                                            will not
                                                                                                            exceed 10
                                                                                                            working days
                                                                                                            after the
                                                                                                            payment of
                                                                                                            the Fee.
                                                                                                            Customer
                                                                                                            will be
                                                                                                            informed
                                                                                                            about the
                                                                                                            Start Date
                                                                                                            of the
                                                                                                            Project
                                                                                                            after the
                                                                                                            payment.
                                                                                                        </li>
                                                                                                        <li> In
                                                                                                            [DAYS_FOR_SKETCHES]
                                                                                                            days after
                                                                                                            the Start
                                                                                                            Date
                                                                                                            Designer
                                                                                                            will provide
                                                                                                            the Customer
                                                                                                            with up to
                                                                                                            [NUMBER_OF_SKETCHS]
                                                                                                            variants of
                                                                                                            design to
                                                                                                            choose from.
                                                                                                        </li>
                                                                                                        <li> Customer
                                                                                                            should
                                                                                                            choose and
                                                                                                            approve one
                                                                                                            of the
                                                                                                            variants.
                                                                                                            Further work
                                                                                                            on the
                                                                                                            Project will
                                                                                                            be based on
                                                                                                            the chosen
                                                                                                            variant.
                                                                                                        </li>
                                                                                                        <li> Designer
                                                                                                            continues to
                                                                                                            work on the
                                                                                                            Project
                                                                                                            regularly
                                                                                                            sending the
                                                                                                            results to
                                                                                                            the Customer
                                                                                                            for review
                                                                                                            and approval
                                                                                                            and make
                                                                                                            necessary
                                                                                                            alterations
                                                                                                            until the
                                                                                                            Customer is
                                                                                                            satisfied
                                                                                                            with the
                                                                                                            result. The
                                                                                                            total number
                                                                                                            of reviews
                                                                                                            should not
                                                                                                            exceed 10
                                                                                                            iterations.
                                                                                                        </li>
                                                                                                        <li> Designer
                                                                                                            delivers the
                                                                                                            resulting
                                                                                                            Project to
                                                                                                            the
                                                                                                            Customer.
                                                                                                        </li>
                                                                                                        <li> Further
                                                                                                            work on the
                                                                                                            improvement
                                                                                                            of the
                                                                                                            Project
                                                                                                            after the
                                                                                                            allowed
                                                                                                            number of
                                                                                                            reviews or
                                                                                                            working days
                                                                                                            is exceeded
                                                                                                            is possible
                                                                                                            only after
                                                                                                            the payment
                                                                                                            of
                                                                                                            additional
                                                                                                            fee quoted
                                                                                                            by the sales
                                                                                                            representative.
                                                                                                        </li>
                                                                                                        <li> The Project
                                                                                                            is delivered
                                                                                                            in
                                                                                                            electronic
                                                                                                            form via
                                                                                                            HTTP or FTP
                                                                                                            download by
                                                                                                            the Customer
                                                                                                            from the
                                                                                                            Designer's
                                                                                                            web server.
                                                                                                            The Designer
                                                                                                            is not
                                                                                                            responsible
                                                                                                            for safe
                                                                                                            storing the
                                                                                                            Project
                                                                                                            files after
                                                                                                            the end of
                                                                                                            limited
                                                                                                            warranty
                                                                                                            period a
                                                                                                            specified in
                                                                                                            clause 7 of
                                                                                                            the present
                                                                                                            Agreement.
                                                                                                            The
                                                                                                            following
                                                                                                            files are
                                                                                                            are
                                                                                                            delivered as
                                                                                                            a part of
                                                                                                            the project:
                                                                                                            <ul style={{listStyle: 'circle'}}>
                                                                                                                <li>Image
                                                                                                                    files
                                                                                                                    that
                                                                                                                    constitute
                                                                                                                    the
                                                                                                                    design
                                                                                                                    elaborated
                                                                                                                    in a
                                                                                                                    scope
                                                                                                                    of
                                                                                                                    the
                                                                                                                    Project
                                                                                                                    as
                                                                                                                    described
                                                                                                                    in
                                                                                                                    the
                                                                                                                    Specifications
                                                                                                                </li>
                                                                                                                <li>Source
                                                                                                                    files
                                                                                                                    for
                                                                                                                    the
                                                                                                                    above
                                                                                                                    mentioned
                                                                                                                    image
                                                                                                                    files
                                                                                                                </li>
                                                                                                            </ul>
                                                                                                        </li>
                                                                                                    </ul>
                                                                                                    <br/>
                                                                                                        <br/>
                                                                                                            <br/>
                                                                                                                <br/>
                                                                                                                    I
                                                                                                                    have
                                                                                                                    read
                                                                                                                    and
                                                                                                                    understood
                                                                                                                    terms
                                                                                                                    and
                                                                                                                    conditions
                                                                                                                    of
                                                                                                                    the
                                                                                                                    Graphic
                                                                                                                    Design
                                                                                                                    Agreement
                                                                                                                    and
                                                                                                                    hereby
                                                                                                                    agree
                                                                                                                    to
                                                                                                                    abide
                                                                                                                    by
                                                                                                                    them.
                                                                                                                    I
                                                                                                                    also
                                                                                                                    have
                                                                                                                    thoroughly
                                                                                                                    read
                                                                                                                    and
                                                                                                                    accepted
                                                                                                                    Specifications
                                                                                                                    for
                                                                                                                    the
                                                                                                                    Project
                                                                                                                    located
                                                                                                                    in
                                                                                                                    the
                                                                                                                    specification
                                                                                                                    file
                                                                                                                    #[SPECIFICATION_NUMBER]. <br/>
                                                                                                                    <br/>
                                                                                                                        <br/>
                                                                                                                            <br/>
                                                                                                                                Signed
                                                                                                                                by
                                                                                                                                [COMPANY_NAME].

                            </div>
                        </div>
                    </div>
                </div>

            </Fragment>
        );
    }

}

const mapStateToProps = (state) => {
    return {

    }
};

const mapDispatchToProps = (dispatch) => ({
    setDialog: (dialog) => dispatch(setDialog(dialog)),
});


export default connect(mapStateToProps, mapDispatchToProps)(Index);
