import React, {Component,Fragment} from "react";
import {NavLink  as Link} from 'react-router-dom';
import { NavLink,NavItem} from 'reactstrap';
import {connect} from "react-redux";
import LogoDark from "../../theme/images/logos/logo.svg";
import {setPage} from "../../lib/Store/actions/appApiData";
import {setIndex} from "../../lib/functions";

export class Header extends Component {

    constructor(props) {
        super(props);
        this.state={
            page:'index',
            breadcrumb:''
        }
    }

    componentDidMount() {
        require('../../theme/custom');
        const {pathname} = this.props.location;
        const {setPage} = this.props;
         let path = pathname.split("/")[1];
         //window.Intercom('update');
         if(!path || path === 'products'){
             let breadcrumb = pathname.split("/")[2];
             setPage({page:'index',breadcrumb:breadcrumb});
         }
         else{
             setPage({page:path,breadcrumb:''});
         }
    }



    render() {

        const {page,breadcrumb} = this.props;

        return (
            <Fragment>
                <header id="top-bar" className={`top-bar top-bar--light   ${page === 'index'?'index':'other'}`} data-nav-anchor="true">
                    <div className="top-bar__inner">
                        <div className="container">
                            <div className="row align-items-center no-gutters">
                                <div>
                                    <NavLink className="top-bar__logo site-logo nav-link" onClick={()=>setIndex('index')} tag={Link} exact={'true'} to={"/"} >
                                        <img className="img-fluid  rounded" src={LogoDark}  alt="Logo" style={{width:'120px'}}/>
                                        {/*<img className="img-fluid logo-light" src={LogoLight}  alt="Logo"/>*/}
                                    </NavLink>
                                    {breadcrumb ? <div  style={{marginLeft:'0px'}} className={'d-none d-md-block'}>
                                        <NavLink className="breadcrumb-item text-capitalize" tag={Link} exact={'true'} to={"/"}>Home</NavLink>
                                        <span className="breadcrumb-item text-capitalize" >{breadcrumb}</span>
                                    </div> : <div  style={{marginLeft:'10px'}} className={'d-none d-md-block'}>
                                        <NavLink className="breadcrumb-item text-capitalize" tag={Link} exact={'true'} to={"/"}>&nbsp;</NavLink>
                                    </div>}
                                </div>
                                <a id="top-bar__navigation-toggler" className="top-bar__navigation-toggler" href={'/'} ><span></span> </a>
                                <div className="top-bar__collapse">
                                    <nav id="top-bar__navigation" className="top-bar__navigation ml-auto" role="navigation">
                                        <ul>
                                            <NavItem onClick={()=>setIndex('index')} ><NavLink tag={Link}  exact={'true'}   to={"/"}>HOME</NavLink></NavItem>
                                            <NavItem className="has-submenu">
                                                <a className="nav-link"  href="/">PRODUCTS</a>
                                                <ul className="submenu">
                                                    <li onClick={()=>setIndex('index','Fusion')} ><NavLink tag={Link}   to={"/products/dhru-fusion"}>Dhru Fusion</NavLink></li>
                                                    <li onClick={()=>setIndex('index','Ecommerce')} ><NavLink tag={Link}    to={"/products/dhru-ecommerce"}>Dhru Ecommerce</NavLink></li>
                                                    <li onClick={()=>setIndex('index','Wallet')} ><NavLink tag={Link}    to={"/products/dhru-wallet"}>Dhru Wallet</NavLink></li>
                                                    <li onClick={()=>setIndex('index','ERP')} ><NavLink tag={Link}    to={"/products/dhru-erp/restaurant"}>Dhru ERP for Restaurant</NavLink></li>
                                                    <li onClick={()=>setIndex('index','ERP')} ><NavLink tag={Link}    to={"/products/dhru-erp/retail"}>Dhru ERP for Retail</NavLink></li>
                                                    <li onClick={()=>setIndex('index','ERP')} ><NavLink tag={Link}    to={"/products/dhru-erp/servicecenter"}>Dhru ERP for Service center</NavLink></li>
                                                </ul>
                                            </NavItem>
                                            <NavItem onClick={()=>setIndex('service')} ><NavLink tag={Link}    to={"/services"}>SERVICES</NavLink></NavItem>
                                            <NavItem onClick={()=>setIndex('contactus')} ><NavLink tag={Link}    to={"/contactus"}>CONTACT US</NavLink></NavItem>
                                            <NavItem><a  target="_blank" rel="noopener noreferrer" href={"https://account.dhru.com"}>LOGIN</a></NavItem>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>

                {page !== 'index' && <main>
                    <div className="start-screen start-screen--full-height start-screen--style-6"
                         ng-hide="$state.current.data.heading=='0'">
                        <div className="position-relative">
                            <div className="start-screen__content-container">
                                <div className="start-screen__content__item align-items-center heading-13vh">
                                    <div className="container">

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>}


            </Fragment>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        page:state.appApiData.page,
        breadcrumb: state.appApiData.breadcrumb
    }
};
const mapDispatchToProps = (dispatch) => ({
    setPage: (page) => dispatch(setPage(page)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Header);
