import React, {Component, Fragment} from "react";
import {connect} from "react-redux";
import {NavLink} from "reactstrap";
import {Link} from "react-router-dom";
import {setPage} from "../../lib/Store/actions/appApiData";
import {scrollToTop} from "../../lib/functions";

class Index extends Component {


    constructor(props) {
        super(props);
        this.state={

        }
    }

    componentDidMount() {
        scrollToTop();
    }

    render() {
        const {setPage} = this.props;
        return(
            <Fragment>

                <div className="container mt-5">
                    <p> You are agreeing to comply with and be bound by the following Terms and Conditions of use,
                        which, together with our Privacy Policy, govern Dhru Cloud Pvt. Ltd.’s relationship with you in
                        relation to this website.
                        <br/>
                        The term Dhru Cloud Pvt. Ltd. (referred to as DHRU) refers to the owner of the website, whose
                        registered office is Dhru Base, 80 Foot Road, Anand, Pin-388001, India. The term you refers to
                        the user or viewer of our website.
                        <br/>
                        <NavLink tag={Link} onClick={() => setPage({page: 'customdevelopmentagreement'})}
                                 to={"/customdevelopmentagreement"}>Custom Development Agreement</NavLink> <br/>
                        <NavLink tag={Link} onClick={() => setPage({page: 'graphicdesignagreement'})}
                                 to={'/graphicdesignagreement'}>Graphic Design Agreement</NavLink>
                    </p>
                    <div className="clearer"></div>
                    <br/>
<h2>Website Terms and Conditions of Use</h2>
<div className="fbox">
    <ul>
        <li>The content of the pages of this website is for your general information and use
            only. It is subject to change without notice.<br/><br/></li>
        <li>Neither we nor any third parties provide any warranty or guarantee as to the
            accuracy, timeliness, performance, completeness or suitability of the information
            and materials found or offered on this website for any particular purpose. You
            acknowledge that such information and materials may contain inaccuracies or errors
            and we expressly exclude liability for any such inaccuracies or errors to the
            fullest extent permitted by law.<br/><br/></li>
        <li>Your use of any information or materials on this website is entirely at your own
            risk, for which we shall not be liable. It shall be your own responsibility to
            ensure that any products, services or information available through this website
            meet your specific requirements.<br/><br/></li>
        <li>This website contains material which is owned by or licensed to us. This material
            includes, but is not limited to, the design, layout, look, appearance and graphics.
            Reproduction is prohibited other than in accordance with the copyright notice, which
            forms part of these terms and conditions.<br/><br/></li>
        <li>All trademarks reproduced in this website, which are not the property of, or
            licensed to the operator, are acknowledged on the website.<br/><br/></li>
        <li>Unauthorised use of this website may give rise to a claim for damages and/or be a
            criminal offence.<br/><br/></li>
        <li>From time to time this website may also include links to other websites. These links
            are provided for your convenience to provide further information. They do not
            signify that we endorse the website(s). We have no responsibility for the content of
            the linked website(s).<br/><br/></li>
        <li>You may not create a link to this website from another website or document without
            Dhru's prior written consent. <br/></li>
    </ul>
</div>


<h2>End User License Agreement</h2>
<p>The EULA Agreement is included in the Product download package. By installing, copying, or
    otherwise using our products, you agree to be bound by the terms of the EULA.</p>

<h3>Delivery Policy</h3>
    All licenses are issued instantly upon receipt of payment. Should your payment turn out to be
    fraudulent, the license will be suspended and you will be expected to repay the amount and any
    fees incurred. Our professional services usually take between 24 &amp; 48 hours for completion
    but at peak times such as new releases, they may take slightly longer.

<h3>License Transfer Policy</h3>
    We allow owned licenses bought directly from us to be sold and transferred between people.
    Licenses obtained through a reseller cannot be resold. Before any sale or transfer, the seller
    should contact us to notify us of the sale. Once a buyer has been found, both the buyer and
    seller should contact us to arrange the transfer and payment of the $50 transfer fee. A license
    can only be resold once. Addons may be transferred between licenses within your own client
    account but not resold or transferred to other clients.

<h3>Domain Change Policy</h3>
    Licenses are associated with a single domain name, and any request to change the domain will incur a $200
    administrative fee. Domain name changes are limited to one occurrence per calendar year.

<h3>Refunds Policy</h3>
    Refunds are only issued for software failure. Refunds are not issued for server failure/issues,
    lack of features or if your server does not meet the Software Requirements. Refunds are
    determined on individual circumstances and only issued once our technical staff determine that
    DHRU has a fault causing it to not run on your server. Installation charges are not
    refundable under any circumstances. Refunds are not available after 1 month from the purchase
    date.

<h3>Personal Information</h3>
    We collect your personal information when you make an order on our website. This information is
    kept in our database for our own use but will never be given or sold to any other parties.

<h3>Mailing List</h3>
    All our customers are automatically subscribed to our mailing announcements. Announcements are
    sent out from time to time regarding new releases, new products or services we have available,
    upcoming releases and/or features .

<h3>External Links</h3>
    Our site contains various links to external companies. We are not responsible for the content or
    privacy practices of these websites and would recommend you exercise caution when visiting such
    links. Particularly those found on the forums where people can post links freely.


<h3>Photo / Image / Graphics Policy</h3>
<p>You cannot sell or distribute the Content (either in digital or physical form) on a Standalone basis. This includes
    selling or distributing Content on a Standalone basis as an image, audio, video, NFT or other digital file
    (including through a stock media platform), as well as a print, wallpaper, poster or on merchandise or on other
    physical products.</p>
<p> When we refer to "Standalone" we mean where no creative effort has been applied to the Content and it remains in
    substantially the same form as it exists on the Services. To help illustrate this, here are some examples:</p>

<ul style={{listStyle: 'circle', marginLeft: 20}}>
    <li>Using the Content in its original form or solely using a filter, changing colors, resizing or cropping the
        Content remains Standalone use.
    </li>
    <li>Using the Content with a combination of images, videos, audio files, other media, text, illustrations,
        background features and editing techniques is not Standalone use, so long as the combined effect is to make a
        "new" creative work.
    </li>
</ul>


<br/><br/>
<h2>Copyright notice</h2>
<p>This website and its content is copyright of DHRU.com 2007-10 / Dhru.com 2001/12. All
    rights reserved.</p>

    Any redistribution or reproduction of part or all of the contents in any form is prohibited
    other than the following:

<div className="fbox">
    <ul>
        <li>you may print or download to a local hard disk extracts for your personal and
            non-commercial use only.
        </li>
        <li>you may copy the content to individual third parties for their personal use, but
            only if you acknowledge the website as the source of the material.
        </li>
    </ul>
</div>
<p>
    You may not, except with our express written permission, distribute or commercially
    exploit the content. Nor may you transmit it or store it in any other website or other
    form of electronic retrieval system.
</p>


<br/><br/>
<h2>Collection and Use of Personal Information </h2>
<p>
    We collect the following personal information from you: <br/>
    &nbsp;&nbsp;&nbsp;&nbsp;Contact Information such as name, email address, mailing
    address, phone number<br/>
    &nbsp;&nbsp;&nbsp;&nbsp;Billing Information such as credit card number, and billing
    address <br/>
    &nbsp;&nbsp;&nbsp;&nbsp;Financial Information such as bank or brokerage account
    numbers, types of investments<br/>

    As is true of most Web sites, we automatically gather information about your
    computer such as your IP address, browser type, referring/exit pages, and operating
    system.
    We use this information to: <br/>
    &nbsp;&nbsp;&nbsp;&nbsp;Fulfill your order<br/>
    &nbsp;&nbsp;&nbsp;&nbsp;Send you an order confirmation<br/>
    &nbsp;&nbsp;&nbsp;&nbsp;Send you requested product or service information<br/>
    &nbsp;&nbsp;&nbsp;&nbsp;Send product updates or warranty information<br/>
    &nbsp;&nbsp;&nbsp;&nbsp;Respond to customer service requests<br/>
    &nbsp;&nbsp;&nbsp;&nbsp;Administer your account<br/>
    &nbsp;&nbsp;&nbsp;&nbsp;Send you a newsletter<br/>
    &nbsp;&nbsp;&nbsp;&nbsp;Conduct research and analysis<br/>
    &nbsp;&nbsp;&nbsp;&nbsp;Send you a catalog<br/>
    Choice/Opt-Out<br/>
    You may choose to stop receiving our newsletter or marketing emails by following the
    unsubscribe instructions included in these emails or you can contact us at
    info@dhru.com.
    <br/>
</p>


<br/><br/>
<h2>Disclosure to Third Parties </h2>
<p>We will share your information with third parties only in the ways that are
    described in this privacy statement.</p><p>We may provide your personal
    information to companies that provide services to help us with our business
    activities such as shipping your order or offering customer service. These companies
    are authorized to use your personal information <em>only as necessary to
        provide</em> these services to us.</p><p>We may disclose your personal
    information</p>
<ul>
    <li>as required by law, such as to comply with a subpoena, or similar legal
        process
    </li>
    <li>when we believe in good faith that disclosure is necessary to protect our
        rights, protect your safety or the safety of others, investigate fraud, or
        respond to a government request,
    </li>
    <li>if DHRU is involved in a merger, acquisition, or sale of all or a portion of
        its assets, you will be notified via email and/or a prominent notice on our
        Web site of any change in ownership or uses of your personal information, as
        well as any choices you may have regarding your personal information,
    </li>
    <li>to any other third party with your prior consent to do so.</li>
</ul>
<em>PayPal</em><br/><br/><p>If you choose to use PayPal to finalize and pay for your
    order, you will provide your credit card number, directly to PayPal. PayPal's
    privacy policy will apply to the information you provide on the PayPal Web site.</p>
<em>Third Party E-Commerce Solutions Provider</em><br/><br/><p>Our shopping cart is
    hosted by our e-commerce solutions provider. They host our ordering system,
    and collect your billing information directly from you for the purpose of
    processing your order. This company does not use this information for any other
    purpose. </p>


<br/><br/>
<h2>Tracking Technologies </h2>

<p>We or a third party contracted by us may use technologies such as
    cookies, Web beacons,
    or scripts to gather information about how you and others interact with
    our Web site. For example,
    we will know how many users access a specific areas or features within
    our site and which links or ads
    they clicked on. We use this aggregated information to understand and
    optimize how our site is used,
    improve our marketing efforts, and provide content and features that are
    of interest to you.</p>


<br/><br/>


<h2>Data Safety Policy</h2>

<ol style={{listStyle: 'lower-alpha', marginLeft: 18}}>
    <li> processed lawfully, fairly and in a transparent manner in relation to individuals;</li>
    <li> collected for specified, explicit and legitimate purposes and not further processed in
        a manner that is incompatible with those purposes; further processing for archiving
        purposes in the public interest, scientific or historical research purposes or
        statistical purposes shall not be considered to be incompatible with the initial
        purposes;
    </li>
    <li> adequate, relevant and limited to what is necessary in relation to the purposes for
        which they are processed;
    </li>
    <li> accurate and, where necessary, kept up to date; every reasonable step must be
        taken to ensure that personal data that are inaccurate, having regard to the
        purposes for which they are processed, are erased or rectified without delay;
    </li>
    <li> kept in a form which permits identification of data subjects for no longer than is
        necessary for the purposes for which the personal data are processed; personal
        data may be stored for longer periods insofar as the personal data will be processed
        solely for archiving purposes in the public interest, scientific or historical research
        purposes or statistical purposes subject to implementation of the appropriate
        technical and organisational measures required by the DPA in order to safeguard
        the rights and freedoms of individuals; and
    </li>
    <li> processed in a manner that ensures appropriate security of the personal data,
        including protection against unauthorised or unlawful processing and against
        accidental loss, destruction or damage, using appropriate technical or
        organisational measures.
    </li>

</ol>


<br/><br/>
<h2>Security</h2>

<p>The security of your personal information is important to us. We
    follow generally accepted industry
    standards to protect the personal information submitted to us, both
    during transmission and once we
    receive it. No method of transmission over the Internet, or method
    of electronic storage,
    is 100% secure. Therefore, we cannot guarantee its absolute
    security.</p><p>If you have any questions about
    security on our Web site, you can contact us at <a
        href="mailto:info@dhru.com">info@dhru.com</a></p><p>We provide two
    means for you to make a purchase on our site. You can either use our
    order form or a third
    party checkout option. If you use our shopping cart, the transmission of
    sensitive information collected
    on our order form is encrypted using secure socket layer technology
    (SSL). If you choose to use a third
    party checkout option to pay for your order, its privacy statement and
    security practices will apply to
    your information. We encourage you to read that privacy statement before
    providing your information.</p><p>We will retain your information for as
    long as your account is active or as needed to provide you services. If
    you wish to cancel your account or request that we no longer use your
    information to provide you services contact us at <a
        href="mailto:info@dhru.com">info@dhru.com</a>. We will retain and
    use your information as necessary to comply with our legal obligations,
    resolve disputes, and enforce our agreements.</p>


<br/><br/>
<h2>Other Information </h2>
<p>Our Web site includes third party hosted widgets, which are
    interactive mini-programs that run on our site to provide
    single-purpose services (e.g. sharing of articles, videos,
    etc.). Personal information, like your email address, may be
    collected and cookies may be set so the widget can work
    properly. The information through the widget is subject to the
    widget provider�s privacy policy </p><p>Our Web site offers
    publicly accessible blogs or community forums. You should be aware
    that any information you provide in these areas may be read,
    collected, and used by others who access them. To request removal of
    your personal information from our blog or community forum, contact
    us at <a href="mailto:info@dhru.com">info@dhru.com</a>. In some
    cases, we may not be able to remove your personal information, in
    which case we will let you know if we are unable to do so.</p>
<strong>Correcting and Updating Your Personal
    Information</strong><br/><br/><p>To review and update your
    personal information to ensure it is accurate, contact us at <a
        href="mailto:info@dhru.com">info@dhru.com</a>.</p><strong>Notification
    of Privacy Statement Changes</strong><br/><br/><p>We may update this
    privacy statement to reflect changes to our information practices.
    If we make any
    material changes we will notify you by email (sent to the e-mail
    address specified in your account) or by means
    of a notice on this Site prior to the change becoming effective. We
    encourage you to periodically review
    this page for the latest information on our privacy practices.</p>

<br/><br/>
<h2>Cancellations &amp; Refunds Policy</h2>

    Refunds are only issued for software failure. Refunds are not
    issued for server failure/issues, lack of features or if your
    server does not meet the Software Requirements. Refunds are
    determined on individual circumstances and only issued once our
    technical staff determine that DHRU has a fault causing it to
    not run on your server. Installation charges are not refundable
    under any circumstances. Refunds are not available after Deliver
    package.


<div className="clearer"></div>
<br/>
<h2>Website usage terms and conditions</h2>
<div className="fbox">
    <ul>
        <li>The content of the pages of this website is for your general information and use
            only. It is subject to change without notice.<br/><br/></li>
        <li>Neither we nor any third parties provide any warranty or guarantee as to the
            accuracy, timeliness, performance, completeness or suitability of the information
            and materials found or offered on this website for any particular purpose. You
            acknowledge that such information and materials may contain inaccuracies or errors
            and we expressly exclude liability for any such inaccuracies or errors to the
            fullest extent permitted by law.<br/><br/></li>
        <li>Your use of any information or materials on this website is entirely at your own
            risk, for which we shall not be liable. It shall be your own responsibility to
            ensure that any products, services or information available through this website
            meet your specific requirements.<br/><br/></li>
        <li>This website contains material which is owned by or licensed to us. This material
            includes, but is not limited to, the design, layout, look, appearance and graphics.
            Reproduction is prohibited other than in accordance with the copyright notice, which
            forms part of these terms and conditions.<br/><br/></li>
        <li>All trademarks reproduced in this website, which are not the property of, or
            licensed to the operator, are acknowledged on the website.<br/><br/></li>
        <li>Unauthorised use of this website may give rise to a claim for damages and/or be a
            criminal offence.<br/><br/></li>
        <li>From time to time this website may also include links to other websites. These links
            are provided for your convenience to provide further information. They do not
            signify that we endorse the website(s). We have no responsibility for the content of
            the linked website(s).<br/><br/></li>
        <li>You may not create a link to this website from another website or document without
            Dhru's prior written consent. <br/></li>
    </ul>
</div>


<h2>End User License Agreement</h2>
<p>The EULA Agreement is included in the Product download package. By installing, copying, or
    otherwise using our products, you agree to be bound by the terms of the EULA.</p>

<h3>Delivery Policy</h3>
    All licenses are issued instantly upon receipt of payment. Should your payment turn out to be
    fraudulent, the license will be suspended and you will be expected to repay the amount and any
    fees incurred. Our professional services usually take between 24 &amp; 48 hours for completion
    but at peak times such as new releases, they may take slightly longer.

<h3>License Transfer Policy</h3>
    We allow owned licenses bought directly from us to be sold and transferred between people.
    Licenses obtained through a reseller cannot be resold. Before any sale or transfer, the seller
    should contact us to notify us of the sale. Once a buyer has been found, both the buyer and
    seller should contact us to arrange the transfer and payment of the $50 transfer fee. A license
    can only be resold once. Addons may be transferred between licenses within your own client
    account but not resold or transferred to other clients.
<p>Licenses are associated with a single domain name, and any request to change the domain will
    incur a $200 administrative fee. Domain name changes are limited to one occurrence per calendar
    year.</p>

<h3>Refunds Policy</h3>
    Refunds are only issued for software failure. Refunds are not issued for server failure/issues,
    lack of features or if your server does not meet the Software Requirements. Refunds are
    determined on individual circumstances and only issued once our technical staff determine that
    DHRU has a fault causing it to not run on your server. Installation charges are not
    refundable under any circumstances. Refunds are not available after 1 month from the purchase
    date.

<h3>Personal Information</h3>
    We collect your personal information when you make an order on our website. This information is
    kept in our database for our own use but will never be given or sold to any other parties.

<h3>Mailing List</h3>
    All our customers are automatically subscribed to our mailing announcements. Announcements are
    sent out from time to time regarding new releases, new products or services we have available,
    upcoming releases and/or features .

<h3>External Links</h3>
    Our site contains various links to external companies. We are not responsible for the content or
    privacy practices of these websites and would recommend you exercise caution when visiting such
    links. Particularly those found on the forums where people can post links freely.

<br/><br/>
<h2>Copyright notice</h2>
<p>This website and its content is copyright of DHRU.com 2007-10 / Dhru.com 2001/12. All
    rights reserved.</p>

    Any redistribution or reproduction of part or all of the contents in any form is prohibited
    other than the following:

<div className="fbox">
    <ul>
        <li>you may print or download to a local hard disk extracts for your personal and
            non-commercial use only.
        </li>
        <li>you may copy the content to individual third parties for their personal use, but
            only if you acknowledge the website as the source of the material.
        </li>
    </ul>
</div>
<p>
    You may not, except with our express written permission, distribute or commercially
    exploit the content. Nor may you transmit it or store it in any other website or other
    form of electronic retrieval system.
</p>


<br/><br/>
<h2>Collection and Use of Personal Information </h2>
<p>
    We collect the following personal information from you: <br/>
    &nbsp;&nbsp;&nbsp;&nbsp;Contact Information such as name, email address, mailing
    address, phone number<br/>
    &nbsp;&nbsp;&nbsp;&nbsp;Billing Information such as credit card number, and billing
    address <br/>
    &nbsp;&nbsp;&nbsp;&nbsp;Financial Information such as bank or brokerage account
    numbers, types of investments<br/>

    As is true of most Web sites, we automatically gather information about your
    computer such as your IP address, browser type, referring/exit pages, and operating
    system.
    We use this information to: <br/>
    &nbsp;&nbsp;&nbsp;&nbsp;Fulfill your order<br/>
    &nbsp;&nbsp;&nbsp;&nbsp;Send you an order confirmation<br/>
    &nbsp;&nbsp;&nbsp;&nbsp;Send you requested product or service information<br/>
    &nbsp;&nbsp;&nbsp;&nbsp;Send product updates or warranty information<br/>
    &nbsp;&nbsp;&nbsp;&nbsp;Respond to customer service requests<br/>
    &nbsp;&nbsp;&nbsp;&nbsp;Administer your account<br/>
    &nbsp;&nbsp;&nbsp;&nbsp;Send you a newsletter<br/>
    &nbsp;&nbsp;&nbsp;&nbsp;Conduct research and analysis<br/>
    &nbsp;&nbsp;&nbsp;&nbsp;Send you a catalog<br/>
    Choice/Opt-Out<br/>
    You may choose to stop receiving our newsletter or marketing emails by following the
    unsubscribe instructions included in these emails or you can contact us at
    info@dhru.com.
    <br/>
</p>


<br/><br/>
<h2>Disclosure to Third Parties </h2>
<p>We will share your information with third parties only in the ways that are
    described in this privacy statement.</p><p>We may provide your personal
    information to companies that provide services to help us with our business
    activities such as shipping your order or offering customer service. These companies
    are authorized to use your personal information <em>only as necessary to
        provide</em> these services to us.</p><p>We may disclose your personal
    information</p>
<ul>
    <li>as required by law, such as to comply with a subpoena, or similar legal
        process
    </li>
    <li>when we believe in good faith that disclosure is necessary to protect our
        rights, protect your safety or the safety of others, investigate fraud, or
        respond to a government request,
    </li>
    <li>if DHRU is involved in a merger, acquisition, or sale of all or a portion of
        its assets, you will be notified via email and/or a prominent notice on our
        Web site of any change in ownership or uses of your personal information, as
        well as any choices you may have regarding your personal information,
    </li>
    <li>to any other third party with your prior consent to do so.</li>
</ul>
<em>PayPal</em><br/><br/><p>If you choose to use PayPal to finalize and pay for your
    order, you will provide your credit card number, directly to PayPal. PayPal's
    privacy policy will apply to the information you provide on the PayPal Web site.</p>
<em>Third Party E-Commerce Solutions Provider</em><br/><br/><p>Our shopping cart is
    hosted by our e-commerce solutions provider. They host our ordering system,
    and collect your billing information directly from you for the purpose of
    processing your order. This company does not use this information for any other
    purpose. </p>


<br/><br/>
<h2>Tracking Technologies </h2>

<p>We or a third party contracted by us may use technologies such as
    cookies, Web beacons,
    or scripts to gather information about how you and others interact with
    our Web site. For example,
    we will know how many users access a specific areas or features within
    our site and which links or ads
    they clicked on. We use this aggregated information to understand and
    optimize how our site is used,
    improve our marketing efforts, and provide content and features that are
    of interest to you.</p>


<br/><br/>
<h2>Security</h2>

<p>The security of your personal information is important to us. We
    follow generally accepted industry
    standards to protect the personal information submitted to us, both
    during transmission and once we
    receive it. No method of transmission over the Internet, or method
    of electronic storage,
    is 100% secure. Therefore, we cannot guarantee its absolute
    security.</p><p>If you have any questions about
    security on our Web site, you can contact us at <a
        href="mailto:info@dhru.com">info@dhru.com</a></p><p>We provide two
    means for you to make a purchase on our site. You can either use our
    order form or a third
    party checkout option. If you use our shopping cart, the transmission of
    sensitive information collected
    on our order form is encrypted using secure socket layer technology
    (SSL). If you choose to use a third
    party checkout option to pay for your order, its privacy statement and
    security practices will apply to
    your information. We encourage you to read that privacy statement before
    providing your information.</p><p>We will retain your information for as
    long as your account is active or as needed to provide you services. If
    you wish to cancel your account or request that we no longer use your
    information to provide you services contact us at <a
        href="mailto:info@dhru.com">info@dhru.com</a>. We will retain and
    use your information as necessary to comply with our legal obligations,
    resolve disputes, and enforce our agreements.</p>


<br/><br/>
<h2>Other Information </h2>
<p>Our Web site includes third party hosted widgets, which are
    interactive mini-programs that run on our site to provide
    single-purpose services (e.g. sharing of articles, videos,
    etc.). Personal information, like your email address, may be
    collected and cookies may be set so the widget can work
    properly. The information through the widget is subject to the
    widget provider�s privacy policy </p><p>Our Web site offers
    publicly accessible blogs or community forums. You should be aware
    that any information you provide in these areas may be read,
    collected, and used by others who access them. To request removal of
    your personal information from our blog or community forum, contact
    us at <a href="mailto:info@dhru.com">info@dhru.com</a>. In some
    cases, we may not be able to remove your personal information, in
    which case we will let you know if we are unable to do so.</p>
<strong>Correcting and Updating Your Personal
    Information</strong><br/><br/><p>To review and update your
    personal information to ensure it is accurate, contact us at <a
        href="mailto:info@dhru.com">info@dhru.com</a>.</p><strong>Notification
    of Privacy Statement Changes</strong><br/><br/><p>We may update this
    privacy statement to reflect changes to our information practices.
    If we make any
    material changes we will notify you by email (sent to the e-mail
    address specified in your account) or by means
    of a notice on this Site prior to the change becoming effective. We
    encourage you to periodically review
    this page for the latest information on our privacy practices.</p>

<br/><br/>
<h2>Cancellations &amp; Refunds Policy</h2>

    Refunds are only issued for software failure. Refunds are not
    issued for server failure/issues, lack of features or if your
    server does not meet the Software Requirements. Refunds are
    determined on individual circumstances and only issued once our
    technical staff determine that DHRU has a fault causing it to
    not run on your server. Installation charges are not refundable
    under any circumstances. Refunds are not available after Deliver
    package.


<br/><br/>
<small>Last Updated: 01 Nov 2024</small>
<br/><br/>

<div className="clearer"></div>
<br/>

                </div>

            </Fragment>
        );
    }

}


const mapStateToProps = (state) => {
    return {
        page: state.appApiData.page
    }
};

const mapDispatchToProps = (dispatch) => ({
    setPage: (page) => dispatch(setPage(page)),
});


export default connect(mapStateToProps, mapDispatchToProps)(Index);
