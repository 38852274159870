import React, {Component, Fragment, useEffect, useState} from "react";
import {connect} from "react-redux";
import {_tilt, map, scrollToTop} from "../../lib/functions";
import {integration} from "../../lib/static";
import {ReactComponent as  Ecommerce} from "../../theme/svg/ecommerce.svg";
import {ReactComponent as Fusionmain} from "../../theme/svg/orderautomation.svg";
import {ReactComponent as FusionEcommerceMain} from "../../theme/svg/robustframework.svg";
import {ReactComponent as WalletMain} from "../../theme/svg/walletmain.svg";
import {ReactComponent as FusionApi} from "../../theme/svg/fusionapi.svg";
import {ReactComponent as Restaurant} from "../../theme/svg/ecosystem.svg";
import {ReactComponent as CMS} from "../../theme/svg/cms.svg";
import {ReactComponent as ERPSmart} from "../../theme/svg/erpsmart.svg";
import {ReactComponent as SmartPos} from "../../theme/svg/smartpos.svg";
import {ReactComponent as IngeniusUtilities} from "../../theme/svg/ignesisutility.svg";
import {ReactComponent as SupportingClient} from "../../theme/svg/supportingclient.svg";
import {ReactComponent as SecurityFirst} from "../../theme/svg/securityfirst.svg";
import {ReactComponent as Dashboard} from "../../theme/svg/dashboard.svg";
import {ReactComponent as Jobsheet} from "../../theme/svg/jobsheet.svg";
import {ReactComponent as Updation} from "../../theme/svg/updation.svg";
import {ReactComponent as MobileApp} from "../../theme/svg/mobile-app.svg";
import {ReactComponent as Task} from "../../theme/svg/task.svg";


import { NavLink} from 'reactstrap';
import {Link, useParams} from 'react-router-dom';
import {setPage} from "../../lib/Store/actions/appApiData";
import Image from "../../components/Image";


const Index = (props) => {

    const params = useParams()

    const {setPage} = props;
    const {type,product} = params;


    useEffect(()=>{
        document.title = `DHRU  ${product === 'dhru-fusion'?'Fusion':product === 'dhru-ecommerce'?'Ecommerce':product === 'dhru-wallet'?'Wallet':'ERP'}   | Application for Businesses`;
        _tilt();
        scrollToTop();
    },[])


    return(
        <Fragment>

            <div id="start-screen" className="start-screen start-screen--full-height start-screen--style-1">
                <div className="start-screen__bg-container position-relative bg-light">


                    <div className="bg-svg svg20"></div>

                </div>


                <div className="start-screen__shapes  d-none d-lg-block">

                    <div className="img-shape">
                    </div>

                    { product === 'dhru-fusion' &&  <div   className="img-shape" style={{maxWidth: '45%',marginRight: '50px',width: '100%'}}>
                        <div  className="start-screen__shapes">
                            <figure className="image-container">
                                <Fusionmain />
                            </figure>
                        </div>
                    </div>}

                    { product === 'dhru-erp' &&  <div    className="img-shape" style={{maxWidth: '35%',marginRight: '200px',width: '100%'}}>
                        <div  className="start-screen__shapes">
                            <figure className="image-container">
                                <Restaurant/>
                            </figure>
                        </div>
                    </div>}

                    { product === 'dhru-ecommerce' && <div    className="img-shape" style={{maxWidth: '40%',marginRight: '200px',width: '100%'}}>
                        <div  className="start-screen__shapes">
                            <figure className="image-container">

                                <FusionEcommerceMain/>

                            </figure>
                        </div>
                    </div>}

                    { product === 'dhru-wallet' && <div    className="img-shape" style={{maxWidth: '45%',marginRight: '50px',width: '100%'}}>
                        <div  className="start-screen__shapes">
                            <figure className="image-container">

                                <WalletMain/>

                            </figure>
                        </div>
                    </div>}

                </div>

                <div className="start-screen__content-container">
                    <div className="start-screen__content__item align-items-center">
                        <div className="container">


                            <div className="row">

                                <div className="col-12 col-md-10 col-lg-6 " data-aos="fade-right">
                                    <div className="d-none d-md-block">
                                        <div className=""> <span className="text-capitalize"> { type !== 'servicecenter'? type :'service-center' }</span> <span className="text-uppercase">{product}</span>   </div>
                                    </div>

                                    { product === 'dhru-fusion' &&   <div >
                                        <h2>  <span> Our very own inception for order automation</span></h2>
                                        <p>
                                            State of the art solution for
                                            automation of orders via APi, client & supplier management, Billing, Shopping cart, Blog, Live Chat and Support tickets all under one dashboard.
                                        </p>
                                    </div>}

                                    { product === 'dhru-erp' &&   <div  >
                                        { type === 'retail' && <div  >
                                            <h2>  <span> A Robust solution for an extensive industry   </span></h2>
                                            <p>
                                                We offer a one stop solution for the Retail industry that can be used in essentially all segments from a single outlet to Multichannel brands.
                                            </p>
                                        </div>}

                                        { type === 'restaurant' &&  <div  >
                                            <h2>  <span>A Powerhouse in itself  </span></h2>
                                            <p>
                                                <strong> A One-stop solution for the restaurant industry that can be used in all segments from cafe to Fine Dine Restaurants and Multiple Chain of outlets.</strong>
                                            </p>
                                        </div>}

                                        { type === 'servicecenter' &&  <div  >
                                            <h2>  <span> An Omnipotent Solution   </span></h2>
                                            <p>
                                                Introducing a one of a kind solution for the Service Industry which will revolutionize Automation with a sturdy infrastructure.
                                            </p>
                                        </div>}

                                    </div>}

                                    { product === 'dhru-ecommerce' &&   <div >
                                        <h2>  <span> Amplify your business with a  smart and secure  eCommerce Solution that does not rely on technical knowledge. </span></h2>
                                    </div>}

                                    { product === 'dhru-wallet' &&   <div >
                                        <h2>  <span> Our take on digital payments, <br/> made simple. </span></h2>
                                        <p> Engage clients with an exceptional payment experience with an exclusive Mobile Wallet.
                                            From instant P2P & B2B payments and multiple ways to load the wallet, provide a perfect digital payment experience.  </p>
                                    </div>}

                                    <div className="spacer py-2 py-sm-4"></div>

                                    <div className="d-sm-table">
                                        <div className="d-sm-table-cell  pb-5 pb-sm-0 pr-sm-8 pr-md-10 mr-5">
                                            {

                                                product === 'dhru-wallet' /*|| (product === 'dhru-erp' && type==='servicecenter') || (product === 'dhru-erp' && type==='retail')*/ ?  <NavLink className="custom-btn custom-btn--big custom-btn--style-4 mb-3" tag={Link} to={`/contactus`}  onClick={()=>setPage({page:'contactus',breadcrumb: ''})} >Contact Us</NavLink> :
                                                    <NavLink className="custom-btn custom-btn--big custom-btn--style-4 mb-3" onClick={()=>setPage({page:'pricing',breadcrumb:'Pricing'})} tag={Link} to={`/pricing/${product}`}  >Getting Started</NavLink>
                                            }
                                        </div>

                                    </div>
                                </div>

                                <div className="col-lg-6 d-block d-lg-none">

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>


            {/*<div className="modal fade"  id="demo-request" tabindex="-1" role="dialog" aria-labelledby="demo-request-aria" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">

                            <div className="modal-body">

                                <form className="authorization__form" action="#">
                                    <h3 className="__title">Sign Up</h3>

                                    <div className="input-wrp">
                                        <input className="textfield textfield--grey" type="text" value="" placeholder="Full Name" />
                                    </div>

                                    <div className="input-wrp">
                                        <input className="textfield textfield--grey" type="text" value="" placeholder="Email" inputmode="email" x-inputmode="email"/>
                                    </div>

                                    <div className="input-wrp">
                                        <input className="textfield textfield--grey" type="text" value="" placeholder="Phone"/>
                                    </div>



                                    <button className="custom-btn custom-btn--medium custom-btn--style-2 wide" type="button" ng-click="demorequest()" role="button">Demo Request</button>

                                </form>


                            </div>
                        </div>
                    </div>
                </div>*/}



            {/*<div className="modal fade" id="demo-detail" tabindex="-1" role="dialog" aria-labelledby="demo-detail-aria" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div className="modal-content">

                            <div className="modal-body">

                                <div className="p-5">
                                    <div className="row" ng-if="product=='fusion'">
                                        <div className="col-lg-6">
                                            <h3>Client Area</h3>
                                            <a href="https://fusiondemo.dhru.com" target="_blank">https://fusiondemo.dhru.com/</a><br/>
                                            <h5  className="mb-2"> Access Detail </h5>
                                            username : admin <br/>
                                            password : admin <br/>
                                        </div>
                                        <div className="col-lg-6">
                                            <h3>Admin Area</h3>
                                            <a href="https://fusiondemo.dhru.com/admincp" target="_blank">https://fusiondemo.dhru.com/admincp</a> <br/>
                                            <h5  className="mb-2"> Access Detail </h5>
                                            username : admin <br/>
                                            password : admin <br/>
                                        </div>
                                    </div>

                                    <div className="row" ng-if="product=='ecommerce'">
                                        <div className="col-lg-6">
                                            <h3>Client Area</h3>
                                            <a href="http://shoppingcartdemo.dhru.com" target="_blank">http://shoppingcartdemo.dhru.com</a><br/>
                                            <h5  className="mb-2"> Access Detail </h5>
                                            username : admin <br/>
                                            password : admin <br/>
                                        </div>
                                        <div className="col-lg-6">
                                            <h3>Admin Area</h3>
                                            <a href="http://shoppingcartdemo.dhru.com//admincp" target="_blank">http://shoppingcartdemo.dhru.com/admincp</a> <br/>
                                            <h5  className="mb-2"> Access Detail </h5>
                                            username : admin <br/>
                                            password : admin <br/>
                                        </div>
                                    </div>


                                    <div className="row" ng-if="product=='erp'">
                                        <div className="col-lg-6">
                                            <h3>Book your demo here</h3>
                                            <a href="http://restaurant.dhru.com/" target="_blank">http://restaurant.dhru.com/</a><br/>
                                        </div>

                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>*/}



            { product === 'dhru-fusion' && <div >
                <section className="section">
                    <div className="container">
                        <div className="row row-reverse align-items-md-center">
                            <div className="col-12 col-lg-6 " data-aos="fade-right">
                                <div className="section-heading">

                                    <h3 className="__title">APi Automation<br/><span>for fast Order Processing </span></h3>
                                </div>

                                <div className="spacer py-4"></div>

                                <div>
                                    <p>
                                        <strong style={{color:'#555'}}>Dhru Fusion is an all-in-one client & supplier management, Billing, Shopping cart, Blog,Support solution for online businesses. It is an ultimate solution for Your Supplier's API Connection, so you are in control with a very powerful business automation tool. Easy to Use - Simple & Intuitive Interface, Fast Loading, Quick Setup & Efficient Support . </strong>
                                    </p>

                                    <ul className="check-list my-md-6">
                                        <li><i className="ico-checked fontello-ok"></i>Send orders directly to your supplier and return code</li>
                                        <li><i className="ico-checked fontello-ok"></i>Supports over 50+ supplier worldwide</li>
                                        <li><i className="ico-checked fontello-ok"></i>You can   provide APi for your own site to   clients</li>
                                        <li><i className="ico-checked fontello-ok"></i>We provide full APi documentation  with examples</li>
                                    </ul>

                                </div>
                            </div>

                            <div className="spacer py-4 d-lg-none"></div>

                            <div className="col-12 col-lg-6  text-center text-lg-right">

                                <figure style={{maxWidth: '470px'}} className="image-container js-tilt" data-tilt-speed="600" data-tilt-max="15" data-tilt-perspective="700">
                                    <FusionApi/>
                                </figure>

                            </div>
                        </div>
                    </div>
                </section>

                <section className="section">
                    <div className="container">
                        <div className="row row-reverse flex-lg-row-reverse align-items-lg-center">
                            <div className="col-12 col-lg-6 " data-aos="fade-left">
                                <div className="section-heading">
                                    <h3 className="__title">Content Management CMS  <br/> & Blogging </h3>
                                </div>

                                <div className="spacer py-4"></div>

                                <div>
                                    <p>
                                        We Offer an in-built Style editor which manages the front-end and it is easy to use even for a non-technical person. With CMS you can always keep your clients updated with the latest know-how of your services.
                                        You can also Enable/Disable visitors to leave comments on your blog posts.
                                        <br/><br/>
                                        <strong>Fully customizable Templates</strong>
                                        <br/>
                                        We offer Templates that can be customized according to your needs where you can manage the styling/layout , custom banner & pages and an extensive range of design tools.
                                    </p>



                                </div>
                            </div>

                            <div className="spacer py-4 d-lg-none"></div>

                            <div className="col-12 col-lg-6  text-center text-lg-left">

                                <figure style={{maxWidth: '450px'}} className="image-container js-tilt" data-tilt-speed="600" data-tilt-max="15" data-tilt-perspective="700">

                                    <CMS/>
                                </figure>

                            </div>
                        </div>
                    </div>
                </section>



            </div>}

            { product === 'dhru-ecommerce' &&  <div >
                <section className="section">
                    <div className="container">
                        <div className="row   align-items-md-center">


                            <div className="col-12 col-lg-6">

                                <figure style={{width:'100%',marginLeft:'-50px'}}  className="image-container js-tilt" data-tilt-speed="600" data-tilt-max="15" data-tilt-perspective="700">
                                    <Ecommerce/>

                                </figure>

                            </div>



                            <div className="col-12 col-lg-6">
                                <div className="section-heading">

                                    <h3 className="__title">Selling online <br/><span> is now easy</span></h3>
                                </div>

                                <div className="spacer py-4"></div>

                                <div>
                                    <p>
                                        <strong style={{color:'#555'}}>Dhru provideadvanced eCommerce solution to sell products online securely and quick with an easy to use web-based administration that does not require any technical knowledge</strong>
                                    </p>

                                </div>
                            </div>

                        </div>

                        <div className="spacer py-4 d-lg-none"></div>

                    </div>
                </section>


            </div>}



            { product === 'dhru-erp' &&  <div>

                { type === 'restaurant' &&  <div>

                    <section className="section">
                        <div className="container">
                            <div className="row row-reverse">
                                <div className="col-12 col-sm text-center">
                                    <h4 className="__title h3">Restaurant Overview </h4>


                                    <p> Restaurants have evolved along with the digital era and are trying to keep up with innovations on a daily basis. With increasing demand and competition in the hospitality sector, hotels and restaurants can't allow their daily tasks to affect their performance. Hence, the incorporation of the Restaurant management system by Dhru ERP has become an effective attempt for restaurants and hotels striving to reach the top in profit and popularity. <br/> Get a synopsis of your restaurant from a single screen , supervise amount of items, bills and waiting time. <br/> Tap on a table name and manage it or customise an arrangement for visual selection. <br/> Third party orders get a blinker and notification on your POS . </p>

                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="section">
                        <div className="container">
                            <div className="row row-reverse align-items-md-center">
                                <div className="col-12 col-lg-6">
                                    <div className="section-heading">

                                        <h3 className="__title">Smart POS   </h3>
                                    </div>


                                    <div>
                                        <ul className="check-list">
                                            <li><i className="ico-checked fontello-ok"></i> Access and manage tables
                                                and waiting lists , see their orders and add/remove items if needed.
                                            </li>
                                            <li><i className="ico-checked fontello-ok"></i> Select items visually by
                                                group, add quantity , manage modifiers, add predefined notes or
                                                custom notes.
                                            </li>
                                            <li><i className="ico-checked fontello-ok"></i> With support to all the
                                                platforms whether it is android , iOS or windows our POS and native
                                                applications provides optimum performance.
                                            </li>
                                            <li><i className="ico-checked fontello-ok"></i> You can enable/ disable
                                                access and permissions where ever you want , so in case your waiter
                                                wants to cancel an order it will need your or manager's permission.
                                            </li>
                                            <li><i className="ico-checked fontello-ok"></i> You can check deleted
                                                invoices and cancelled orders with compulsory reasons right on the
                                                dashboard, so there are minimal chances of theft in the restaurant.
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div className="spacer py-4 d-lg-none"></div>

                                <div className="col-12 col-lg-6  text-center text-lg-right">

                                    <figure className="image-container js-tilt" data-tilt-speed="600" data-tilt-max="15" data-tilt-perspective="700" style={{maxWidth:'70%'}}>

                                        <ERPSmart/>
                                    </figure>

                                </div>
                            </div>
                        </div>
                    </section>


                    <section className="section section--no-pt">
                        <div className="container">
                            <div className="row row-reverse flex-lg-row-reverse align-items-lg-center">
                                <div className="col-12 col-lg-12">
                                    <div className="feature feature--s1 ">
                                        <div className="__inner row">
                                            <div className="__item col-lg-6">
                                                <div className="row">
                                                    <div className="col-12 col-sm"><h4 className="__title h3">Self
                                                        order</h4>
                                                        <ul className="check-list">
                                                            <li><i className="ico-checked fontello-ok"></i>Self
                                                                order App for Take away and home delivery orders.
                                                            </li>
                                                            <li><i className="ico-checked fontello-ok"></i>A unique
                                                                feature of customer signature on device for home
                                                                delivery, app records the signature, location and
                                                                Photograph so there's no mishap or excuses of
                                                                imperfection.
                                                            </li>
                                                            <li><i className="ico-checked fontello-ok"></i>Self
                                                                order application for Dine-in customers , they can
                                                                order right from the table without having to call
                                                                for anyone
                                                            </li>
                                                            <li><i className="ico-checked fontello-ok"></i>Self
                                                                order is available on both iOS and Android.
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="__item col-lg-6">
                                                <div className="row">
                                                    <div className="col-12 col-sm">
                                                        <div className={'section-heading'}>
                                                            <h3 className="__title">Recipe
                                                                Management</h3>
                                                        </div>
                                                        <ul className="check-list">
                                                            <li><i className="ico-checked fontello-ok"></i> With our
                                                                recipe management tool you get a detailed report of
                                                                total raw material consumption and food costing.
                                                            </li>
                                                            <li><i className="ico-checked fontello-ok"></i> Setup a
                                                                recipe once and it will show you all the statistics
                                                                of the included raw materials, the number of raw
                                                                materials in stock , low stock alert and age of the
                                                                stock.
                                                            </li>
                                                            <li><i className="ico-checked fontello-ok"></i> With age
                                                                of your raw materials being displayed never
                                                                compromise on your food quality
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="__item col-lg-6">
                                                <div className="row">
                                                    <div className="col-12 col-sm"><h4
                                                        className="__title h3">Inventory Management</h4>
                                                        <p> Inventory is a crucial part of a restaurant as it
                                                            consists of all the raw materials which come together as
                                                            a dish and the inventory management system integrated
                                                            with the POS enables you to keep track and organise all
                                                            the characteristics of the stock. Dhru providea fully
                                                            accounted purchase and mass purchase tool which keeps
                                                            track of the stock, its shelf life and if any
                                                            unsatisfactory products are delivered it has a tool to
                                                            record returned items. All of these tools synchronise
                                                            with the POS and create reports of opening stock, carry
                                                            forwarded stock, closing Stock and Stock Variance. </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="__item col-lg-6">
                                                <div className="row">
                                                    <div className="col-12 col-sm"><h4 className="__title h3">CRM /
                                                        Marketing</h4> <p> Create, maintain and access your customer
                                                        profile in the integrated CRM system across all your
                                                        restaurants. Make them feel privileged and they'll keep
                                                        coming back. We provide you with your very own loyalty
                                                        program where you make the rules and our software does the
                                                        work at POS. All you have to do is give them a Royal
                                                        experience. Our software has all the tools you need to
                                                        promote your business right from your screen; generate
                                                        coupon codes , special offers &amp; greetings on birthdays
                                                        and anniversaries , special parties and occasions hosted at
                                                        the restaurant or any of your custom required offers and
                                                        never worry about tracking offers with a click of a button
                                                        all the promotional offers will appear on your screen . </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="__item col-lg-6">
                                                <div className="row">
                                                    <div className="col-12 col-sm"><h4
                                                        className="__title h3">Feedbacks</h4> <p> You get your
                                                        custom feedback form where you can set questions according
                                                        to your need . Revert in appreciation to positive and
                                                        negative feedbacks . We understand not everyone is willing
                                                        to give feedbacks right after visiting your restaurant so we
                                                        give a unique SMS with feedback link service that gives
                                                        customers liberty to give their feedbacks whenever they
                                                        want. </p></div>
                                                </div>
                                            </div>
                                            <div className="__item col-lg-6">
                                                <div className="row">
                                                    <div className="col-12 col-sm"><h4 className="__title h3">Online
                                                        ordering</h4>
                                                        <ul className="check-list">
                                                            <li><i className="ico-checked fontello-ok"></i> Assign
                                                                your personal domain in the POS to direct your
                                                                online orders in the system.
                                                            </li>
                                                            <li><i className="ico-checked fontello-ok"></i> If you
                                                                do not have a domain you can still receive online
                                                                orders with our cloud link (for eg
                                                                restaurantXYZ.dhru.cloud )
                                                            </li>
                                                            <li><i className="ico-checked fontello-ok"></i> when any
                                                                changes are made in the menu , it automatically
                                                                syncs data with third party services and your online
                                                                ordering portal.
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="__item col-lg-6">
                                                <div className="row">
                                                    <div className="col-12 col-sm"><h4
                                                        className="__title h3">Statistics On the Go</h4> <p> You can
                                                        get real-time reports on the dashboard and on the ERP Admin
                                                        application on your phone, all you need to do is log in .
                                                        With mobile application to handle such crucial statistics
                                                        you are always on the go and updated. Here are some of the
                                                        offered features : </p>
                                                        <ul className="check-list">
                                                            <li><i className="ico-checked fontello-ok"></i> Sales
                                                                Summary
                                                            </li>
                                                            <li><i className="ico-checked fontello-ok"></i>Top
                                                                selling by Day
                                                            </li>
                                                            <li><i className="ico-checked fontello-ok"></i>Total
                                                                invoice amount
                                                            </li>
                                                            <li><i className="ico-checked fontello-ok"></i>Total
                                                                orders taken
                                                            </li>
                                                            <li><i className="ico-checked fontello-ok"></i>Received
                                                                amount by payment gateways
                                                            </li>
                                                            <li><i className="ico-checked fontello-ok"></i>Pending
                                                                tasks
                                                            </li>
                                                            <li><i className="ico-checked fontello-ok"></i>Low Stock
                                                                alert.
                                                            </li>
                                                            <li><i className="ico-checked fontello-ok"></i>Top 10
                                                                Clients
                                                            </li>
                                                            <li><i className="ico-checked fontello-ok"></i>Top
                                                                selling products
                                                            </li>
                                                            <li><i className="ico-checked fontello-ok"></i>Total
                                                                expenses of the day
                                                            </li>
                                                            <li>And much more...</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="__item col-lg-6">
                                                <div className="row">
                                                    <div className="col-12 col-sm"><h4 className="__title h3">KOT /
                                                        Kitchen Order Display</h4>
                                                        <ul className="check-list">
                                                            <li><i className="ico-checked fontello-ok"></i>Kitchen
                                                                Order Ticket is generated at the very second you
                                                                confirm order from POS which saves you a lot of
                                                                running around time
                                                            </li>
                                                            <li><i className="ico-checked fontello-ok"></i>If you do
                                                                not wish to link a Thermal Printer in the kitchen
                                                                you get an option of putting up a Kitchen Order
                                                                Display(KOD) screen which will show exact details of
                                                                the order and prevent errors.
                                                            </li>
                                                            <li><i className="ico-checked fontello-ok"></i>In case
                                                                there's a time where you cancel a certain order a
                                                                new cancelled KOT will be generated and also be
                                                                displayed as cancelled on KOD.
                                                            </li>
                                                            <li><i className="ico-checked fontello-ok"></i>With a
                                                                dedicated KOT application , your chef can mark KOTs
                                                                which are ready to serve.
                                                            </li>
                                                            <li><i className="ico-checked fontello-ok"></i>For KOT
                                                                Thermal printer has all options supported (
                                                                Bluetooth , Ethernet and WiFi).
                                                            </li>
                                                            <li><i className="ico-checked fontello-ok"></i>For KOD
                                                                any Android TV , tablet or windows system is
                                                                supported.
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="spacer py-4 d-lg-none"></div>
                            </div>
                        </div>
                    </section>

                </div>}
                { type === 'retail' &&  <div  >


                    <section className="section">
                        <div className="container">
                            <div className="row row-reverse align-items-md-center">
                                <div className="col-12 col-lg-6">
                                    <div className="section-heading">
                                        <h3 className="__title">Smart POS</h3>
                                    </div>

                                    <div>
                                        <ul className="check-list">
                                            <li><i className="ico-checked fontello-ok"></i> Select items visually by group, add quantity, manage orders and custom notes.</li>
                                            <li><i className="ico-checked fontello-ok"></i> With support to all platforms whether it is android, iOS or windows our POS and native applications provides optimum performance.</li>
                                            <li><i className="ico-checked fontello-ok"></i> Define access and roles and keep track of how your team is performing with just a few clicks.</li>
                                        </ul>
                                    </div>
                                </div>

                                <div className="spacer py-4 d-lg-none"></div>

                                <div className="col-12 col-lg-6  text-center text-lg-right">
                                    <figure className="image-container js-tilt" data-tilt-speed="600" data-tilt-max="15" data-tilt-perspective="700" style={{maxWidth:'70%'}}>
                                        <SmartPos/>
                                    </figure>
                                </div>
                            </div>
                        </div>
                    </section>


                    <div className="spacer py-4"></div>


                    <section className="section">
                        <div className="container">
                            <div className="row row-reverse">

                                <div className="col-12 mb-5 col-lg-6">
                                    <div className="section-heading">
                                        <h3 className="__title">Accounting</h3>
                                    </div>

                                    <div>
                                        <ul className="check-list">
                                            <li><i className="ico-checked fontello-ok"></i>Customisable chart of account for different types of businesses which can be edited and managed.</li>
                                            <li><i className="ico-checked fontello-ok"></i>Various vouchers like sales, purchase, stock inward, delivery challan, credit - debit notes, journal entries, cash bank receipt</li>
                                            <li><i className="ico-checked fontello-ok"></i>Manage Account ledgers</li>
                                            <li><i className="ico-checked fontello-ok"></i>Manage Debtors and creditors accounting.</li>
                                            <li><i className="ico-checked fontello-ok"></i>All the necessary tools to generate complex accounting reports, Balance sheets, Trial balance sheet, Profit & Loss reports etc...</li>
                                            <li><i className="ico-checked fontello-ok"></i>A feature for Financial Year Closing with carry forward balance and stock.</li>
                                            <li><i className="ico-checked fontello-ok"></i>Bank reconciliation will reflect all your bank transactions right at your screen.</li>
                                            <li><i className="ico-checked fontello-ok"></i>GST Compatible.</li>
                                        </ul>
                                    </div>
                                </div>



                                <div className="col-12 col-lg-6">
                                    <div className="section-heading">
                                        <h3 className="__title">CRM / Marketing</h3>
                                    </div>

                                    <div>
                                        <p>
                                            Create, maintain and access your customer profile in the integrated CRM system across all your outlets. Make them feel privileged and they'll keep coming back. We provide you with your very own loyalty program where you make the rules and our software does the work at POS. All you have to do is give them a Royal experience. Our software has all the tools you need to promote your business right from your screen; generate coupon codes, special offers & greetings on birthdays and anniversaries, special parties and occasions hosted at the restaurant or any of your custom required offers and never worry about tracking offers with a click of a button all the promotional offers will appear on your screen .
                                        </p>
                                    </div>
                                </div>


                                <div className="col-12 col-lg-6">
                                    <div className="section-heading">
                                        <h3 className="__title">Inventory Management</h3>
                                    </div>


                                    <div>
                                        <p>
                                            Inventory is a crucial part of a business and the inventory management system integrated with the POS enables you to keep track and organise all the characteristics of the stock. Dhru providea fully accounted purchase and mass purchase tool which keeps track of the stock, its shelf life and if any unsatisfactory products are delivered it has a tool to record returned items. All of these tools synchronise with the POS and create reports of opening stock, carry forwarded stock, closing Stock and Stock Variance
                                        </p>
                                    </div>

                                    <div className="section-heading mt-5">
                                        <h3 className="__title">Feedbacks</h3>
                                    </div>

                                    <div>
                                        <p>
                                            You get your custom feedback form where you can set questions according to your need. Revert in appreciation to positive and negative feedbacks. We understand not everyone is willing to give feedbacks right after visiting your business so we give a unique SMS with feedback link service that gives customers liberty to give their feedbacks whenever they want.
                                        </p>
                                    </div>


                                </div>



                                <div className="col-12 col-sm">
                                    <div className={'section-heading'}>
                                        <h3 className="__title">Statistics On the Go</h3>
                                    </div>
                                    <p>
                                        You can get real time reports on the dashboard and on the ERP Admin application on your phone, all you need to do is log in . With mobile application to handle such crucial statistics you are always on the go and updated. Here are some of the offered features :
                                    </p>
                                    <ul className="check-list">
                                        <li><i className="ico-checked fontello-ok"></i> Sales Summary </li>
                                        <li><i className="ico-checked fontello-ok"></i>Top selling by Day </li>
                                        <li><i className="ico-checked fontello-ok"></i>Total invoice amount</li>
                                        <li><i className="ico-checked fontello-ok"></i>Total orders taken</li>
                                        <li><i className="ico-checked fontello-ok"></i>Received amount by payment gateways</li>
                                        <li><i className="ico-checked fontello-ok"></i>Pending tasks</li>
                                        <li><i className="ico-checked fontello-ok"></i>Low Stock alert.</li>
                                        <li><i className="ico-checked fontello-ok"></i>Top 10 Clients</li>
                                        <li><i className="ico-checked fontello-ok"></i>Top selling products</li>
                                        <li><i className="ico-checked fontello-ok"></i>Total expenses of the day</li>
                                        <li>And much more...</li>
                                    </ul>
                                </div>




                            </div>
                        </div>
                    </section>



                </div>}
                { type === 'servicecenter' &&  <div  >
                    {/*<div className="container">
                            <section className="section">
                                <h2 className="text-center">
                                    COMING SOON
                                    <span> for your Business needs.</span>
                                </h2>
                            </section>
                        </div>*/}

                    <div className="container">
                        <section className="section">
                            <div className="container">

                                <div className="row   align-items-md-center">

                                    <div className="col-12 col-lg-6">
                                        <figure style={{width:'100%',maxWidth:450}}  className="image-container js-tilt" data-tilt-speed="600" data-tilt-max="15" data-tilt-perspective="700">
                                            {/*<SecurityFirst/>*/}
                                            <Dashboard/>

                                        </figure>
                                    </div>

                                    <div className="spacer py-4 d-lg-none"></div>

                                    <div className="col-12 col-lg-6">
                                        <div className="section-heading">

                                            <h3 className="__title">Bringing growth, ingenuity, and experience  <br/><span> to market. </span></h3>
                                        </div>

                                        <div className="spacer py-4"></div>

                                        <div>
                                            <p>
                                                <strong style={{color:'#555'}}>All that you really want is to produce more income and give an extraordinary checkout experience to your clients.  </strong>
                                            </p>

                                        </div>
                                    </div>

                                </div>

                            </div>
                        </section>

                        <section className="section">
                            <div className="container">

                                <div className="row     align-items-md-center">
                                    <div className="col-12 col-lg-6">
                                        <div className="section-heading">

                                            <h3 className="__title">Your Shop Operations <br/><span> Made Easier, Better & Faster </span></h3>
                                        </div>

                                        <div className="spacer py-4"></div>

                                        <div>
                                            <p>
                                                <strong style={{color:'#555'}}>With DHRU. Create and assign Job Sheets, manage and organize inventory and get a total outline of what's happening in your repair shop.
                                                </strong>
                                            </p>

                                        </div>
                                    </div>

                                    <div className="spacer py-4 d-lg-none"></div>

                                    <div className="col-12 text-right col-lg-6">
                                        <figure style={{width:'100%',maxWidth:400}}  className="image-container js-tilt" data-tilt-speed="600" data-tilt-max="15" data-tilt-perspective="700">
                                            <Jobsheet/>
                                        </figure>
                                    </div>
                                </div>

                            </div>
                        </section>

                        <section className="section">
                            <div className="container">

                                <div className="row   align-items-md-center">

                                    <div className="col-12  col-lg-6">
                                        <figure style={{width:'100%',maxWidth:450}}  className="image-container js-tilt" data-tilt-speed="600" data-tilt-max="15" data-tilt-perspective="700">

                                            <MobileApp/>

                                        </figure>
                                    </div>


                                    <div className="spacer py-4 d-lg-none"></div>

                                    <div className="col-12 col-lg-6">
                                        <div className="section-heading">

                                            <h3 className="__title">All of this on a native mobile app </h3>
                                        </div>

                                        <div className="spacer py-4"></div>

                                        <div>
                                            <p>
                                                <strong style={{color:'#555'}}>Power Packed native Application for both iOS and Android which lets you manage all your business needs right within your palm.
                                                </strong>
                                            </p>

                                        </div>
                                    </div>


                                </div>

                            </div>
                        </section>

                        <section className="section">
                            <div className="container">
                                <div className="row   align-items-md-center">



                                    <div className="col-12 col-lg-6">
                                        <div className="section-heading">

                                            <h3 className="__title">Keep Your Customers Updated   <br/><span> at Every Step </span></h3>
                                        </div>

                                        <div className="spacer py-4"></div>

                                        <div>
                                            <p>
                                                <strong style={{color:'#555'}}>Give your clients superior insight through alerts and updates via email and SMS. </strong>
                                            </p>

                                        </div>
                                    </div>


                                    <div className="spacer py-4 d-lg-none"></div>


                                    <div className="col-12  text-right  col-lg-6">
                                        <figure style={{width:'100%',maxWidth:450}}  className="image-container js-tilt" data-tilt-speed="600" data-tilt-max="15" data-tilt-perspective="700">

                                            <Updation/>

                                        </figure>
                                    </div>


                                </div>

                            </div>
                        </section>

                        <section className="section">
                            <div className="container">


                                <div className="row   align-items-md-center">



                                    <div className="col-12 col-lg-6">
                                        <figure style={{width:'100%',maxWidth:450}}  className="image-container js-tilt" data-tilt-speed="600" data-tilt-max="15" data-tilt-perspective="700">

                                            <Task/>

                                        </figure>
                                    </div>

                                    <div className="spacer py-4 d-lg-none"></div>

                                    <div className="col-12 col-lg-6">
                                        <div className="section-heading">

                                            <h3 className="__title">Multiple Viewing Options </h3>
                                        </div>

                                        <div className="spacer py-4"></div>

                                        <div>
                                            <p>
                                                <strong style={{color:'#555'}}>Decrease time wastage and add adaptability to how you view your Task dashboard. You get different viewing options like KANBAN and List view.

                                                </strong>
                                            </p>

                                        </div>
                                    </div>


                                </div>
                            </div>
                        </section>










                        <section id="services" className="section aos-init" data-aos="fade-down"
                                 data-aos-easing="linear" data-aos-duration="500">
                            <div className="container">


                                <div className="section-heading section-heading--center"> <h2 className="__title">Why Choose <span>Dhru ERP?</span>
                                </h2></div>
                                <div className="spacer mb-5 py-6"></div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="services services--s6">
                                            <div className="__inner">
                                                <div className="row">
                                                    <div className="col-12 col-sm-6 col-xl-4 d-sm-flex">
                                                        <figure style={{width:'100%'}}
                                                                className="__item __item--rounded __item--shadow js-tilt"
                                                                js-tilt="" data-tilt-speed="600" data-tilt-max="22"
                                                                data-tilt-perspective="800">

                                                            <div className="mb-5 text-center"><i className="__icon fal fa-analytics"></i></div>

                                                            <h4 className="__title"> Manage Job Sheets / Job cards </h4>
                                                            <p> Manage Status and repair parts right from the Job Sheet. </p></figure>
                                                    </div>
                                                    <div className="col-12 col-sm-6 col-xl-4 d-sm-flex">
                                                        <figure style={{width:'100%'}}
                                                                className="__item __item--rounded __item--shadow js-tilt"
                                                                js-tilt="" data-tilt-speed="600" data-tilt-max="22"
                                                                data-tilt-perspective="800">

                                                            <div className="mb-5 text-center"><i className="__icon fal fa-cash-register"></i></div>

                                                            <h4 className="__title">Manage Sales and Purchase
                                                            </h4> <p> Maintain all your sales, purchase, and payments from one panel. </p></figure>
                                                    </div>
                                                    <div className="col-12 col-sm-6 col-xl-4 d-sm-flex">
                                                        <figure style={{width:'100%'}}
                                                                className="__item __item--rounded __item--shadow js-tilt"
                                                                js-tilt="" data-tilt-speed="600" data-tilt-max="22"
                                                                data-tilt-perspective="800">

                                                            <div className="mb-5 text-center"><i className="__icon fal fa-boxes"></i></div>
                                                            <h4 className="__title">Manage Inventory </h4>
                                                            <p> DHRU allows you to easily maintain inventory.  </p></figure>
                                                    </div>
                                                    <div className="col-12 col-sm-6 col-xl-4 d-sm-flex">
                                                        <figure style={{width:'100%'}}
                                                                className="__item __item--rounded __item--shadow js-tilt"
                                                                js-tilt="" data-tilt-speed="600" data-tilt-max="22"
                                                                data-tilt-perspective="800">
                                                            <div className="mb-5 text-center"><i className="__icon fal fa-database"></i></div>
                                                            <h4 className="__title"> Easier to manage customer data </h4>
                                                            <p>DHRU allows you to save client assets so that the next time the client visits his device is already registered with you.
                                                            </p></figure>
                                                    </div>

                                                    <div className="col-12 col-sm-6 col-xl-4 d-sm-flex">
                                                        <figure style={{width:'100%'}}
                                                                className="__item __item--rounded __item--shadow js-tilt"
                                                                js-tilt="" data-tilt-speed="600" data-tilt-max="22"
                                                                data-tilt-perspective="800">
                                                            <div className="mb-5 text-center"><i className="__icon fal fa-store"></i></div>
                                                            <h4 className="__title">Manage Multiple stores from one panel
                                                            </h4>
                                                            <p> Easily manage a chain of stores, no need to buy multiple licenses.  </p></figure>
                                                    </div>
                                                    <div className="col-12 col-sm-6 col-xl-4 d-sm-flex">
                                                        <figure style={{width:'100%'}}
                                                                className="__item __item--rounded __item--shadow js-tilt"
                                                                js-tilt="" data-tilt-speed="600" data-tilt-max="22"
                                                                data-tilt-perspective="800">
                                                            <div className="mb-5 text-center"><i className="__icon fal fa-file-chart-line"></i></div>
                                                            <h4 className="__title"> Robust Accounting Reports </h4>
                                                            <p>Sales Report, Profit & Loss, Balance Sheet, Day Book, GST and many more reports to keep you informed of your growth.
                                                            </p></figure>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>







                    </div>

                </div>}

            </div>}



            {(product === 'dhru-wallet') && <div>

                <section className="section">
                    <div className="container">
                        <div className="row   align-items-md-center">

                            <div className="col-12 col-lg-6">
                                <figure style={{width:'100%',marginLeft:'-50px'}}  className="image-container js-tilt" data-tilt-speed="600" data-tilt-max="15" data-tilt-perspective="700">
                                    <Ecommerce/>
                                </figure>
                            </div>

                            <div className="spacer py-4 d-lg-none"></div>

                            <div className="col-12 col-lg-6">
                                <div className="section-heading">

                                    <h3 className="__title">Retain Customers  <br/><span> In The Digital Era </span></h3>
                                </div>

                                <div className="spacer py-4"></div>

                                <div>
                                    <p>
                                        <strong style={{color:'#555'}}>Increase customer loyalty with consistent digital payment experiences P2P, B2B and online with Dhru Wallet script and build your own wallet. </strong>
                                    </p>

                                </div>
                            </div>

                        </div>
                    </div>
                </section>


                <section className="section">
                    <div className="container">
                        <div className="row row-reverse align-items-lg-center">
                            <div className="col-12 col-lg-6" data-aos="fade-right">


                                <div className="feature feature--s1">
                                    <div className="__inner">

                                        <div>
                                            <div className="row">

                                                <div className="col-12 col-sm">
                                                    <div className="section-heading">

                                                        <h3 className="__title"> Easy to Load & Secure  </h3>
                                                    </div>

                                                    <p>
                                                        A choice of multiple payment gateways to load wallet provides ease of use and reliability with high-security standards.
                                                    </p>

                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>


                            </div>

                            <div className="spacer py-5 d-lg-none"></div>

                            <div className="col-12 col-lg-6  text-center text-lg-right">

                                <figure style={{maxWidth: '400px'}} className="image-container js-tilt" data-tilt-speed="600" data-tilt-max="15" data-tilt-perspective="700">

                                    <SecurityFirst/>
                                </figure>

                            </div>
                        </div>
                    </div>
                </section>

                <section className="section">
                    <div className="container">
                        <div className="row   align-items-lg-center">


                            <div className="col-12 col-lg-6  text-center text-lg-left">

                                <figure style={{maxWidth: '450px'}} className="image-container js-tilt" data-tilt-speed="600" data-tilt-max="15" data-tilt-perspective="700">

                                    <FusionApi/>
                                </figure>

                            </div>

                            <div className="col-12 col-lg-6" data-aos="fade-left">


                                <div className="feature feature--s1  ">
                                    <div className="__inner">

                                        <div>
                                            <div className="row">

                                                <div className="col-12 col-sm">
                                                    <h3 className="__title h3"> Centralised management</h3>
                                                    <p>
                                                        Manage all your digital transactions on a centralised administration panel.
                                                        Customise transaction fees, commissions and security.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </div>


                            </div>

                            <div className="spacer py-5 d-lg-none"></div>

                        </div>
                    </div>
                </section>

                <section className="section">
                    <div className="container">
                        <div className="row row-reverse">
                            <div className="col-12 col-lg-6">


                                <div className="feature feature--s1  ">
                                    <div className="__inner">
                                        <div>
                                            <div className="row  ">

                                                <div className="col-12 col-sm" data-aos="fade-right">
                                                    <div className="section-heading">


                                                        <h3 className="__title">Ingenius Utilities</h3>
                                                    </div>

                                                    <p>Keeping in mind the increasing Service Standards we provide in-built features like</p>
                                                    <ul className="check-list my-md-6">
                                                        <li><i className="ico-checked fontello-ok"></i>Mass mail</li>
                                                        <li><i className="ico-checked fontello-ok"></i>SMS</li>
                                                        <li><i className="ico-checked fontello-ok"></i>System Cleanup</li>
                                                        <li><i className="ico-checked fontello-ok"></i>IP Manager</li>
                                                        <li><i className="ico-checked fontello-ok"></i>Fraud Cage</li>
                                                        <li><i className="ico-checked fontello-ok"></i>Reporting & Tracking Payments</li>
                                                    </ul>

                                                    <p>
                                                        and many more utilities so you do not have to rely on any other resources.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>


                            <div className="col-12 col-lg-6  text-center text-lg-right">

                                <figure  className="image-container js-tilt" data-tilt-speed="600" data-tilt-max="15" data-tilt-perspective="700" style={{width:'100%'}}>

                                    <IngeniusUtilities/>
                                </figure>

                            </div>


                            <div className="spacer py-5 d-lg-none"></div>

                        </div>
                    </div>
                </section>



                <section className="section mt-5 mt-sm-0">
                    <div className="container">
                        <div className="row  align-items-lg-center">




                            <div className="col-12 col-lg-12">


                                <div className="feature feature--s1  ">
                                    <div className="__inner text-center">

                                        <div>
                                            <div className="row">

                                                <div className="col-12 col-sm mb-5">
                                                    <h4 className="__title h3">Integration Ready</h4>

                                                    <p>
                                                        Integration SDK is available for merchants. Full feature merchant solution for payment aggregation, just plug and unravel opportunities to expand customer base.
                                                    </p>


                                                </div>


                                                <div className="row">
                                                    {
                                                        map(integration,function (key,i) {

                                                            console.log('i',i)

                                                            return(
                                                                <div key={key} className="col-lg-2 col-4 ingratwith ">
                                                                    <div
                                                                        className="m-5 text-center position-relative">
                                                                        <div className="w-100 position-absolute" style={{top: '-30px'}}>
                                                                                <span
                                                                                    className="badge badge-secondary m-auto">{i.title}</span>
                                                                        </div>
                                                                        <Image name={`${i.img}.png`} />
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    }

                                                </div>



                                            </div>
                                        </div>



                                    </div>
                                </div>

                            </div>

                            <div className="spacer py-5 d-lg-none"></div>

                        </div>
                    </div>
                </section>



                <br/><br/>

            </div>}



            {(product === 'dhru-fusion' || product === 'dhru-ecommerce') && <div>

                <section className="section">
                    <div className="container">
                        <div className="row row-reverse align-items-lg-center">
                            <div className="col-12 col-lg-6">


                                <div className="feature feature--s1">
                                    <div className="__inner">



                                        <div>
                                            <div className="row">

                                                <div className="col-12 col-sm" data-aos="fade-right">
                                                    <div className="section-heading">


                                                        <h3 className="__title">Ingenius Utilities</h3>
                                                    </div>

                                                    <p>Keeping in mind the increasing Service Standards we provide in-built features like</p>
                                                    <ul className="check-list my-md-6">
                                                        <li><i className="ico-checked fontello-ok"></i>Mass mail</li>
                                                        <li><i className="ico-checked fontello-ok"></i>SMS</li>
                                                        <li><i className="ico-checked fontello-ok"></i>News letter</li>
                                                        <li><i className="ico-checked fontello-ok"></i>System Cleanup</li>
                                                        <li><i className="ico-checked fontello-ok"></i>Database Backup</li>
                                                        <li><i className="ico-checked fontello-ok"></i>IP Manager</li>
                                                        <li><i className="ico-checked fontello-ok"></i>SEO Functions</li>
                                                        <li><i className="ico-checked fontello-ok"></i>Import Script</li>
                                                    </ul>

                                                    <p>
                                                        and many more utilities so you do not have to rely on any other resources.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>

                            <div className="spacer py-5 d-lg-none"></div>

                            <div className="col-12 col-lg-6  text-center text-lg-right">

                                <figure  className="image-container js-tilt" data-tilt-speed="600" data-tilt-max="15" data-tilt-perspective="700" style={{width:'100%'}}>

                                    <IngeniusUtilities/>
                                </figure>

                            </div>
                        </div>
                    </div>
                </section>

                <section className="section">
                    <div className="container">
                        <div className="row   align-items-lg-center">


                            <div className="col-12 col-lg-6  text-center text-lg-left">

                                <figure style={{maxWidth: '450px'}} className="image-container js-tilt" data-tilt-speed="600" data-tilt-max="15" data-tilt-perspective="700">

                                    <SupportingClient/>
                                </figure>

                            </div>

                            <div className="col-12 col-lg-6" data-aos="fade-left">


                                <div className="feature feature--s1  ">
                                    <div className="__inner">

                                        <div>
                                            <div className="row">

                                                <div className="col-12 col-sm">
                                                    <h3 className="__title h3">Supporting your client</h3>

                                                    <p>
                                                        Built-in Support ticket and live help system, Knowledge base and blogs to support your clients efficiently.


                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="mt-5">
                                            <div className="row">

                                                <div className="col-12 col-sm">
                                                    <h3 className="__title h3">Multiple Currency and Language</h3>

                                                    <p>
                                                        Manage your international clientele with Dhru Fusion’s easy to use multi currency and language setup in just a few clicks.


                                                    </p>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </div>


                            </div>

                            <div className="spacer py-5 d-lg-none"></div>

                        </div>
                    </div>
                </section>

                <section className="section">
                    <div className="container">
                        <div className="row row-reverse align-items-lg-center">
                            <div className="col-12 col-lg-6" data-aos="fade-right">


                                <div className="feature feature--s1  ">
                                    <div className="__inner">



                                        <div>
                                            <div className="row">

                                                <div className="col-12 col-sm">
                                                    <div className="section-heading">

                                                        <h3 className="__title">Security  First </h3>
                                                    </div>


                                                    <ul className="check-list">


                                                        <li><i className="ico-checked fontello-ok"></i> With over a decade of experience we have innovated numerous security measures considering the increasing needs of the industry.</li>
                                                        <li><i className="ico-checked fontello-ok"></i> We provide Client side fraud protection that analyses client profiles based on their KYC, Fraud Cage check and binds them to a particular payment gateway forcing them to pay securely without any fraudulent activity.</li>
                                                        <li><i className="ico-checked fontello-ok"></i> You can define staff access and control what aspects of the management panel is accessed by them, which allows YOU to always be in control of your business.</li>

                                                    </ul>


                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>


                            </div>

                            <div className="spacer py-5 d-lg-none"></div>

                            <div className="col-12 col-lg-6  text-center text-lg-right">

                                <figure style={{maxWidth: '400px'}} className="image-container js-tilt" data-tilt-speed="600" data-tilt-max="15" data-tilt-perspective="700">

                                    <SecurityFirst/>
                                </figure>

                            </div>
                        </div>
                    </div>
                </section>


                <section className="section mt-5 mt-sm-0">
                    <div className="container">
                        <div className="row  align-items-lg-center">




                            <div className="col-12 col-lg-12">


                                <div className="feature feature--s1  ">
                                    <div className="__inner text-center">

                                        <div>
                                            <div className="row">

                                                <div className="col-12 col-sm mb-5  mt-5 mt-sm-0">
                                                    <h4 className="__title h3">Integrations</h4>

                                                </div>

                                                <div className="row">
                                                    {
                                                        map(integration,function (key,i) {
                                                            return(
                                                                <div key={key} className="col-lg-2 col-4 ingratwith ">
                                                                    <div
                                                                        className="m-5 text-center position-relative">
                                                                        <div className="w-100 position-absolute" style={{top: '-30px'}}>
                                                                                <span
                                                                                    className="badge badge-secondary m-auto">{i.title}</span>
                                                                        </div>
                                                                        <Image name={`${i.img}.png`} />
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>

                                            </div>

                                        </div>

                                    </div>

                                </div>

                            </div>

                            <div className="spacer py-5 d-lg-none"></div>

                        </div>
                    </div>
                </section>

                <section id="infoblock" className="section section--no-pb">
                    <div className="container">
                        <div className="section-heading section-heading--center">

                            <h3 className="__title h3">Road Map <br/><span> We are improving everyday</span></h3>
                        </div>

                        <div className="spacer py-6"></div>

                        <div className="row">
                            <div className="col-12">


                                <div className="services--s6">
                                    <div className="__inner">
                                        <div className="row">

                                            <div className="col-12 col-sm-6 col-lg-3">
                                                <div className="__item __item--shadow  text-center">
                                                    <a target="_blank" rel="noopener noreferrer"  href="https://support.dhru.com/Dhru-Fusion/releasenotes/" className="cursor-pointer">
                                                        <div className="">
                                                            <i className="__icon fal fa-rss"></i>
                                                        </div>

                                                        <div className="__content p-3">
                                                            <div className="__title">Release Notes</div>
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>

                                            <div className="col-12 col-sm-6 col-lg-3">
                                                <div className="__item __item--shadow  text-center">
                                                    <a target="_blank" rel="noopener noreferrer"  href="https://support.dhru.com/Dhru-Fusion/features/feature/unconfirmed" className="cursor-pointer">
                                                        <div className="">
                                                            <i className="__icon fal fa-lightbulb-on"></i>
                                                        </div>

                                                        <div className="__content p-3">
                                                            <div className="__title">Post Idea</div>
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>

                                            <div className="col-12 col-sm-6 col-lg-3">
                                                <div className="__item __item--shadow  text-center">
                                                    <a target="_blank" rel="noopener noreferrer"  href="https://support.dhru.com/Dhru-Fusion/features/bug/unconfirmed" className="cursor-pointer">
                                                        <div className="">
                                                            <i className="__icon fal fa-comment"></i>
                                                        </div>

                                                        <div className="__content p-3">
                                                            <div className="__title">Feedback</div>
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>

                                            <div className="col-12 col-sm-6 col-lg-3">
                                                <div className="__item __item--shadow  text-center">
                                                    <a target="_blank" rel="noopener noreferrer"  href="https://support.dhru.com/Dhru-Fusion/" className="cursor-pointer">
                                                        <div className="">
                                                            <i className="__icon fal fa-file"></i>
                                                        </div>

                                                        <div className="__content p-3">
                                                            <div className="__title">Documents</div>
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>



                    </div>
                </section>

                <section className="section">

                    <div className="p-3 text-center mt-5">
                        <NavLink  onClick={()=>setPage({page:'pricing',breadcrumb:'Pricing'})}   tag={Link} to={`/pricing/${product}`}  className="custom-btn   custom-btn--big custom-btn--style-4 mb-3" >Buy Now</NavLink>
                    </div>

                </section>

                <br/><br/>

            </div>}

        </Fragment>
    );


}

const mapStateToProps = (state) => {
    return {
        page:state.appApiData.page
    }
};

const mapDispatchToProps = (dispatch) => ({
    setPage: (page) => dispatch(setPage(page)),
});


export default connect(mapStateToProps, mapDispatchToProps)(Index);
