import {
    SET_INIT,
    SET_PAGE,
} from "../definitions/appApiData";


const setInit = (data) => ({
    type: SET_INIT,
    data
});
const setPage = (page) => ({
    type: SET_PAGE,
    page
});

export {
    setInit,
    setPage,
}
