import {
    SET_DIALOG,
    SET_ALERT,
    SET_LOADER,
    ENQUEUE_SNACKBAR,
    CLOSE_SNACKBAR,
    REMOVE_SNACKBAR, TOGGLE_LOADER
} from "../definitions/components";

const setDialog = (dialog) => ({
    type: SET_DIALOG,
    dialog
});

const setAlert = (alert) => ({
    type: SET_ALERT,
    alert
});

const setLoader = (loader) => ({
    type: SET_LOADER,
    loader
});

const enqueueSnackbar = (notification) => {
    const key = notification.options && notification.options.key;
    return {
        type: ENQUEUE_SNACKBAR,
        notification: {
            ...notification,
            key: key || new Date().getTime() + Math.random(),
        },
    };
};

const closeSnackbar = key => ({
    type: CLOSE_SNACKBAR,
    dismissAll: !key, // dismiss all if no key has been defined
    key,
});

const removeSnackbar = key => ({
    type: REMOVE_SNACKBAR,
    key,
});

const toggleLoader = (isOpenLoader)=>({
    type:TOGGLE_LOADER,
    isOpenLoader
});

export {setDialog,setAlert,setLoader,enqueueSnackbar,closeSnackbar,removeSnackbar,toggleLoader};
