import {
    SET_INIT,
    SET_PAGE
} from "../definitions/appApiData";

const initState = {page:'index'};

export default (state = initState, action) => {

    switch (action.type) {
        case SET_INIT:
            return {
                ...state,
                ...action.data
            };
        case SET_PAGE:
            return {
                ...state,
                ...action.page
            };
        default:
            return state;
    }
}
