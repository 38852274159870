import React, {Component, Fragment} from "react";
import {ReactComponent as AboutUs} from '../../theme/svg/aboutus.svg';
import {ReactComponent as Banner} from '../../theme/svg/banner.svg';
import {ReactComponent as Banner2} from '../../theme/svg/banner2.svg';
import {ReactComponent as OrderAutomation} from "../../theme/svg/orderautomation.svg";
import {ReactComponent as EcoSystem} from "../../theme/svg/ecosystem.svg";
import {ReactComponent as RobustFramework} from "../../theme/svg/robustframework.svg";
import Services from "../Services";
import {NavLink} from "reactstrap";
import {Link} from "react-router-dom";
import {_tilt, scrollToTop, setIndex} from "../../lib/functions";



class Index extends Component {


    constructor(props) {
        super(props);
        this.state={

        }
    }

    componentDidMount() {
        document.title = "DHRU | Application for Businesses";
        _tilt();
        scrollToTop();
    }

    render() {

        return(
            <Fragment>
                <div>

                    <div className="">
                        <div id="start-screen"
                             className="start-screen start-screen--full-height start-screen--style-1 " >
                            <div className="start-screen__bg-container position-relative bg-light"  >

                                <div className="bg-svg svg20"  ></div>
                            </div>
                            <div className="start-screen__shapes d-none d-lg-block">
                                <div className="img-shape"></div>
                                <div className="img-shape" style={{maxWidth:'50%',marginRight:'20px',width:'100%'}}>
                                    <div>
                                        <Banner2/>
                                    </div>
                                </div>
                            </div>
                            <div className="start-screen__content-container">
                                <div className="start-screen__content__item align-items-center">
                                    <div className="container">
                                        <div className="row  ">
                                            <div className="col-12 col-md-10 col-lg-6 aos-init aos-animate"
                                                 data-aos="fade-right">
                                                <div className="d-md-block pt-5 ">
                                                    <div className="d-none d-lg-block">
                                                        <h2 style={{fontSize:'5rem',fontWeight:'600'}}>Complex made simple</h2>
                                                    </div>
                                                    <div className="text-center col-lg-6 d-block d-lg-none mt-5">
                                                        <h2 style={{fontSize:'5rem',fontWeight:'600',paddingTop:'25px'}}>Complex made simple</h2>
                                                    </div>

                                                </div>
                                                <h3 className="p-2 d-none d-lg-block"><span>Grow Your Business <br/>with our PRO-efficient Products</span></h3>
                                                <h3 className="p-2 text-center col-lg-6 d-block d-lg-none"><span>Grow Your Business <br/>with our PRO-efficient Products</span></h3>

                                                <p className="p-2 d-none d-lg-block">
                                                    Using our resourceful and intelligent management tools you can
                                                automate your complex workflow and save time to invest in taking your
                                                business to a whole new level.
                                                </p>

                                                <p className="p-5 col-lg-6 d-block d-lg-none">
                                                    Using our resourceful and intelligent management tools you can
                                                    automate your complex workflow and save time to invest in taking your
                                                    business to a whole new level.
                                                </p>


                                                <div className="spacer py-2 py-sm-4"></div>
                                            </div>
                                            <div className="col-lg-6 d-block d-lg-none pb-5">
                                                <Banner/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div role="main" className="">
                            <section id="fusion" className="section ">
                                <div className="container ">
                                    <div className="row align-items-md-center row-reverse ">
                                        <div className="col-12 col-lg-6 aos-init" data-aos="fade-right">
                                            <div className="section-heading  d-none d-md-block">
                                                <h2 style={{fontSize:'5rem',fontWeight:'600'}}>Dhru
                                                    Fusion
                                                </h2>
                                                <h3 className="p-2 d-none d-lg-block"><span>Our very own inception for order automation</span></h3>



                                            </div>
                                            <div className="spacer py-4 "></div>
                                            <div className="p-2 "><p><strong style={{color:'#555'}}> State of the art solution
                                                for </strong><br/> automation of orders via API, client &amp; supplier
                                                management, Billing, Shopping cart, Blog, Live Chat and Support tickets
                                                all under one dashboard.</p> <p className="mt-9 text-right text-sm-left"><NavLink
                                                onClick={()=>setIndex('index','Fusion')}
                                                className="custom-btn custom-btn--medium custom-btn--style-2 "
                                                tag={Link} to={"/products/dhru-fusion"}>More About
                                                Fusion</NavLink></p></div>
                                        </div>
                                        <div className="spacer py-4 d-lg-none"></div>
                                        <div className="col-12 col-lg-6 text-center text-lg-right">

                                            <div className="section-heading d-block d-md-none mt-5">
                                                <h3 className="__title mt-5">Dhru Fusion <br/>
                                                    <span>Our very own inception for order automation </span></h3>
                                            </div>

                                                <figure className="image-container js-tilt" js-tilt="" options={{ scale: 1, perspective: 700, max:15 }}  style={{width:'100%'}}>
                                                    <OrderAutomation />
                                                </figure>



                                        </div>
                                    </div>
                                </div>
                            </section>


                            <section className="section">
                                <div className="container">
                                    <div
                                        className="row row-reverse flex-md-row-reverse justify-content-sm-center align-items-md-center">
                                        <div className="col-12 col-md-6 aos-init" data-aos="fade-left">
                                            <div className="section-heading d-none d-md-block">

                                                <h2 style={{fontSize:'5rem',fontWeight:'600'}}>Dhru
                                                    ERP
                                                </h2>
                                                <h3 className="__title"><span>An ideal solution for Retail store, Restaurant and Service centers</span></h3>


                                            </div>
                                            <div className="spacer py-4"></div>
                                            <div><p><strong style={{color:'#555'}}> Ecosystem ERP designed specially by
                                                recognizing the demanding needs of the upcoming, small and midsize
                                                enterprises. </strong><br/> Our cloud-based ERP solution helps you manage
                                                all your business needs from accounting, CRM, Point Of Sale to Supply
                                                chain management, advanced inventory management , support/service
                                                Tickets and Intuitive &amp; precise Reports</p> <p className="mt-9 text-right text-sm-left">
                                                <NavLink onClick={()=>setIndex('index','ERP')}
                                                         className="custom-btn custom-btn--medium custom-btn--style-2 "
                                                tag={Link} to={"/products/dhru-erp/restaurant"}>More About
                                                ERP</NavLink></p></div>
                                        </div>
                                        <div className="spacer py-4 d-md-none"></div>
                                        <div className="col-12 col-md-6 text-center text-md-left mt-5">

                                            <div className="section-heading  d-block d-md-none mt-5"><h3 className="__title mt-5">Dhru
                                                ERP<br/>
                                                <span>An ideal solution for Retail store, Restaurant and Service centers</span>
                                            </h3></div>

                                            <figure className="image-container js-tilt" js-tilt="" data-figure-speed="600"
                                                    data-figure-max="15" data-figure-perspective="700" style={{width:'75%'}}>
                                                 <EcoSystem />
                                            </figure>
                                        </div>
                                    </div>
                                    <div className="spacer py-9"></div>
                                </div>
                            </section>




                            <section id="features" className="section">
                                <div className="container">
                                    <div className="row row-reverse align-items-md-center">
                                        <div className="col-12 col-lg-6 aos-init" data-aos="fade-right">

                                            <h2 style={{fontSize:'5rem',fontWeight:'600'}}>Dhru
                                                Ecommerce
                                            </h2>
                                            <h3 className="__title"><span>Secure &amp; Powerful</span></h3>




                                            <div className="spacer py-4"></div>



                                            <div><p><strong style={{color:'#555'}}>Hosted solution built on Dhru's Robust
                                                Framework </strong><br/> Packed with amazing security measures, optimized
                                                performance and flexibility to customize all your out of the box needs.
                                            </p> <p className="mt-9 text-right text-sm-left">
                                                <NavLink
                                                    onClick={()=>setIndex('index','Ecommerce')}
                                                className="custom-btn custom-btn--medium custom-btn--style-2 "
                                                tag={Link} to={"/products/dhru-ecommerce"} >More
                                                About Ecommerce</NavLink></p></div>
                                        </div>
                                        <div className="spacer py-4 d-lg-none"></div>
                                        <div className="col-12 col-lg-6 text-center text-lg-right mt-5">

                                            <div className="section-heading d-block d-md-none mt-5"><h3 className="__title  mt-5">Dhru
                                                Ecommerce<br/><span>Secure &amp; Powerful</span></h3></div>

                                            <figure className="image-container js-tilt" js-tilt="" data-figure-speed="600"
                                                    data-figure-max="15" data-figure-perspective="700" style={{width:'85%'}}>
                                                    <RobustFramework />
                                            </figure>
                                        </div>
                                    </div>
                                </div>
                            </section>





                            <Services />





                            <section className="section" id="about" style={{marginBottom:'100px'}}>
                                <div className="container">
                                    <div className="row row-reverse flex-lg-row-reverse align-items-lg-center">
                                        <div className="col-12 col-lg-6 aos-init" data-aos="fade-left">
                                            <div className="section-heading"><h2 className="__title">About
                                                us<br/><span></span></h2></div>
                                            <div className="spacer py-4"></div>
                                            <div><p> With over 15 years of experience in IT services from providing
                                                elite management panels to one-stop solutions, we've been the backbone
                                                of sky high results of many successful enterprises. We host a plethora
                                                of products for various industries with ultramodern features and on the
                                                dot support. </p>
                                                <div className="counter counter--s2">
                                                    <div className="__inner">
                                                        <div className="row">
                                                            <div className="col-12 col-sm-6 col-md-4 col-lg-6">
                                                                <div className="__item">
                                                                    <div className="d-table">
                                                                        <div className="d-table-cell align-middle"
                                                                             style={{paddingRight:'10px'}}><i
                                                                            className="__icon fal fa-globe"></i></div>
                                                                        <div className="d-table-cell align-middle">
                                                                            <div className="__content">
                                                                                <div className="__count js-count"
                                                                                     data-from="0" data-to="285"> 100+
                                                                                </div>
                                                                                <div className="__title">Countries</div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-sm-6 col-md-4 col-lg-6">
                                                                <div className="__item">
                                                                    <div className="d-table">
                                                                        <div className="d-table-cell align-middle"
                                                                             style={{paddingRight:'15px'}}><i
                                                                            className="__icon fal fa-users"></i></div>
                                                                        <div className="d-table-cell align-middle">
                                                                            <div className="__content">
                                                                                <div className="__count js-count"
                                                                                     data-from="0" data-to="5650"> 4000+
                                                                                </div>
                                                                                <div className="__title">Happy Clients
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="spacer py-4 d-lg-none"></div>
                                        <div className="col-12 col-lg-6 text-center text-lg-left">
                                            <figure className="image-container js-tilt" data-figure=""
                                                    data-figure-speed="600" data-figure-max="15" data-figure-perspective="700"
                                                    style={{width:'60%'}}>
                                                <AboutUs />

                                            </figure>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>

                </div>
            </Fragment>
        );
    }

}


export default Index;
