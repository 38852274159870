import React, {Component,Fragment} from "react";
import {connect} from "react-redux";
import {Route, Routes} from "react-router-dom";

import Home from "../pages/Home";
import Products from "../pages/Products";
import Pricing from "../pages/Pricing";
import Services from "../pages/Services";
import Contactus from "../pages/Contactus";

import Customerdevelopmentagreement from "../pages/Customerdevelopmentagreement";
import Graphicdesignagreement from "../pages/Graphicdesignagreement";
import Privacypolicy from "../pages/Privacypolicy";
import Toc from "../pages/Toc";

class RouterComponent extends Component {



    render() {

        return (<Fragment>

            {/*<Route path="/" exact={true} render={(props) => { return <Home key={props.match.url}   {...props}/>;}}/>
            <Route path="/products/:product" exact={true} render={(props) => {return <Products key={props.match.url}   {...props}/>;}}/>
            <Route path="/products/:product/:type" exact={true} render={(props) => {return <Products key={props.match.url}   {...props}/>;}}/>
            <Route path="/pricing/:product" exact={true} render={(props) => {return <Pricing key={props.match.url}   {...props}/>;}}/>
            <Route path="/services"   render={(props) => { return <Services key={props.match.url}  {...props}/>;}}/>
            <Route path="/contactus"   render={(props) => { return <Contactus key={props.match.url}  {...props}/>;}}/>

            <Route path="/customdevelopmentagreement"   render={(props) => { return <Customerdevelopmentagreement key={props.match.url}  {...props}/>;}}/>
            <Route path="/graphicdesignagreement"   render={(props) => { return <Graphicdesignagreement key={props.match.url}  {...props}/>;}}/>
            <Route path="/privacypolicy"   render={(props) => { return <Privacypolicy key={props.match.url}  {...props}/>;}}/>
            <Route path="/toc"   render={(props) => { return <Toc key={props.match.url}  {...props}/>;}}/>*/}

            <Routes >

            <Route exact={'true'} path="/"   element={<Home />}/>
            <Route  exact={'true'}  path="/products/:product"    element={<Products />}  />
            <Route  exact={'true'}  path="/products/:product/:type"   element={<Products />}  />
            <Route  exact={'true'}  path="/pricing/:product"   element={<Pricing />}  />
            <Route  exact={'true'}  path="/services"  element={<Services />}   />
            <Route  exact={'true'}  path="/contactus"   element={<Contactus />}  />

            <Route  exact={'true'}  path="/customdevelopmentagreement"  element={<Customerdevelopmentagreement />}   />
            <Route  exact={'true'}  path="/graphicdesignagreement"   element={<Graphicdesignagreement />}  />
            <Route  exact={'true'}  path="/privacypolicy"  element={<Privacypolicy />}   />
            <Route  exact={'true'}  path="/toc"  element={<Toc />}   />

            </Routes>


        </Fragment>)
    }
}
const mapStateToProps = (state) => ({

});

export default connect(mapStateToProps)(RouterComponent);
