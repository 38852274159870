import React, {Component, Fragment} from "react";
import {connect} from "react-redux";
import {_tilt, scrollToTop} from "../../lib/functions";


class Index extends Component {


    constructor(props) {
        super(props);
        this.state={

        }
    }

    componentDidMount() {
        document.title = `DHRU  Services`;
        scrollToTop();
        _tilt();
    }

    render() {
        return(
            <Fragment>
                <section id="services" className="section aos-init" data-aos="fade-down"
                         data-aos-easing="linear" data-aos-duration="500">
                    <div className="container">
                        <div className="col-12 col-lg-6 text-center text-lg-right mt-5">
                            <div className="col-12 col-lg-6 text-center text-lg-right mt-5">
                                <br/>
                            </div>
                        </div>

                        <div className="section-heading section-heading--center"><h6
                            className="__subtitle">our services</h6> <h2 className="__title">What We <span>can do for you</span>
                        </h2></div>
                        <div className="spacer mb-5 py-6"></div>
                        <div className="row">
                            <div className="col-12">
                                <div className="services services--s6">
                                    <div className="__inner">
                                        <div className="row">
                                            <div className="col-12 col-sm-6 col-xl-3 d-sm-flex">
                                                <figure style={{width:'100%'}}
                                                    className="__item __item--rounded __item--shadow js-tilt"
                                                    js-tilt="" data-tilt-speed="600" data-tilt-max="22"
                                                    data-tilt-perspective="800">
                                                    <div className="mb-5"><i className="__icon fal fa-terminal"></i></div>
                                                    <h4 className="__title"> AWS Console Management </h4>
                                                    <p> Amazon Web Services (AWS) is all about deploying,
                                                        maintaining, monitoring and running applications on
                                                        the cloud, we help you streamline your AWS
                                                        infrastructure with our expertise. </p></figure>
                                            </div>
                                            <div className="col-12 col-sm-6 col-xl-3 d-sm-flex">
                                                <figure style={{width:'100%'}}
                                                    className="__item __item--rounded __item--shadow js-tilt"
                                                    js-tilt="" data-tilt-speed="600" data-tilt-max="22"
                                                    data-tilt-perspective="800">
                                                    <div className="mb-5"><i
                                                        className="__icon fal fa-shield-check"></i></div>
                                                    <h4 className="__title">Server
                                                        Security &amp; Management</h4> <p> A ProActive Linux
                                                    Server Management service helps you achieve High
                                                    Performance, Higher Security Standards and monitoring
                                                    for our products to function smoothly and
                                                    efficiently. </p></figure>
                                            </div>
                                            <div className="col-12 col-sm-6 col-xl-3 d-sm-flex">
                                                <figure style={{width:'100%'}}
                                                    className="__item __item--rounded __item--shadow js-tilt"
                                                    js-tilt="" data-tilt-speed="600" data-tilt-max="22"
                                                    data-tilt-perspective="800">
                                                    <div className="mb-5"><i
                                                        className="__icon fal fa-file-code"></i></div>
                                                    <h4 className="__title">Custom <br/>Development</h4>
                                                    <p> We welcome dedicated development for Custom Features
                                                        that are not available for the generic use of our
                                                        products. From feature requests to cosmetic changes
                                                        we deliver what suits you the best. </p></figure>
                                            </div>
                                            <div className="col-12 col-sm-6 col-xl-3 d-sm-flex">
                                                <figure style={{width:'100%'}}
                                                    className="__item __item--rounded __item--shadow js-tilt"
                                                    js-tilt="" data-tilt-speed="600" data-tilt-max="22"
                                                    data-tilt-perspective="800">
                                                    <div className="mb-5"><i
                                                        className="__icon fal fa-handshake"></i></div>
                                                    <h4 className="__title"> Implementation &amp; consulting </h4>
                                                    <p>We study your business model and churn out the best
                                                        way to implement our products in your work flow to
                                                        maximize your team's potential.</p></figure>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </Fragment>
        );
    }

}

const mapStateToProps = (state) => {
    return {

    }
};

const mapDispatchToProps = (dispatch) => ({

});


export default connect(mapStateToProps, mapDispatchToProps)(Index);
