import {
    SET_DIALOG,
    SET_ALERT,
    SET_LOADER,
    ENQUEUE_SNACKBAR,
    CLOSE_SNACKBAR,
    REMOVE_SNACKBAR, TOGGLE_LOADER
} from "../definitions/components";
import {LOGOUT} from "../definitions/authentication";
import React from "react";


const initState = {
    dialog: {show:false,component: () => <div></div>},
    alert: {msg: '', variant: 'success'},
    loader: {show: false},
    notifications: [],
    isOpenLoader: false
};

export default (state = initState, action) => {


    switch (action.type) {
        case SET_DIALOG:
            return {
                ...state,
                dialog: {
                    ...state.dialog,
                    ...action.dialog
                }
            };
        case SET_ALERT:
            return {
                ...state,
                alert: {
                    ...state.alert,
                    ...action.alert
                }
            };
        case SET_LOADER:
            return {
                ...state,
                loader: {
                    ...state.loader,
                    ...action.loader
                }
            };
        case ENQUEUE_SNACKBAR:
            return {
                ...state,
                notifications: [
                    ...state.notifications,
                    {
                        key: action.key,
                        ...action.notification,
                    },
                ],
            };
        case CLOSE_SNACKBAR:
            return {
                ...state,
                notifications: state.notifications.map(notification => (
                    (action.dismissAll || notification.key === action.key)
                        ? {...notification, dismissed: true}
                        : {...notification}
                )),
            };
        case REMOVE_SNACKBAR:
            return {
                ...state,
                notifications: state.notifications.filter(
                    notification => notification.key !== action.key,
                ),
            };
        case TOGGLE_LOADER:
            return {...state, isOpenLoader: action.isOpenLoader};
        case LOGOUT:
            return initState;
        default:
            return state;
    }
}
