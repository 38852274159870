const SET_DIALOG = "SET_DIALOG";
const SET_ALERT = "SET_ALERT";
const SET_LOADER = "SET_LOADER";

const ENQUEUE_SNACKBAR = 'ENQUEUE_SNACKBAR';
const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR';
const REMOVE_SNACKBAR = 'REMOVE_SNACKBAR';

const TOGGLE_LOADER = "TOGGLE_LOADER";

export {
    SET_DIALOG,
    SET_ALERT,
    SET_LOADER,
    ENQUEUE_SNACKBAR,
    CLOSE_SNACKBAR,
    REMOVE_SNACKBAR,
    TOGGLE_LOADER
};
